import CopyIcon2 from '@/components/IconSVG/CopyIcon2';
import { Image } from '@/components/Image';
import QrCode from '@/components/QrCode';
import Text from '@/components/Text';
import ToolTip from '@/components/Tooltip';
import { TC_WEB_URL } from '@/configs';
import { ellipsisCenter } from '@/utils/address';
import { formatAmount } from '@/utils/format';
import { formatDateTime } from '@/utils/time';
import { getIconUrl } from '@/utils/token';
import copy from 'copy-to-clipboard';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Info } from 'react-feather';
import toast from 'react-hot-toast';
import { HistoryItemType, HistoryTypes } from '../History.types';
import {
  ellipsisByTx,
  EXPLORER_LINK,
  getDirectionStr,
  getExplorerLink,
  getExplorerLinkByTokenInfo,
  getExplorerOnLayer2,
  getToolTipMessage,
  getTxStatusDecorator,
  isSuccessfulTx,
  isTxInsufficientFee,
  isTxInvalid,
  isTxWatingInscribed,
} from '../History.utils';
import {
  Column,
  Container,
  Link,
  Row,
  Title,
  WrapCopyIcon,
  WrapTitle,
} from './HistoryItem.styled';
import React from 'react';
import useAsyncEffect from 'use-async-effect';
import withdrawTCL2Storage, {
  ITransactionItem,
} from '@components/WithdrawTCLayer2/storage';
import TCL2TransactorModal, {
  IConvertData,
} from '@components/WithdrawTCLayer2/TCL2Modal.transactor';
import { IStepKey } from '@components/WithdrawTCLayer2/constants';
import {
  LOCAL_PENDING_STATUS,
  WAITING_INSCRIBED_STATUS,
} from '../History.constants';
import { useWeb3React } from '@web3-react/core';
import { useSelector } from 'react-redux';
import {
  getNetworkByNameSelector,
  getNetworkListSelector,
} from '@state/network/selector';

type Props = {
  item: HistoryItemType;
  index: number;
  addressInput: string;
};

const HistoryItem = (props: Props) => {
  const {
    type = 'unknown',
    tcAddress,
    withdrawWallet,
    createdAt = '',
    amountTc,
    txProcessWithdraw,
    txTcProcessDeposit,
    txBurnedWithdraw,
    txReceivedDeposit,
    tcTokenInfo,
    amountBurnedWithdraw,
    amountReceivedDeposit,
    status = 0,
    statusStr,
    statusType,
    fee = '0.0',
    note,
    symbol,
    depositWallet,
    convertTcl2Stepper,
    fromToNetworkInfo,
    fromNetwork,
    toNetwork,
    fromNetworkName,
    toNetworkName,
  } = props.item;

  const addressInput = props.addressInput;

  const { account } = useWeb3React();

  const [convertTCL2Data, setConvertTCL2Data] = React.useState<
    IConvertData | undefined
  >(undefined);
  const [isShowModalClaimTCl2, setIsShowModalClaimTCl2] = React.useState(false);
  const typeLowerCase = type.toLowerCase() as HistoryTypes;
  const networkList = useSelector(getNetworkListSelector);

  const getConvertTCL2Data = async () => {
    if (
      !convertTcl2Stepper ||
      !convertTcl2Stepper.length ||
      !txBurnedWithdraw ||
      !tcTokenInfo ||
      account?.toLowerCase() !== addressInput.toLowerCase()
    ) {
      setConvertTCL2Data(undefined);
      return;
    }
    try {
      const storage: ITransactionItem | undefined =
        await withdrawTCL2Storage.getCurrentStatusByStorage(txBurnedWithdraw);
      const finalized = convertTcl2Stepper.find(
        (step) => step.key === IStepKey.claim,
      );

      const isSuccess = finalized?.processing && finalized.hash;

      const isProve =
        !storage?.l1ProveHash &&
        convertTcl2Stepper.find(
          (step) => step.processing && step.key === IStepKey.prove,
        );

      const isClaim =
        !storage?.l1ClaimHash &&
        convertTcl2Stepper.find(
          (step) => step.processing && step.key === IStepKey.claim,
        );

      setConvertTCL2Data({
        storage,
        l2Hash: txBurnedWithdraw || '',
        steps: convertTcl2Stepper,
        amount: amountTc || '',
        isProve: !!isProve,
        isClaim: !!isClaim,
        isSuccess: !!isSuccess,
        network: tcTokenInfo.network,
      });
    } catch (e) {
      setConvertTCL2Data(undefined);
    }
  };

  const typeText = React.useMemo(() => {
    switch (typeLowerCase) {
      case 'deposit':
      case 'deposit l2':
      case 'l1 => l2':
      case 'cross layer':
        return 'DEPOSIT';

      case 'withdraw':
      case 'withdraw l2':
      case 'l2 => l1':
        return 'WITHDRAW';
      default:
        return 'UNKNOW';
    }
  }, [typeLowerCase]);

  const formatSymbol = React.useMemo(() => {
    if (status === LOCAL_PENDING_STATUS || status === WAITING_INSCRIBED_STATUS) {
      return symbol?.toUpperCase() || '--';
    } else {
      const tokenSymbol = tcTokenInfo?.symbol?.toUpperCase();

      switch (typeLowerCase) {
        case 'withdraw':
        case 'withdraw l2':
        case 'l1 => l2':
        case 'l2 => l1':
          return tokenSymbol ? `${tokenSymbol}` : '--';
        default:
          return tokenSymbol || '--';
      }
    }
  }, [tcTokenInfo, symbol, status]);

  useAsyncEffect(async () => {
    await getConvertTCL2Data();
  }, [convertTcl2Stepper, addressInput, account]);

  const renderStatus = () => {
    const decorator = getTxStatusDecorator({
      statusStr,
      statusType,
      statusCode: status,
    });
    if (isTxWatingInscribed(status)) {
      return (
        <>
          <button
            className="button-inscribe"
            onClick={() => window.open(`${TC_WEB_URL}/?tab=transactions`)}
          >
            Process
          </button>
        </>
      );
    } else if (isTxInvalid(status) || isTxInsufficientFee(status)) {
      const depositAddress = isTxInsufficientFee(status) ? depositWallet : undefined;
      return (
        <Row style={{ gap: 6 }}>
          <Title style={{ color: decorator.color, fontWeight: 800 }}>
            {statusStr}
          </Title>
          {!!note && (
            <ToolTip
              unwrapElement={
                <Info
                  style={{
                    color: 'white',
                    width: 16,
                    height: 16,
                    cursor: 'pointer',
                  }}
                />
              }
              width={300}
            >
              <div style={{ fontSize: 14, padding: 8 }}>
                {note}
                {!!depositAddress && (
                  <QrCode
                    qrCodeProps={{
                      value: depositAddress || 'UNKNOWN',
                      size: 100,
                    }}
                    isBlur={!depositAddress}
                  />
                )}
                {!!depositAddress && (
                  <Row style={{ marginTop: 4 }}>
                    <Text>
                      {ellipsisCenter({
                        limit: 4,
                        str: depositAddress,
                      })}
                    </Text>
                    {renderBtnCopyAddress(depositAddress)}
                  </Row>
                )}
              </div>
            </ToolTip>
          )}
        </Row>
      );
    } else {
      if (isSuccessfulTx(status)) {
        return (
          <Title style={{ color: decorator.color, fontWeight: 800 }}>
            {statusStr}
          </Title>
        );
      } else {
        const toolTipMessage = getToolTipMessage(status);
        return (
          <div>
            <Row style={{ gap: 6 }}>
              <Title
                style={{
                  color: decorator.color,
                  fontWeight: 800,
                  fontStyle: status === LOCAL_PENDING_STATUS ? 'italic' : '',
                }}
              >
                {statusStr}
              </Title>
              {!convertTCL2Data && !!toolTipMessage && (
                <OverlayTrigger
                  placement="right"
                  overlay={
                    <Tooltip id="tooltip">
                      <Text>{toolTipMessage}</Text>
                    </Tooltip>
                  }
                >
                  <Info color={'white'} size={16} className="info-icon" />
                </OverlayTrigger>
              )}
            </Row>
            {convertTCL2Data && (
              <button
                className="button-withdraw"
                onClick={() => setIsShowModalClaimTCl2(true)}
              >
                {convertTCL2Data.isProve
                  ? 'Prove'
                  : convertTCL2Data.isClaim
                  ? 'Claim'
                  : 'View'}
              </button>
            )}
          </div>
        );
      }
    }
  };

  const renderBtnCopyAddress = (address: string) => {
    return (
      <WrapCopyIcon>
        <CopyIcon2
          scale={0}
          onClick={() => {
            if (address) {
              toast.success('Copied');
              copy(address);
            }
          }}
        />
      </WrapCopyIcon>
    );
  };

  const renderRowTxByData = ({
    title,
    tx,
    explorer,
  }: {
    title: string;
    tx?: string;
    explorer?: string;
  }) => {
    return (
      <Row>
        <Title>{title}</Title>
        {tx && explorer ? (
          <>
            <Link target="_blank" href={`${explorer}/${tx}`}>
              {ellipsisByTx({ tx })}
            </Link>
            {renderBtnCopyAddress(tx)}
          </>
        ) : (
          <p>{'--'}</p>
        )}
      </Row>
    );
  };

  const renderDirection = () => {
    return getDirectionStr({ fromNetworkName, toNetworkName, networkList });
  };

  const renderTransactionContentColumnNEW = () => {
    let txn = txReceivedDeposit || txBurnedWithdraw;
    let processTxn = txTcProcessDeposit || txProcessWithdraw;
    return (
      <>
        {renderRowTxByData({
          title: 'Txn:',
          tx: txn,
          explorer: getExplorerLink({ networkName: fromNetworkName, networkList }),
        })}
        {renderRowTxByData({
          title: 'Process Txn:',
          tx: processTxn,
          explorer: getExplorerLink({ networkName: toNetworkName, networkList }),
        })}
      </>
    );
  };

  const renderTransactionContentColumn = () => {
    switch (typeText) {
      case 'DEPOSIT': {
        let receivedDepositTx = txReceivedDeposit;
        let receivedDepositLinkExplorer = undefined;

        let tcProcessDepositTx = txTcProcessDeposit;
        let tcProcessDepositTxLinkExplorer = undefined;

        switch (typeLowerCase) {
          // External (BTC | ETH ) -> L1
          case 'deposit':
            {
              // Deposit Tx
              receivedDepositLinkExplorer = getExplorerLinkByTokenInfo({
                tcTokenInfo,
              });
              // Process Tx
              tcProcessDepositTxLinkExplorer = EXPLORER_LINK.TC;
            }
            break;
          // External (BTC | ETH) -> L2
          case 'deposit l2':
            {
              // Deposit Tx
              receivedDepositLinkExplorer = getExplorerLinkByTokenInfo({
                tcTokenInfo,
              });

              // Process Tx
              tcProcessDepositTxLinkExplorer = getExplorerOnLayer2({
                tcTokenInfo,
                networkList,
                networkName: toNetwork,
              });
            }
            break;

          // L1 -> L2
          case 'l1 => l2': {
            {
              // Deposit Tx
              receivedDepositLinkExplorer = EXPLORER_LINK.TC;

              // Process Tx
              tcProcessDepositTxLinkExplorer = getExplorerOnLayer2({
                tcTokenInfo,
                networkList,
                networkName: toNetwork,
              });
            }
            break;
          }
        }
        return (
          <>
            {renderRowTxByData({
              title: 'Txn:',
              tx: receivedDepositTx,
              explorer: receivedDepositLinkExplorer,
            })}
            {renderRowTxByData({
              title: 'Process Txn:',
              tx: tcProcessDepositTx,
              explorer: tcProcessDepositTxLinkExplorer,
            })}
          </>
        );
      }
      case 'WITHDRAW': {
        let burnedWithdrawTx = txBurnedWithdraw;
        let burnedWithdrawTxLinkExplorer = undefined;

        let processWithdrawTx = txProcessWithdraw;
        let processWithdrawTxLinkExplorer = undefined;

        switch (typeLowerCase) {
          // L1 -> External (BTC | ETH)
          case 'withdraw':
            {
              // Withdraw Tx
              burnedWithdrawTxLinkExplorer = EXPLORER_LINK.TC;

              // Withdraw Process Tx
              processWithdrawTxLinkExplorer = getExplorerLinkByTokenInfo({
                tcTokenInfo,
              });
            }
            break;
          // L2 -> External (BTC | ETH)
          case 'withdraw l2':
            {
              // Withdraw Tx
              burnedWithdrawTxLinkExplorer = getExplorerOnLayer2({
                tcTokenInfo,
                networkList,
                networkName: fromNetwork,
              });

              // Withdraw Process Tx
              processWithdrawTxLinkExplorer = getExplorerLinkByTokenInfo({
                tcTokenInfo,
              });
            }
            break;

          // L2 -> L1
          case 'l2 => l1': {
            {
              // Withdraw Tx
              burnedWithdrawTxLinkExplorer = getExplorerOnLayer2({
                tcTokenInfo,
                networkList,
                networkName: fromNetwork,
              });

              // Withdraw Process Tx
              processWithdrawTxLinkExplorer = EXPLORER_LINK.TC;
            }
            break;
          }
        }
        return (
          <>
            {renderRowTxByData({
              title: 'Txn:',
              tx: burnedWithdrawTx,
              explorer: burnedWithdrawTxLinkExplorer,
            })}
            {renderRowTxByData({
              title: 'Process Txn:',
              tx: processWithdrawTx,
              explorer: processWithdrawTxLinkExplorer,
            })}
          </>
        );
      }
      case 'UNKNOW':
        return null;
    }
  };

  const renderAmountColumn = () => {
    if (typeText === 'DEPOSIT') {
      if (amountTc) {
        return <Title>{formatAmount(amountTc, true, 18)}</Title>;
      } else if (amountReceivedDeposit) {
        return (
          <Title>
            {formatAmount(amountReceivedDeposit, true, tcTokenInfo?.decimals)}
          </Title>
        );
      }
      return '--';
    }
    if (typeText === 'WITHDRAW') {
      if (amountTc) {
        return <Title>{formatAmount(amountTc, true, 18)}</Title>;
      } else if (amountBurnedWithdraw) {
        return <Title>{formatAmount(amountBurnedWithdraw, true, 18)}</Title>;
      }
      return '--';
    }
  };

  const renderReceivingAddress = () => {
    let receivingAddress;

    if (typeText === 'DEPOSIT') {
      receivingAddress = tcAddress;
    } else if (typeText === 'WITHDRAW') {
      receivingAddress = withdrawWallet;
    } else {
      receivingAddress = tcAddress;
    }
    return (
      <Row>
        <Title
          style={{
            textAlign: 'left',
            marginRight: 10,
          }}
        >
          {'Receiving Address: '}
        </Title>
        {receivingAddress ? (
          <>
            <Text
              style={{
                color: '#b1e3ff',
                fontWeight: 'bold',
              }}
            >
              {ellipsisCenter({
                limit: 5,
                str: receivingAddress,
              })}
            </Text>
            {renderBtnCopyAddress(receivingAddress)}
          </>
        ) : (
          <p>{'--'}</p>
        )}
      </Row>
    );
  };

  return (
    <Container>
      <WrapTitle
        style={{
          maxWidth: '130px',
          display: 'flex',
          justifyContent: 'flex-start',
        }}
      >
        <Title
          style={{
            textTransform: 'capitalize',
            lineBreak: 'auto',
            overflow: 'unset',
            wordBreak: 'break-word',
            textAlign: 'inherit',
          }}
        >
          {/* BE handle this field */}
          {fromToNetworkInfo}
          {/* {renderDirection()} */}
        </Title>
      </WrapTitle>
      <WrapTitle style={{ flex: 2, maxWidth: 290 }}>
        {/* <Column>{renderTransactionContentColumn()}</Column> */}
        <Column>
          {renderTransactionContentColumnNEW()}
          {renderReceivingAddress()}
        </Column>
      </WrapTitle>
      <WrapTitle style={{ flex: 0.8, maxWidth: 100 }}>
        {renderAmountColumn()}
      </WrapTitle>
      <WrapTitle className="fee-item" style={{ flex: 0.5, maxWidth: 120 }}>
        <Title>{formatAmount(fee, true)}</Title>
      </WrapTitle>
      <WrapTitle className="status-item" style={{ flex: 0.5, maxWidth: 80 }}>
        <Row style={{ gap: 6 }}>
          <Image
            iconUrl={
              tcTokenInfo?.logo ||
              getIconUrl({
                symbol: formatSymbol,
              })
            }
            size={28}
            alt={`${formatSymbol}`}
          />
          <Title style={{ textTransform: 'uppercase' }}>{formatSymbol}</Title>
        </Row>
      </WrapTitle>
      <WrapTitle
        style={{
          flex: 0.8,
          maxWidth: 100,
          justifyContent: 'center',
        }}
      >
        {renderStatus()}
      </WrapTitle>
      <WrapTitle className="time-item" style={{ flex: 0.8, maxWidth: 100 }}>
        <Row>
          <Title>
            {formatDateTime({
              dateTime: new Date(createdAt).getTime(),
            }).toLocaleString()}
          </Title>
        </Row>
      </WrapTitle>
      {!!convertTCL2Data && isShowModalClaimTCl2 && (
        <TCL2TransactorModal
          isShow={isShowModalClaimTCl2}
          onHide={() => setIsShowModalClaimTCl2(false)}
          data={convertTCL2Data}
          fetchData={getConvertTCL2Data}
          address={addressInput}
          symbol={formatSymbol}
        />
      )}
    </Container>
  );
};

export default HistoryItem;
