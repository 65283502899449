import { Container } from './Maintenance.styled';

const MaintenancePage = () => {
  return (
    <Container>
      <p className="title">Temporarily Down for Maintenance</p>
      <p className="decs">
        We are performing scheduled maintenance. We should be back online shortly.
      </p>
    </Container>
  );
};

export default MaintenancePage;
