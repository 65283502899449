import { ellipsisCenter } from '@/utils/address';
import { isProduction } from '@/utils/commons';
import { TransactionStatus } from './History.constants';
import { StatusType, TcTokenInfo } from './History.types';
import { INetwork } from '../../state/network/types';

type TxStatusDecoratorType = {
  color: string;
  statusStr: string;
};

type TxStatusDecoratorPayload = {
  statusStr?: string;
  statusType: StatusType;
  statusCode: number;
};

export const isTxProcessing = (status: number): boolean => {
  if (
    TransactionStatus.SUCCESS.includes(status) ||
    TransactionStatus.INVALID.includes(status)
  ) {
    return false;
  }
  return true;
};

export const isTxWatingInscribed = (status: number): boolean => {
  return TransactionStatus.WAITING_INSCRIBED.includes(status);
};

export const isTxInvalid = (status: number): boolean => {
  return TransactionStatus.INVALID.includes(status);
};

export const isTxInsufficientFee = (status: number): boolean => {
  return TransactionStatus.TOPUP_FEE.includes(status);
};

export const isPendingTx = (status: number): boolean => {
  return TransactionStatus.PENDING.includes(status);
};

export const isProcessingTx = (status: number): boolean => {
  return TransactionStatus.PROCESSING.includes(status);
};

export const isSuccessfulTx = (status: number): boolean => {
  return TransactionStatus.SUCCESS.includes(status);
};

export const isFailed = (status: number): boolean => {
  return TransactionStatus.FAILED.includes(status);
};

const StatusColor = {
  [StatusType.Success]: '#18CF8E',
  [StatusType.Failed]: 'red',
  [StatusType.Processing]: '#FF7E21',
  [StatusType.Info]: '#FF7E21',
};

const getTxStatusDecorator = ({
  statusStr,
  statusType,
  statusCode,
}: TxStatusDecoratorPayload): TxStatusDecoratorType => {
  let result: TxStatusDecoratorType;
  if (statusStr && statusType !== undefined) {
    return {
      color: StatusColor[statusType],
      statusStr,
    };
  }

  // TODO: Remove later
  if (TransactionStatus.SUCCESS.includes(statusCode)) {
    result = {
      color: StatusColor[StatusType.Success],
      statusStr: 'Success',
    };
  } else if (TransactionStatus.INVALID.includes(statusCode)) {
    result = {
      color: StatusColor[StatusType.Processing],
      statusStr: 'Invalid',
    };
  } else if (TransactionStatus.PENDING.includes(statusCode)) {
    result = {
      color: 'yellow',
      statusStr: 'Pending',
    };
  } else if (TransactionStatus.WAITING_INSCRIBED.includes(statusCode)) {
    result = {
      color: '#B1E3FF',
      statusStr: 'Waiting for Inscribed',
    };
  } else if (TransactionStatus.TOPUP_FEE.includes(statusCode)) {
    result = {
      color: StatusColor[StatusType.Processing],
      statusStr: 'Insufficient fee',
    };
  } else if (TransactionStatus.FAILED.includes(statusCode)) {
    result = {
      color: StatusColor[StatusType.Failed],
      statusStr: 'Failed',
    };
  } else {
    result = {
      color: StatusColor[StatusType.Processing],
      statusStr: 'Processing',
    };
  }
  return result;
};

export const getToolTipMessage = (status: number): string => {
  let toolTipMessage = '';
  if (isPendingTx(status)) {
    toolTipMessage = 'Wait for the required number of on-chain confirmations.';
  } else if (isSuccessfulTx(status)) {
    toolTipMessage = 'The transaction has been completed.';
  } else if (isFailed(status)) {
    toolTipMessage = '';
  } else {
    toolTipMessage =
      'The necessary on-chain confirmations have been received, and the processing is currently underway.';
  }
  return toolTipMessage;
};

export const EXPLORER_LINK = {
  Bitcoin: isProduction()
    ? 'https://mempool.space/tx'
    : 'https://blockstream.regtest.trustless.computer/tx',
  Ethereum: isProduction()
    ? 'https://etherscan.io/tx'
    : 'https://sepolia.etherscan.io/tx',
  TC: isProduction()
    ? 'https://explorer.trustless.computer/tx'
    : 'https://explorer.regtest.trustless.computer/tx',
  TCLayer2: isProduction()
    ? 'https://explorer.l2.trustless.computer/tx'
    : 'https://nos-explorer.regtest.trustless.computer/tx',
  Solana: isProduction()
    ? 'https://explorer.solana.com/tx'
    : 'https://explorer.solana.com/tx',
};

export const ellipsisByTx = ({ tx, limit = 4 }: { tx: string; limit?: number }) => {
  return ellipsisCenter({
    limit,
    str: tx || '--',
  });
};

export const getExplorerLinkByTokenInfo = ({
  tcTokenInfo,
}: {
  tcTokenInfo: TcTokenInfo | undefined;
}) => {
  if (!tcTokenInfo) return undefined;
  if (
    tcTokenInfo.network?.toUpperCase() === 'BITCOIN' ||
    tcTokenInfo.name?.toUpperCase() === 'BITCOIN' ||
    tcTokenInfo.symbol?.toUpperCase() === 'BTC' ||
    tcTokenInfo.type?.toUpperCase() === 'BRC20'
  ) {
    return EXPLORER_LINK.Bitcoin;
  } else if (
    tcTokenInfo.network?.toUpperCase() === 'ETHEREUM' ||
    tcTokenInfo.name?.toUpperCase() === 'ETHEREUM'
  ) {
    return EXPLORER_LINK.Ethereum;
  } else if (
    tcTokenInfo.network?.toUpperCase() === 'SOLANA' ||
    tcTokenInfo.name?.toUpperCase() === 'SOL'
  ) {
    return EXPLORER_LINK.Solana;
  } else {
    return undefined;
  }
};

export const getExplorerOnLayer2 = ({
  tcTokenInfo,
  networkList,
  networkName,
}: {
  tcTokenInfo: TcTokenInfo | undefined;
  networkList: INetwork[];
  networkName?: string;
}) => {
  if (networkName) {
    const networkObject = networkList.find(
      (network) => network.networkName === networkName,
    );

    return networkObject?.explorerUrl
      ? `${networkObject?.explorerUrl}/tx`
      : undefined;
  } else {
    if (!tcTokenInfo?.networkId || tcTokenInfo?.networkId.length < 1)
      return EXPLORER_LINK.TCLayer2;

    const networkId = tcTokenInfo.networkId;
    const networkObject = networkList.find((network) => network.id === networkId);

    return networkObject?.explorerUrl
      ? `${networkObject?.explorerUrl}/tx`
      : undefined;
  }
};

export const getDirectionStr = ({
  fromNetworkName,
  toNetworkName,
  networkList,
}: {
  fromNetworkName: string | undefined;
  toNetworkName: string | undefined;
  networkList: INetwork[];
}) => {
  let fromNetworkStr;
  let toNetworkStr;

  if (!fromNetworkName) {
    const networkObject = networkList.find(
      (network) => network.networkName === fromNetworkName,
    );
    fromNetworkStr = networkObject?.networkTitle;
  }

  if (!toNetworkName) {
    const networkObject = networkList.find(
      (network) => network.networkName === toNetworkName,
    );
    toNetworkStr = networkObject?.networkTitle;
  }

  return `${fromNetworkStr} -> ${toNetworkStr}`;
};

export const getExplorerLink = ({
  networkName,
  networkList,
}: {
  networkName: string | undefined;
  networkList: INetwork[];
}) => {
  if (!networkName || !networkList) return undefined;

  const networkObject = networkList.find(
    (network) => network.networkName === networkName,
  );

  return networkObject?.explorerUrl ? `${networkObject?.explorerUrl}/tx` : undefined;
};

export { getTxStatusDecorator };
