import { Image } from '@/components/Image';
import { ellipsisCenter } from '@/utils/address';
import { formatDateTime } from '@/utils/time';
import { getIconUrl } from '@/utils/token';
import { Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import {
  getEstimateTimeProcess,
  getFormBridgeInfo,
  getEstimateWithdrawSelector,
} from '../state/selector';
import CopyIcon2 from '@/components/IconSVG/CopyIcon2';
import toast from 'react-hot-toast';
import copy from 'copy-to-clipboard';
import { NetworkName } from '@/state/network/types';
import { getNetworkByNameSelector } from '@/state/network/selector';

export const ModalStyled = styled(Modal)`
  .modal-content {
    padding: 15px;
    background: #1b1e26;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 16px;
  }

  .modal-header {
    border-bottom: 0.5px solid #353945;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 200px;
  width: 100%;
  border-radius: 10px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 5px;
`;

const Box = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Title = styled.p`
  &&& {
    opacity: 1;
  }
`;

const Label = styled.p`
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
  text-align: left;
  color: ${({ theme }) => theme.colors.border2};
  opacity: 0.6;
`;

const Value = styled.p`
  font-size: 16px;
  line-height: 20px;
  text-align: left;
  color: ${({ theme }) => theme.colors.white};
`;

export const SubmitButtonStyled = styled.button`
  margin-top: 30px;
  width: 100%;
  height: 50px;
  align-self: center;

  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 16px;
  text-align: center;
  color: #fcfcfd;

  background: #3772ff;
  border-radius: 100px !important;

  :disabled {
    opacity: 0.5;
    :hover {
      opacity: 0.5;
    }
  }

  :hover {
    opacity: 0.8;
  }
`;

type Props = {
  amount: string;
  receiverAddress: string;
  txHash: string;
  isShow?: boolean;
  onHide?: any;
};

type RenderRowType = {
  label: string;
  value: string;
  network?: NetworkName;
  token?: string;
  isCopy?: boolean;
  dataCopy?: string;
};

function WithdrawProcessingModal(props: Props) {
  const { isShow, onHide, amount, receiverAddress, txHash } = props;
  const { data } = useSelector(getEstimateWithdrawSelector);
  const getNetworkByNameFunc = useSelector(getNetworkByNameSelector);
  const withdrawFeeHRFormatStr = data?.withdrawFeeHRFormatStr;
  const { fromTokenSelected, fromNetworkSelected, toNetworkSelected } =
    useSelector(getFormBridgeInfo);
  const estimateTimeData = useSelector(getEstimateTimeProcess);

  const receiverAddressShorten =
    receiverAddress &&
    ellipsisCenter({
      limit: 12,
      str: receiverAddress,
    });

  const txHashShorten =
    txHash &&
    ellipsisCenter({
      limit: 12,
      str: txHash,
    });

  const renderNetworkIcon = (network: NetworkName) => {
    const networkObj = getNetworkByNameFunc(network);
    return (
      <Image
        iconUrl={networkObj?.networkLogo || ''}
        size={30}
        alt={`${network}`}
        style={{ marginLeft: 7, marginRight: 7, width: 30, height: 30 }}
      />
    );
  };

  const onClickCopy = (text: string | undefined) => {
    if (text) {
      toast.success('Copied');
      copy(text);
    }
  };

  const renderTokenIcon = (symbol: string) => {
    return (
      <Image
        iconUrl={getIconUrl({
          symbol,
        })}
        size={32}
        alt={`${symbol}`}
        style={{ marginLeft: 7, marginRight: 7, width: 32, height: 32 }}
      />
    );
  };

  const renderRow = (params: RenderRowType) => {
    const { label, value, network, token, isCopy = false, dataCopy } = params;
    return (
      <Row>
        <Label>{label}</Label>
        <Box>
          {network && renderNetworkIcon(network)}
          {token && renderTokenIcon(token)}
          <Value>{value}</Value>
          {isCopy && (
            <CopyIcon2
              className="hover"
              scale={0}
              onClick={() => onClickCopy(dataCopy)}
            />
          )}
        </Box>
      </Row>
    );
  };

  return (
    <ModalStyled centered show={isShow} keyboard={false} onHide={onHide}>
      <Modal.Header closeButton color="white" closeVariant="white">
        <Modal.Title style={{ color: 'green' }}>{'Withdraw Processing'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          {renderRow({
            label: 'From',
            value: `${fromNetworkSelected} Network`,
            network: fromNetworkSelected,
          })}

          {renderRow({
            label: 'To',
            value: `${toNetworkSelected} Network`,
            network: toNetworkSelected,
          })}

          {renderRow({
            label: 'Token',
            value: `${fromTokenSelected?.symbol?.toUpperCase()}`,
            token: fromTokenSelected?.symbol,
          })}

          {renderRow({
            label: 'Amount',
            value: `${amount}`,
          })}

          {renderRow({
            label: 'Transfer fee (est.)',
            value: `${withdrawFeeHRFormatStr} ${(
              fromTokenSelected?.symbol || 'Unknow'
            ).toUpperCase()}`,
          })}

          {renderRow({
            label: 'Transfer time',
            value: `~ ${estimateTimeData?.estTimeStr}`,
          })}

          {renderRow({
            label: 'Receiving address',
            value: `${receiverAddressShorten}`,
            isCopy: true,
            dataCopy: receiverAddress,
          })}

          {renderRow({
            label: 'TxHash',
            value: `${txHashShorten}`,
            isCopy: true,
            dataCopy: txHash,
          })}

          {renderRow({
            label: 'Time',
            value: `${formatDateTime({
              dateTime: new Date().getTime(),
            }).toLocaleString()}`,
          })}

          <SubmitButtonStyled onClick={onHide}>OK</SubmitButtonStyled>
        </Container>
      </Modal.Body>
    </ModalStyled>
  );
}

export default WithdrawProcessingModal;
