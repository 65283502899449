import { Input2 } from '@/components/Inputs/Input2';
import validator, { composeValidators } from '@/utils/validator';
import { NETWORK_SUPPORTING, isLayer2, isOutChain } from '@constants/network';
import { useWeb3React } from '@web3-react/core';
import { useEffect, useMemo } from 'react';
import { Field, useForm } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Fields, FormInitialValues } from '../FormBridge.constants';
import { formBridgeActions } from '../state/reducer';
import { getFormBridgeInfo } from '../state/selector';

export const Title = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  text-align: left;
  color: #ffffff;
`;

const ToInputField = () => {
  const {
    fromTokenSelected,
    fromNetworkSelected,
    formType,
    fromBalanceFormatedStr,
    generateDepositData,
    toNetworkSelected,
    toTokenSelected,
    isSwitchNetwork,
    toInputPlaceHolder,
    disableSubmitBtn,
    isBurnNativeToken,
    formInstance,
  } = useSelector(getFormBridgeInfo);

  const form = useForm();
  const dispatch = useDispatch();
  const { account: metasMaskAddress, chainId } = useWeb3React();

  useEffect(() => {
    if (form) {
      form.reset(FormInitialValues);
      form.resetFieldState(Fields.AMOUNT);
      form.resetFieldState(Fields.ADDRESS);
    }
  }, [fromTokenSelected, fromNetworkSelected, toNetworkSelected]);

  const validators = useMemo(() => {
    const validators: any[] = [];

    if (disableSubmitBtn) return undefined;

    switch (formType) {
      case 'Deposit':
        validators.push(validator.addressRequired(), validator.validateEVMAddress());
        break;

      case 'Withdraw': {
        if (
          toNetworkSelected === NETWORK_SUPPORTING.BITCOIN ||
          toNetworkSelected === NETWORK_SUPPORTING.ORDINALS
        ) {
          validators.push(
            validator.addressRequired(),
            validator.validateBTCAddress(),
          );
        }
        if (toNetworkSelected === NETWORK_SUPPORTING.ETHEREUM) {
          validators.push(
            validator.addressRequired(),
            validator.validateEVMAddress(),
          );
        }

        if (
          toNetworkSelected === NETWORK_SUPPORTING.TRUSTLESS_LAYER1 ||
          isLayer2(toNetworkSelected)
        ) {
          return undefined;
        }
        break;
      }
      default:
        return undefined;
    }
    return composeValidators(...validators);
  }, [
    fromTokenSelected,
    fromNetworkSelected,
    formType,
    fromBalanceFormatedStr,
    generateDepositData,
    toNetworkSelected,
    toTokenSelected,
    isSwitchNetwork,
    disableSubmitBtn,
    chainId,
    isBurnNativeToken,
    formInstance,
  ]);

  useEffect(() => {
    form.reset({});
    form.resetFieldState(Fields.ADDRESS);
  }, [fromTokenSelected, fromNetworkSelected, chainId]);

  const disableTextInput = useMemo(() => {
    if (isOutChain(toNetworkSelected)) return false;
    else return true;
  }, [fromNetworkSelected, toNetworkSelected, fromTokenSelected, metasMaskAddress]);

  // const disableTextInput = useMemo(() => {
  //   if (
  //     (fromNetworkSelected === NETWORK_SUPPORTING.TRUSTLESS_LAYER1 &&
  //       isLayer2(toNetworkSelected)) ||
  //     (isLayer2(fromNetworkSelected) &&
  //       toNetworkSelected === NETWORK_SUPPORTING.TRUSTLESS_LAYER1)
  //   ) {
  //     return true;
  //   }
  //   return false;
  // }, [fromNetworkSelected, toNetworkSelected, fromTokenSelected, metasMaskAddress]);

  return (
    <>
      {disableTextInput && <Title>{'Receiving Address'}</Title>}
      <Field name={Fields.ADDRESS} validate={validators}>
        {({ input, meta }) => {
          if (disableTextInput && metasMaskAddress) {
            input.onChange(metasMaskAddress);
          }
          return (
            <Input2
              {...input}
              {...meta}
              onChange={(event: any) => {
                dispatch(formBridgeActions.setToInputAmount(event.target.value));
                input.onChange(event);
              }}
              id={Fields.ADDRESS}
              name={Fields.ADDRESS}
              placeholder={disableTextInput ? metasMaskAddress : toInputPlaceHolder}
              type="text"
              spellCheck={false}
              onWheel={(e: any) => e?.target?.blur()}
              disabled={disableTextInput}
              // autoComplete="off"
            />
          );
        }}
      </Field>
    </>
  );
};

export default ToInputField;
