import Text from '@/components/Text';
import { memo } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { getFormBridgeInfo, getGenerateDepositSelector } from '../state/selector';
import Skeleton from 'react-loading-skeleton';

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const DepositFeeEstimate = () => {
  const { isLoading, data } = useSelector(getGenerateDepositSelector);
  const { fromTokenSelected } = useSelector(getFormBridgeInfo);

  if (isLoading) return <Skeleton count={1} />;
  if (!data || !fromTokenSelected) return null;

  const { depositFeeHRFormatStr } = data;

  if (!depositFeeHRFormatStr) return null;

  return (
    <Container>
      <Text>{'Transfer fee (est.)'}</Text>
      <Text>{`${depositFeeHRFormatStr} ${(
        fromTokenSelected?.symbol || 'Unknow'
      ).toUpperCase()}`}</Text>
    </Container>
  );
};

export default memo(DepositFeeEstimate);
