import { useWeb3React } from '@web3-react/core';
import { useEffect } from 'react';
import { batch, useSelector } from 'react-redux';
import { FormBridgeType } from './FormBridge.types';
import { getFormBridgeInfo } from './state/selector';

import { useAppDispatch } from '@/state/hooks';
import BigNumber from 'bignumber.js';
import { formBridgeActions } from './state/reducer';
import useGasInfor from '@/hooks/useGasInfor';

const enhanceGasEstimate = (WrappedComponent: any) => (props: FormBridgeType) => {
  const dispatch = useAppDispatch();
  const { account: tcAddress, provider } = useWeb3React();
  const { fromTokenSelected, isBurnNativeToken } = useSelector(getFormBridgeInfo);

  const { getGasPrice, getGasLimit } = useGasInfor();

  const fetchFeeBurnNativeToken = async () => {
    let fee = '0';
    try {
      dispatch(formBridgeActions.setLoading(true));

      const gasPrice = await getGasPrice();
      const gasLimit = await getGasLimit();

      fee = new BigNumber(gasPrice || 0).multipliedBy(gasLimit).toFixed();

      console.log('[fetchFeeBurnNativeToken] DATA : ', {
        gasPrice,
        gasLimit,
        fee,
      });
    } catch (e: any) {
      console.log('[fetchFeeBurnBVM] ERROR: ', e);
      fee = '0';
    } finally {
      batch(() => {
        dispatch(formBridgeActions.setLoading(false));
        dispatch(formBridgeActions.setFeeBurnNativeToken(fee));
      });
    }
  };

  useEffect(() => {
    if (isBurnNativeToken && provider) {
      fetchFeeBurnNativeToken();
    }
  }, [tcAddress, fromTokenSelected, isBurnNativeToken, provider]);

  return <WrappedComponent {...{ ...props }} />;
};

export default enhanceGasEstimate;
