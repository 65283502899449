import { ContractOperationHook, DAppType } from '@/interfaces/contract-operation';
import { useWeb3React } from '@web3-react/core';
import { useCallback } from 'react';
import { TransactionEventType } from '@/enums/transaction';
import { getContract } from '@/utils';
import ConvertTCL2ToL1Json from '@/abis/tcBridgeFromL2.json';
import { ethers } from 'ethers';

export interface IConvertTCL2ToL1Params {
  bridgeAddress: string;
  tokenAddress: string;
  amount: string;
  isNative: boolean;
}

const useConvertTCL2ToL1: ContractOperationHook<
  IConvertTCL2ToL1Params,
  any | undefined
> = () => {
  const { account, provider } = useWeb3React();
  const call = useCallback(
    async (params: IConvertTCL2ToL1Params): Promise<any | undefined> => {
      if (account && provider) {
        const { amount, bridgeAddress, tokenAddress, isNative } = params;
        const contract = getContract(
          bridgeAddress,
          ConvertTCL2ToL1Json,
          provider,
          account,
        ).connect(provider.getSigner());
        const tx = await contract.withdraw(tokenAddress, amount, '0', [], {
          value: isNative
            ? ethers.BigNumber.from(amount)
            : ethers.BigNumber.from('0'),
        });
        if (tx) {
          return {
            hash: Object(tx).hash,
          };
        }
      }
      return undefined;
    },
    [account, provider],
  );

  return {
    call: call,
    dAppType: DAppType.Contract,
    transactionType: TransactionEventType.DEPLOY,
  };
};

export default useConvertTCL2ToL1;
