import useGetTokenBalance from '@/hooks/contract-operations/useTokenBalance';
import useGetNativeBalance from '@/hooks/useGetNativeBalance';
import { useAppDispatch } from '@/state/hooks';
import { logger } from '@/utils/logger';
import { useWeb3React } from '@web3-react/core';
import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { isDeposit } from './FormBridge.utils';
import { formBridgeActions } from './state/reducer';
import { getFormBridgeInfo } from './state/selector';
import React from 'react';
import { debounce } from 'lodash';
import { isLayer2 } from '@/constants/network';

const LOAD_BALANCE_INTERVAL_TIMER = 12000; //Other (Etherum | L1 ....)
const LOAD_BALANCE_INTERVAL_TIMER_L2 = 6000; //6s for L2

const enhanceBalance = (WrappedComponent: any) => (props: any) => {
  const { getBalance: getNativeBalance } = useGetNativeBalance();
  const { call: getTokenBalance } = useGetTokenBalance();
  const dispatch = useAppDispatch();
  const web3Data = useWeb3React();

  const timerRef = React.useRef<any>();

  const {
    isNativeToken,
    fromTokenSelected,
    toTokenSelected,
    fromNetworkSelected,
    toNetworkSelected,
    formType,
    isCorrectChain,
  } = useSelector(getFormBridgeInfo);

  const onGetTokenBalance = useCallback(async () => {
    try {
      if (fromTokenSelected && fromTokenSelected.tcTokenID) {
        const tokenBalance = await getTokenBalance({
          tokenAddress: fromTokenSelected.tcTokenID,
        });

        logger('Balance ', tokenBalance);

        dispatch(formBridgeActions.setFromBalance(tokenBalance));
      }
    } catch (error) {
      logger('[onGetTokenBalance] error', error);
    }
  }, [
    isNativeToken,
    fromTokenSelected,
    toTokenSelected,
    fromNetworkSelected,
    toNetworkSelected,
    web3Data,
    formType,
  ]);

  const INTERVAL_TIME = React.useMemo(() => {
    return isLayer2(fromNetworkSelected)
      ? LOAD_BALANCE_INTERVAL_TIMER_L2
      : LOAD_BALANCE_INTERVAL_TIMER;
  }, [
    isNativeToken,
    fromTokenSelected,
    toTokenSelected,
    fromNetworkSelected,
    toNetworkSelected,
    web3Data,
    formType,
  ]);

  const onGetNativeBalance = useCallback(async () => {
    try {
      if (!fromTokenSelected) return;
      if (isNativeToken) {
        const nativeBalance = await getNativeBalance();

        logger('[onGetNativeBalance] nativeBalance = ', nativeBalance);

        dispatch(formBridgeActions.setFromBalance(nativeBalance));
      }
    } catch (error) {
      logger('[onGetNativeBalance] error', error);
    }
  }, [
    isNativeToken,
    fromTokenSelected,
    fromNetworkSelected,
    toTokenSelected,
    web3Data,
    INTERVAL_TIME,
  ]);

  const clearTimer = () => {
    clearInterval(timerRef.current);
    timerRef.current = undefined;
  };

  const loadBalance = useCallback(
    debounce(async () => {
      if (isNativeToken) {
        // Get Balance Native (Ex ETH on Ethereum)
        onGetNativeBalance();
      } else {
        // Other Tokens , USDT on Ethereum, Wrap Tokens on TC1, TC2
        onGetTokenBalance();
      }
    }, 300),
    [
      isNativeToken,
      fromTokenSelected,
      fromNetworkSelected,
      toTokenSelected,
      web3Data,
      INTERVAL_TIME,
    ],
  );

  useEffect(() => {
    clearTimer();

    // Deposit Centralize => Don't Get Balance
    if (isDeposit(fromNetworkSelected)) {
      return;
    }

    // fromNetwork != current network on Metmask => Don't Get Balance
    if (!isCorrectChain) {
      return;
    }

    loadBalance();
    timerRef.current = setInterval(() => {
      loadBalance();
    }, INTERVAL_TIME);
    return () => {
      clearTimer();
    };
  }, [
    isNativeToken,
    fromTokenSelected,
    toTokenSelected,
    fromNetworkSelected,
    toNetworkSelected,
    web3Data,
    INTERVAL_TIME,
  ]);

  return <WrappedComponent {...{ ...props }} />;
};
export default enhanceBalance;
