import { RootState } from '@/state';
import { createSelector } from 'reselect';
import { sortToken } from './helper';

export const tokensReducerSelector = (state: RootState) => state.tokensReducer;

export const getTokenListSelector = createSelector(
  tokensReducerSelector,
  (reducer) => reducer.tokenList || [],
);

export const getTokenGroupNetworkSelector = createSelector(
  tokensReducerSelector,
  (reducer) => {
    const reuslt = reducer.tokenGroupNetwork || {};
    return reuslt;
  },
);

export const isTokenFetchingSelector = createSelector(
  tokensReducerSelector,
  (reducer) => reducer.isFetching,
);

export const getTokenMapSelector = createSelector(
  tokensReducerSelector,
  (reducer) => reducer.tokenMap,
);

export const getTokenListByNetworkSelector = createSelector(
  getTokenGroupNetworkSelector,
  (tokenGroupNetwork) => (network?: string) => {
    const tokenListByNetwork = (network && tokenGroupNetwork[network]) || [];
    return sortToken(tokenListByNetwork);
  },
);

export const filterTokenByNameSelector = createSelector(
  getTokenListSelector,
  (tokenList) => (tokenSymbol?: string) => {
    return tokenList.find((token) => token.symbol?.toLowerCase() === tokenSymbol);
  },
);

export const getTokenNativeCurrentNetworkSelector = createSelector(
  getTokenGroupNetworkSelector,
  (tokenGroupNetwork) => (network?: string) => {
    const tokenListByNetwork = (network && tokenGroupNetwork[network]) || [];
    return (
      tokenListByNetwork.find((token) => token.type.toUpperCase() === 'NATIVE') ??
      tokenListByNetwork[0] ??
      undefined
    );
  },
);

export const getTokenByToNetworkSelector = createSelector(
  getTokenGroupNetworkSelector,
  (tokenGroupNetwork) => (toNetwork?: string) => {
    const tokenListByNetwork = (toNetwork && tokenGroupNetwork[toNetwork]) || [];
    return (
      tokenListByNetwork.find((token) => token.type.toUpperCase() === 'NATIVE') ??
      tokenListByNetwork[0] ??
      undefined
    );
  },
);

export const getTokenBySymbolSelector = createSelector(
  getTokenListSelector,
  (tokenList) => (symbol: string) => {
    return tokenList.find(
      (token) => token.symbol?.toLowerCase() === symbol?.toLowerCase(),
    );
  },
);

export const getTokenInfoSelector = createSelector(
  [tokensReducerSelector],
  (state) => {
    const result = {
      ...state,
    };

    return result;
  },
);
