import { useAppDispatch } from '@/state/hooks';
import { throttle } from 'lodash';
import { useCallback } from 'react';
import { FormBridgeType } from './FormBridge.types';
import { formBridgeActions } from './state/reducer';

const enhanceSwap = (WrappedComponent: any) => (props: FormBridgeType) => {
  const dispatch = useAppDispatch();
  const swapHandler = useCallback(
    throttle(
      () => {
        dispatch(formBridgeActions.swapAction());
      },
      1000,
      {
        trailing: false,
        leading: true,
      },
    ),
    [],
  );

  return <WrappedComponent {...{ ...props, swapHandler }} />;
};
export default enhanceSwap;
