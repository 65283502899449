import {
  getReceiverTokenAddress,
  isLayer1Network,
} from '@/containers/FormBridge/FormBridge.utils';
import { getIsAuthenticatedSelector, getUserSelector } from '@/state/user/selector';
import { isOutChain } from '@constants/network';
import { useAppDispatch } from '@state/hooks';
import { useWeb3React } from '@web3-react/core';
import { debounce } from 'lodash';
import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { fetchEstimateWithdrawData } from './actions';
import { formBridgeActions } from './reducer';
import {
  getDefaultTokensSelectable,
  getFormBridgeInfo,
  getToTokenSelectedSelector,
} from './selector';
import { Token } from '@/state/tokens/types';

export default function FormUpdater(): null {
  const dispatch = useAppDispatch();

  const isAuthenticated = useSelector(getIsAuthenticatedSelector);
  const { account: metasMaskAddress } = useWeb3React();
  const { walletAddress: tcAddress } = useSelector(getUserSelector);
  const fromTokenDefault = useSelector(getDefaultTokensSelectable);
  const toTokenSelectedObj = useSelector(getToTokenSelectedSelector);

  const {
    fromTokenSelected,
    fromNetworkSelected,
    toNetworkSelected,
    formType,
    toTokenSelected,
    toNetworkObject,
    isBurnNativeToken,
  } = useSelector(getFormBridgeInfo);

  const tcTokenID = React.useMemo(() => {
    return getReceiverTokenAddress({
      network: toNetworkSelected,
      token: fromTokenSelected,
      formType,
    });
  }, [fromTokenSelected, toNetworkSelected, formType]);

  // Update Default Selected Token
  useEffect(() => {
    dispatch(formBridgeActions.setFromTokenSelected(fromTokenDefault));
  }, [fromNetworkSelected, toNetworkSelected, fromTokenDefault]);

  const debounceFetchEsimateWithdraw = useCallback(
    debounce(
      ({
        fromTokenSelected,
        tcTokenID,
        metasMaskAddress,
        toNetworkSelected,
        toNetworkObject,
        toTokenSelectedObj,
        fromNetworkSelected,
      }) => {
        if (
          !fromTokenSelected ||
          !fromNetworkSelected ||
          !tcTokenID ||
          !metasMaskAddress ||
          !toTokenSelectedObj
        ) {
          return;
        }
        const isBridgeLayer = !isOutChain(toNetworkSelected);

        dispatch(
          fetchEstimateWithdrawData({
            tcTokenID: tcTokenID,
            tcAddress: tcAddress || metasMaskAddress || '',
            isBridgeLayer,
            dstNetworkId: isBridgeLayer ? toNetworkObject?.id : '',
            dstTcTokenID: (toTokenSelectedObj as Token).tcTokenID,
            network: fromNetworkSelected,
          }),
        );
      },
      500,
    ),
    [],
  );

  const clearEstimateWithdrawDataHandler = () => {
    dispatch(formBridgeActions.clearEstimateWithdrawData());
    debounceFetchEsimateWithdraw({ toNetworkSelected: undefined });
  };

  useEffect(() => {
    if (!fromTokenSelected || !tcTokenID) {
      return;
    }
    switch (formType) {
      case 'Deposit':
        break;
      case 'Withdraw':
        {
          if (isLayer1Network(fromNetworkSelected)) {
            //Required Connected TC Wallet
            if (!isAuthenticated || !metasMaskAddress || !tcAddress) {
              clearEstimateWithdrawDataHandler();
              return;
            }
          }

          // Only Native Token on Trustless chain => Not Call API Estimate
          if (fromTokenSelected?.isNativeBridge === true && !isBurnNativeToken) {
            clearEstimateWithdrawDataHandler();
            return;
          }

          debounceFetchEsimateWithdraw({
            fromTokenSelected,
            tcTokenID,
            metasMaskAddress,
            toNetworkSelected,
            toNetworkObject,
            toTokenSelectedObj,
            fromNetworkSelected,
          });
        }
        break;
      default:
        break;
    }
  }, [
    fromTokenSelected,
    formType,
    isAuthenticated,
    metasMaskAddress,
    fromNetworkSelected,
    tcAddress,
    toNetworkSelected,
    tcTokenID,
    toTokenSelected,
    toNetworkObject,
    toTokenSelectedObj,
    isBurnNativeToken,
  ]);

  useEffect(() => {
    if (!isAuthenticated || !metasMaskAddress) {
      dispatch(formBridgeActions.setGenerateDepositData(undefined));
    }
  }, [isAuthenticated, metasMaskAddress]);

  return null;
}
