import { Box, Row, RowTextInput, Title1 } from './FormBridge.styles';

import FromBalanceBox from './components/FromBalanceBox';
import FromInputField from './components/FromInputField';
import FromInputFieldError from './components/FromInputFieldError';

import ToInputField from './components/ToInputField';
import ToInputFieldError from './components/ToInputFieldError';

import Space from '@components/Space';
import { useSelector } from 'react-redux';
import DepositFeeEstimate from './components/DepositFeeEstimate';
import Divider from './components/Divider';
import EstimateTimeBox from './components/EstimateTimeBox';
import MinimumWithdrawAmount from './components/MinimumWithdrawAmount';
import SwitchChainWarning from './components/SwitchChainWarning';
import WarningNeedDepositBTCMore from './components/WarningNeedDepositBTCMore';
import WithdrawFeeEstimate from './components/WithdrawFeeEstimate';
import YourBTCBalance from './components/YourBTCBalance';
import { getFormBridgeInfo } from './state/selector';
import MaxButton from './components/MaxButton';
import GasPriceEst from './components/GasPriceEst';

const EVMInput = (props: any) => {
  const { switchChainHandler, maxHandler } = props;

  const { formType } = useSelector(getFormBridgeInfo);

  return (
    <>
      <Row>
        <Title1>{'Total amount'}</Title1>
        <FromBalanceBox />
      </Row>

      <Space.Vertical size={8} />

      <Box style={{ gap: 8 }}>
        <RowTextInput>
          <FromInputField />
          <MaxButton onClick={maxHandler} />
        </RowTextInput>
      </Box>
      <FromInputFieldError />

      <Space.Vertical size={4} />

      <Box style={{ gap: 8 }}>
        <ToInputField />
        <ToInputFieldError />
      </Box>

      <Space.Vertical size={16} />
      <Divider />
      <Space.Vertical size={16} />

      {formType === 'Deposit' && <DepositFeeEstimate />}
      {formType === 'Withdraw' && (
        <>
          {/* <WarningNeedDepositBTCMore /> */}
          {/* <YourBTCBalance /> */}
          <WithdrawFeeEstimate />
          <MinimumWithdrawAmount />
          <GasPriceEst />
        </>
      )}
      <EstimateTimeBox />
      <SwitchChainWarning switchChainOnClick={switchChainHandler} />
    </>
  );
};

export default EVMInput;
