// import CopyIcon from '@/components/Copy';
import CopyIcon2 from '@/components/IconSVG/CopyIcon2';
import Text from '@/components/Text';
import { ellipsisCenter } from '@/utils/address';
import copy from 'copy-to-clipboard';
import toast from 'react-hot-toast';
import Skeleton from 'react-loading-skeleton';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { getGenerateDepositSelector } from '../FormBridge/state/selector';

const Container = styled.div`
  flex: 1;
  min-height: 80px;

  min-height: 50px;

  .title {
    color: ${({ theme }) => theme.colors.text1};
  }

  .address-area {
    padding: 5px;
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    display: flex;
    align-items: center;
    gap: 12px;

    background: ${({ theme }) => theme.colors.black};
    border: 1px solid ${({ theme }) => theme.colors.border1};
    border-radius: 12px;

    span {
      width: -webkit-fill-available;
    }

    .wrapper-text {
      display: flex;
      flex: 1;
      font-family: 'TitilliumWeb-Semibold';
      margin-left: 10px;
      overflow: hidden;
      color: ${({ theme }) => theme.colors.white};
    }

    .wrapper-icon {
      display: flex;
      justify-content: center;
      align-items: center;

      :hover {
        cursor: pointer;
        opacity: 0.8;
      }
    }
  }

  .blur {
    -webkit-filter: blur(8px);
    filter: blur(3px);
  }
`;

const AddressBox = () => {
  const { isLoading, data } = useSelector(getGenerateDepositSelector);
  const depositAddress = data?.depositAddress;

  const addressShorten = ellipsisCenter({
    limit: 20,
    str: depositAddress || 'xxxxxxxxxxx...xxxxxxxxxxx',
  });

  const classNameBlur = depositAddress ? '' : 'blur';

  if (isLoading)
    return (
      <Container>
        <Skeleton count={2} />
      </Container>
    );

  return (
    <Container>
      <Text className={`title ${classNameBlur}`}>{'Address'}</Text>
      <div className={`address-area ${classNameBlur}`}>
        <input
          className={`wrapper-text`}
          disabled={true}
          value={addressShorten}
        ></input>
        <div
          className={`wrapper-icon ${classNameBlur}`}
          onClick={() => {
            if (depositAddress) {
              toast.success('Copied');
              copy(depositAddress);
            }
          }}
        >
          <CopyIcon2 />
        </div>
      </div>
    </Container>
  );
};

export default AddressBox;
