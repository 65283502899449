import { ContractOperationHook, DAppType } from '@/interfaces/contract-operation';
import { useWeb3React } from '@web3-react/core';
import { useCallback } from 'react';
import { TransactionEventType } from '@/enums/transaction';
import { getContract } from '@/utils';
import WBridgeLayer2Json from '@/abis/wbridge.layer2.json';
import { ethers } from 'ethers';

export interface IBurnETHNativeToLayer2Params {
  bridgeAddress: string;
  amount: string; // original amount // 1000000000000000
  receiver: string;
  chainID: number;
}

const useBurnETHNativeToLayer2: ContractOperationHook<
  IBurnETHNativeToLayer2Params,
  any | undefined
> = () => {
  const { account, provider } = useWeb3React();
  const call = useCallback(
    async (params: IBurnETHNativeToLayer2Params): Promise<any | undefined> => {
      if (account && provider) {
        const { bridgeAddress, amount, receiver, chainID } = params;
        const contract = getContract(
          bridgeAddress,
          WBridgeLayer2Json,
          provider,
          account,
        ).connect(provider.getSigner());
        const tx = await contract['bridgeToken(string,uint256)'](receiver, chainID, {
          value: ethers.BigNumber.from(amount),
        });
        if (tx) {
          return {
            hash: Object(tx).hash,
          };
        }
      }
      return undefined;
    },
    [account, provider],
  );

  return {
    call: call,
    dAppType: DAppType.Contract,
    transactionType: TransactionEventType.DEPLOY,
  };
};

export default useBurnETHNativeToLayer2;
