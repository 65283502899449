import { ContractOperationHook, DAppType } from '@/interfaces/contract-operation';
import { useWeb3React } from '@web3-react/core';
import { useCallback } from 'react';
import { TransactionEventType } from '@/enums/transaction';
import { getContract } from '@/utils';
import WBridgeBVMJson from '@/abis/wbridgeBVM.layer1.json';
import { ethers } from 'ethers';
import { GAS_LITMIT_TRUSTLESS_LAYER_1 } from '../useGasInfor';
// import BigNumber from 'bignumber.js';

export interface IBurnTokenParams {
  tokenAddress: string;
  bridgeAddress: string;
  burnAmount: string;
  receiver: string;
}

const useBurnNativeToken: ContractOperationHook<
  IBurnTokenParams,
  any | undefined
> = () => {
  const { account, provider } = useWeb3React();
  const call = useCallback(
    async (params: IBurnTokenParams): Promise<any | undefined> => {
      if (account && provider) {
        const { burnAmount, receiver, bridgeAddress } = params;
        const contract = getContract(
          bridgeAddress,
          WBridgeBVMJson,
          provider,
          account,
        ).connect(provider.getSigner());

        // console.log('useBurnNativeToken ---- ', {
        //   bridgeAddress,
        //   WBridgeBVMJson,
        //   provider,
        //   account,
        //   receiver,
        // });

        // const burnAmountBN = new BigNumber(burnAmount).multipliedBy(1e18).toFixed();

        const tx = await contract['bridgeToken(string,uint256)'](receiver, 9999, {
          value: ethers.BigNumber.from(burnAmount),
          gasLimit: GAS_LITMIT_TRUSTLESS_LAYER_1,
        });
        if (tx) {
          return {
            hash: Object(tx).hash,
          };
        }
      }
      return undefined;
    },
    [account, provider],
  );

  return {
    call: call,
    dAppType: DAppType.Contract,
    transactionType: TransactionEventType.DEPLOY,
  };
};

export default useBurnNativeToken;
