import { SupportedChainId } from '@/constants/chains';
import { AssetsContext } from '@/contexts/assets-context';
import { ContractOperationHook } from '@/interfaces/contract-operation';
import { getIsAuthenticatedSelector, getUserSelector } from '@/state/user/selector';
import { capitalizeFirstLetter } from '@trustless-computer/dapp-core';
import { useContext } from 'react';
import { useSelector } from 'react-redux';
import useBitcoin from '../useBitcoin';

interface IParams<P, R> {
  operation: ContractOperationHook<P, R>;
  inscribeable?: boolean;
  chainId?: SupportedChainId;
  isCheckChainID?: boolean;
}

interface IContractOperationReturn<P, R> {
  run: (p: P) => Promise<R>;
}

const useContractOperation = <P, R>(
  args: IParams<P, R>,
): IContractOperationReturn<P, R> => {
  const { operation, inscribeable = true, isCheckChainID = false } = args;
  const { call } = operation();
  const { feeRate } = useContext(AssetsContext);
  const isAuthenticated = useSelector(getIsAuthenticatedSelector);
  const user = useSelector(getUserSelector);
  const { getUnInscribedTransactionByAddress } = useBitcoin();
  const run = async (params: P): Promise<R> => {
    try {
      // This function does not handle error
      // It delegates error to caller

      if (!isAuthenticated || !user?.walletAddress) {
        // router.push(`${ROUTE_PATH.CONNECT_WALLET}?next=${window.location.href}`);
        // throw Error('Please connect wallet to continue.');
      }

      if (isCheckChainID) {
        // Check & switch network if necessary
        // await checkAndSwitchChainIfNecessary();
      }

      if (!inscribeable) {
        // Make TC transaction
        console.time('____metamaskCreateTxTime');
        const tx: R = await call({
          ...params,
        });
        console.timeEnd('____metamaskCreateTxTime');

        console.log('tcTX', tx);
        return tx;
      }

      // Check unInscribed transactions
      console.time('____unInscribedTxIDsLoadTime');
      const unInscribedTxIDs = await getUnInscribedTransactionByAddress(
        user.walletAddress || '',
      );
      console.timeEnd('____unInscribedTxIDsLoadTime');

      if (unInscribedTxIDs.length >= 2) {
        throw Error(
          'You have some pending transactions. Please complete all of them before moving on.',
        );
      }

      console.log('unInscribedTxIDs', unInscribedTxIDs);

      console.time('____metamaskCreateTxTime');
      const tx: R = await call({
        ...params,
      });
      console.timeEnd('____metamaskCreateTxTime');

      console.log('tcTX', tx);

      console.log('feeRatePerByte', feeRate.fastestFee);

      // Make inscribe transaction
      // const { commitTxID, revealTxID } = await createInscribeTx({
      //   tcTxIDs: [...unInscribedTxIDs, Object(tx).hash],
      //   feeRatePerByte: feeRate.fastestFee,
      // });

      // const currentTimeString = moment().format('YYYY-MM-DDTHH:mm:ssZ');
      // const transactionHistory: ICreateTransactionPayload = {
      //   dapp_type: `${transactionType} ${dAppType}`,
      //   tx_hash: Object(tx).hash,
      //   from_address: Object(tx).from,
      //   to_address: Object(tx).to,
      //   time: currentTimeString,
      // };
      // if (commitTxID && revealTxID) {
      //   transactionHistory.btc_tx_hash = revealTxID;
      // }
      // await createTransactionHistory(transactionHistory);

      // await TC_SDK.signTransaction({
      //   method: `${transactionType} ${dAppType}`,
      //   hash: Object(tx).hash,
      //   dappURL: window.location.origin,
      //   isRedirect: true,
      //   target: '_blank',
      // });

      return tx;
    } catch (err) {
      console.log('====================================');
      console.log(err);
      console.log('====================================');
      const reason = Object(err).reason;
      if (reason) {
        console.log('====================================');
        console.log('reason ', reason);
        console.log('====================================');
        if (typeof reason === 'string') {
          // throw new Error(reason);
          // return Promise.reject(reason);
          throw new Error(reason);
        } else {
          throw new Error(capitalizeFirstLetter(Object(err).reason));
        }
      }
      throw err;
    }
  };

  return {
    run,
  };
};

export default useContractOperation;
