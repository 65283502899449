import { ContractOperationHook, DAppType } from '@/interfaces/contract-operation';
import { useWeb3React } from '@web3-react/core';
import { useCallback } from 'react';
import { TransactionEventType } from '@/enums/transaction';
import { getContract } from '@/utils';
import ConvertERC20L1ToL2Json from '@/abis/tcBridgeFromL1.json';

export interface IConvertERC20L1ToL2Params {
  bridgeAddress: string;
  tokenAddress: string;
  remoteTokenAddress: string;
  receiver: string;
  amount: string;
}

const useConvertERC20L1ToL2: ContractOperationHook<
  IConvertERC20L1ToL2Params,
  any | undefined
> = () => {
  const { account, provider } = useWeb3React();
  const call = useCallback(
    async (params: IConvertERC20L1ToL2Params): Promise<any | undefined> => {
      if (account && provider) {
        const { amount, receiver, bridgeAddress, tokenAddress, remoteTokenAddress } =
          params;
        if (receiver.toLowerCase() !== account.toLowerCase()) {
          throw new Error(`please connect to ${receiver}.`);
        }

        const contract = getContract(
          bridgeAddress,
          ConvertERC20L1ToL2Json,
          provider,
          account,
        ).connect(provider.getSigner());
        const tx = await contract.bridgeERC20(
          tokenAddress,
          remoteTokenAddress,
          amount,
          '200000',
          [],
          {
            gasLimit: '1500000',
          },
        );
        if (tx) {
          return {
            hash: Object(tx).hash,
          };
        }
      }
      return undefined;
    },
    [account, provider],
  );

  return {
    call: call,
    dAppType: DAppType.Contract,
    transactionType: TransactionEventType.DEPLOY,
  };
};

export default useConvertERC20L1ToL2;
