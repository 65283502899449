import styled from 'styled-components';
import React from 'react';

const ElementHTMLDiv = styled.div`
  display: flex;
  background-color: transparent;
`;
interface SizeProps {
  size?: number;
}

type Props = SizeProps;

interface SpaceProps extends Props, React.ButtonHTMLAttributes<HTMLDivElement> {}

const StyledSpace = styled(ElementHTMLDiv)<SpaceProps>`
  height: ${({ size }) => `${size || 0}px`};
  width: ${({ size }) => `${size || 0}px`};
`;

const Vertical = styled(ElementHTMLDiv)<SpaceProps>`
  height: ${({ size }) => `${size || 0}px`};
`;

const Horizontal = styled(ElementHTMLDiv)<SpaceProps>`
  width: ${({ size }) => `${size || 0}px`};
`;

const Space = (props: SizeProps) => <StyledSpace {...props} />;

Space.Vertical = Vertical;
Space.Horizontal = Horizontal;

export default Space;
