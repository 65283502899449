import Text from '@/components/Text';
import { memo } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import {
  getFormBridgeInfo,
  getGenerateDepositSelector,
} from '../FormBridge/state/selector';
import Skeleton from 'react-loading-skeleton';

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const MinimumDepositAmount = () => {
  const { isLoading, data } = useSelector(getGenerateDepositSelector);
  const { fromTokenSelected } = useSelector(getFormBridgeInfo);

  if (isLoading) return <Skeleton count={1} />;
  if (!data) return null;

  const { minDepositAmountFormatedStr, minDepositShouldBeHide } = data;

  if (!minDepositAmountFormatedStr || minDepositShouldBeHide) return null;

  return (
    <Container>
      <Text>{'Minimum deposit amount'}</Text>
      <Text>{`${minDepositAmountFormatedStr} ${fromTokenSelected?.symbol?.toUpperCase()}`}</Text>
    </Container>
  );
};

export default memo(MinimumDepositAmount);
