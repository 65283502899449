import styled from 'styled-components';

export const TabContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  .nav-tabs {
    padding: 8px;
    border: none;
    display: flex;
    align-self: center;
    /* width: 260px; */
    background: #131419;
    border-radius: 40px;

    .nav-link {
      opacity: 0.5;
      border: 40px;
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 22px;

      padding: 12px 24px;
      color: #ffffff;

      transition: none;
      &:hover {
        opacity: 0.75;
      }
    }

    .nav-link.active {
      background-color: transparent;
      opacity: 1;

      border-radius: 8px;
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 22px;

      border-radius: 40px;
      color: #ffffff;

      padding: 12px 24px;

      background-color: #3772ff;
      transition: none;
    }

    .nav-title {
      text-transform: capitalize;
      font-size: 18px;
    }
  }
`;

export const Container = styled.div`
  padding: 0 5%;
  margin-top: 30px;
  display: flex;
  flex: 1;
  flex-direction: column;

  @media screen and (max-width: 1024px) {
    padding: 0 2.5%;
  }
  @media screen and (max-width: 768px) {
    padding: 0 1.5%;
  }
`;

export const BodyContainer = styled.div``;
