import { ContractOperationHook, DAppType } from '@/interfaces/contract-operation';
import { useWeb3React } from '@web3-react/core';
import { useCallback } from 'react';
import { TransactionEventType } from '@/enums/transaction';
import { getContract } from '@/utils';
import DepositJson from '@/abis/deposit.json';
import { ethers } from 'ethers';

export interface IDepositETHParams {
  bridgeAddress: string;
  amount: string; // original amount // 1000000000000000
  receiver: string;
}

const useDepositETH: ContractOperationHook<
  IDepositETHParams,
  any | undefined
> = () => {
  const { account, provider } = useWeb3React();
  const call = useCallback(
    async (params: IDepositETHParams): Promise<any | undefined> => {
      if (account && provider) {
        const { bridgeAddress, receiver, amount } = params;
        const contract = getContract(
          bridgeAddress,
          DepositJson,
          provider,
          account,
        ).connect(provider.getSigner());
        const tx = await contract['deposit(address)'](receiver, {
          value: ethers.BigNumber.from(amount),
        });
        if (tx) {
          return {
            hash: Object(tx).hash,
          };
        }
      }
      return undefined;
    },
    [account, provider],
  );

  return {
    call: call,
    dAppType: DAppType.ETH,
    transactionType: TransactionEventType.DEPOSIT,
  };
};

export default useDepositETH;
