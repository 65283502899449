import { RootState } from '@/state';
import { createSelector } from 'reselect';

export const web3ReactReducerSelector = (state: RootState) => state.web3ReactReducer;

export const getWeb3Data = createSelector(
  web3ReactReducerSelector,
  (web3ReactReducer) => web3ReactReducer.web3Data,
);

export const getWeb3InforSelector = createSelector(getWeb3Data, (web3Infor) => {
  let isNeededConnectWeb3 = false;

  if (!web3Infor) {
    isNeededConnectWeb3 = true;
  } else {
    const { account, provider } = web3Infor;
    if (!account || !provider) {
      isNeededConnectWeb3 = true;
    }
  }
  return {
    ...web3Infor,
    isNeededConnectWeb3,
  };
});

export const getWeb3ChainID = createSelector(getWeb3Data, (data) => data?.chainId);
