import QRCodeReact from 'react-qr-code';
import styled from 'styled-components';

// import Loader from '../Loader';
import { Spinner } from 'react-bootstrap';

interface IProps {
  hook?: any;
  qrCodeProps: any;
  label?: string;
  copyProps?: any;
  isBlur?: boolean;
  isLoading?: boolean;
}

const Styled = styled.div`
  margin-top: 5px;
  position: relative;
  .label {
    text-align: center;
    /* margin-left: 54px;
    margin-right: 54px; */
  }
  .qrcode-react {
    justify-content: center;
    display: flex;
    width: fit-content;
    padding: 8px;
    margin: auto;
    border-radius: 8px;
    color: white;
    background-color: white;
  }
  .blur {
    -webkit-filter: blur(8px);
    filter: blur(8px);
    color: white;
    background-color: white;
  }
  .loader {
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
`;

const QrCode = (props: IProps) => {
  const { qrCodeProps, label, isBlur, isLoading } = props;
  return (
    <Styled>
      <div className="label">{label}</div>
      <div className={`qrcode-react ${isBlur ? 'blur' : ''}`}>
        <QRCodeReact
          {...{ ...qrCodeProps, size: qrCodeProps?.size || 184 }}
          bgColor="transparent"
        />
      </div>
      {!!isLoading && (
        <Spinner
          animation="border"
          role="status"
          style={{ color: '#000000' }}
          className="loader"
        ></Spinner>
      )}
    </Styled>
  );
};

export default QrCode;
