import styled from 'styled-components';
import { Modal } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Text from '@components/Text';
import { ExternalLink } from 'react-feather';

const ModalStyled = styled(Modal)`
  &.modal {
    --bs-modal-color: rgb(00, 00, 00);
  }

  .modal-content {
    padding: 15px;
    background: #1b1e26;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 16px;
  }

  .modal-header {
    border-bottom: 0.5px solid #353945;
  }
  .modal-title {
    color: white;
  }
`;

const Container = styled.div``;

const FormStyled = styled(Form)`
  margin-top: 16px;

  .bottom-check {
    margin-top: 16px;
  }
`;

const Row = styled.div<{ hasLink: string }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  padding: 8px 12px;
  cursor: ${({ hasLink }) => (hasLink ? 'pointer' : 'unset')};
  :hover {
    p {
      opacity: ${({ hasLink }) => (hasLink ? 0.8 : 1)};
    }
  }
`;

const HashText = styled(Text)`
  padding-left: 44px;
  padding-bottom: 6px;
`;

const Image = styled.div<{ color: string }>`
  svg {
    width: 16px;
    path {
      fill: ${({ color }) => color};
    }
  }
  margin-top: -2px;
`;

const VerticalLine = styled.div<{ dashed: boolean }>`
  width: 1px;
  height: 18px;
  border: 1px ${({ dashed }) => (dashed ? 'dashed' : 'solid')}
    ${({ dashed }) => (dashed ? 'white' : 'gray')};
  margin-left: 20px;
  margin-top: 8px;
`;

const StepperContainer = styled.div`
  margin-top: 24px;
`;

const RowContainer = styled.div<{ processing: boolean }>`
  background-color: ${({ processing }) => (processing ? '#21262C' : 'transparent')};
  border-radius: 8px;
`;

const LinkIcon = styled(ExternalLink)`
  :hover {
    opacity: 0.8;
  }
`;

export {
  ModalStyled,
  Container,
  Row,
  Image,
  VerticalLine,
  StepperContainer,
  FormStyled,
  HashText,
  LinkIcon,
  RowContainer,
};
