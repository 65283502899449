import * as React from 'react';
import { SVGProps, memo } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    fill="none"
    {...props}
  >
    <rect width={48} height={48} fill="#fff" rx={24} />
    <path
      stroke="#1C1C1C"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m25.8 19.5 2.7-2.7m0 0 2.7 2.7m-2.7-2.7v14.4m-6.3-2.7-2.7 2.7m0 0-2.7-2.7m2.7 2.7V16.8"
    />
  </svg>
);
const Memo = memo(SvgComponent);
export default Memo;
