import { useState } from 'react';
import WithdrawProcessingModal from './components/WithdrawProcessingModal';

const enhanceWithdrawProcessing = (WrappedComponent: any) => (props: any) => {
  const [data, setWithdrawProcessingModalData] = useState({
    isShow: false,
    amount: '',
    receiverAddress: '',
    txHash: '',
  });
  return (
    <>
      <WrappedComponent {...{ ...props, setWithdrawProcessingModalData }} />

      <WithdrawProcessingModal
        isShow={data.isShow}
        amount={data.amount}
        txHash={data.txHash}
        receiverAddress={data.receiverAddress}
        onHide={() => {
          setWithdrawProcessingModalData({
            isShow: false,
            amount: '',
            receiverAddress: '',
            txHash: '',
          });
        }}
      />
    </>
  );
};
export default enhanceWithdrawProcessing;
