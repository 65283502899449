import { useWeb3React } from '@web3-react/core';
import React from 'react';

const useGetNativeBalance = () => {
  const { account, provider } = useWeb3React();
  const [isLoading, setLoading] = React.useState(false);
  const getBalance = async () => {
    let _balance = '0';
    setLoading(true);
    try {
      console.log('[useGetNativeBalance] .... ', {
        account,
        provider,
      });
      if (!!account && provider) {
        const balance = await provider.getBalance(account);
        _balance = balance.toString();

        console.log('[useGetNativeBalance] .... ', {
          _balance,
        });
      }
    } catch (e) {
      console.log(e);
      // toast.error('Load balance error.');
      throw e;
    } finally {
      setLoading(false);
    }
    return _balance;
  };

  return {
    isLoading,
    getBalance,
  };
};

export default useGetNativeBalance;
