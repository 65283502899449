import { FC } from 'react';
import { FormBridgeType } from './FormBridge.types';

import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components';
import React from 'react';

export const Container = styled.div`
  margin-top: 28px;
  display: flex;
  flex-direction: column;
`;

export const WrapperContent = styled.div`
  //width: 50%;
  height: 500px;
  min-width: 700px;
  display: flex;
  flex-direction: column;
  padding: 20px 40px 40px 40px;

  background: ${({ theme }) => theme.colors.bg1};
  border: 1px solid ${({ theme }) => theme.colors.border4};
  border-radius: 16px;
  justify-content: space-around;
  align-self: center;

  @media screen and (max-width: 1920px) {
    width: 50%;
  }
  @media screen and (max-width: 1024px) {
    width: 80%;
    padding: 14px 24px 24px 24px;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
    min-width: 90%;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  gap: 3rem;
  justify-content: space-between;
`;

export const WrapperSwap = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;

  .swapSkeleton {
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: center;

    .skeleton {
      width: 50px;
      height: 50px;
      border-radius: 100%;
    }
  }
`;

const withSekeleton =
  (WrappedComponent: FC<FormBridgeType>) => (props: FormBridgeType) => {
    const { routeParamReady } = props;
    if (routeParamReady) {
      return (
        <Container>
          <WrapperContent>
            <Skeleton count={1} width={'20%'} />
            <Row>
              <div style={{ flex: 1 }}>
                <Skeleton count={1} />
              </div>
              <div style={{ flex: 1 }}>
                <Skeleton count={1} />
              </div>
            </Row>
            <WrapperSwap>
              <div className="swapSkeleton">
                <Skeleton count={1} className="skeleton" />
              </div>
            </WrapperSwap>
            <Skeleton count={1} width={'20%'} />
            <Row>
              <div style={{ flex: 1 }}>
                <Skeleton count={1} />
              </div>
              <div style={{ flex: 1 }}>
                <Skeleton count={1} />
              </div>
            </Row>
            <Skeleton count={1} />
            <Skeleton count={1} />
          </WrapperContent>
        </Container>
      );
    }

    return <WrappedComponent {...{ ...props }} />;
  };
export default withSekeleton;
