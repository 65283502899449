import BigNumber from 'bignumber.js';
import { isAddress as isEtherAddress } from 'ethers/lib/utils';
import isEmpty from 'lodash/isEmpty';
import convertUtils from './convert';
import formatUtils from './format';
import {
  validateBTCAddress as isValidateBTCAddress,
  validateEVMAddress as isValidateEVMAddress,
} from '@/utils';

const isSafeInteger = (number: number) =>
  Math.abs(number) <= Number.MAX_SAFE_INTEGER;

const required1 = (value: any) => (isEmpty(value) ? 'Required' : undefined);

const required = (message?: string) => (value: any) =>
  isEmpty(value) ? `${message || 'Required'}` : undefined;

const addressRequired = (message?: string) => (value: any) =>
  isEmpty(value) ? `${message || 'Address is required'}` : undefined;

const amountRequired = (message?: string) => (value: any) =>
  isEmpty(value) ? `${message || 'Amount is required'}` : undefined;

const maxLength = (max: number, message?: string) => (value: string) =>
  value && value.length > max
    ? message || `Must be ${max} characters or less`
    : undefined;

const minLength = (min: number, message?: string) => (value: string) =>
  value && value.length < min
    ? message || `Must be ${min} characters or more`
    : undefined;

const isInteger = (value: string) =>
  value && !new BigNumber(value).isInteger()
    ? 'Must be a integer number'
    : undefined;

const number = (value: string) => {
  const bn = new BigNumber(value);
  if (bn.isNaN()) {
    return 'Must be a number';
  }
  if (value && !isSafeInteger(bn.toNumber())) {
    return 'This number is too large!';
  }
  return undefined;
};

const minValue = (min: number | string, message?: string) => (value: string) =>
  value && convertUtils.toNumber(value) < convertUtils.toNumber(min)
    ? message || `Must be at least ${formatUtils.humanReadable(min)}`
    : undefined;

const maxValue = (max: number | string, message?: string) => (value: string) =>
  value && convertUtils.toNumber(value) > convertUtils.toNumber(max)
    ? message || `Must be less than or equal ${formatUtils.humanReadable(max)}`
    : undefined;

const largerThan = (min: number | string, message?: string) => (value: string) =>
  value && convertUtils.toNumber(value) <= convertUtils.toNumber(min)
    ? message || `Must be larger than ${formatUtils.humanReadable(min)}`
    : undefined;

const email = (message?: string) => (value: string) =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? message || 'Invalid email address'
    : undefined;

const regexp =
  (pattern: RegExp, message = 'Invalid data') =>
  (value: string) =>
    pattern && !pattern.test(value) ? message : undefined;

const etherAddress = (message?: string) => (value: string) => {
  value = value?.trim();
  if (value && value?.length < 15) {
    return 'Invalid address';
  }
  if (!isEtherAddress(value)) {
    return message || 'Please enter External address';
  }

  return undefined;
};

const nearAddress = () => (value: string) => {
  value = value?.trim();
  const ACCOUNT_ID_REGEX = /^(([a-z\d]+[-_])*[a-z\d]+\.)*([a-z\d]+[-_])*[a-z\d]+$/;

  if (value.length >= 2 && value.length <= 64 && ACCOUNT_ID_REGEX.test(value)) {
    return undefined;
  }

  return 'Invalid address';
};

const combinedAmount = [
  required,
  number,
  largerThan(0, 'Please enter an amount greater than 0'),
];

const combinedNanoAmount = [
  required,
  isInteger,
  number,
  minValue(1, 'Please enter an amount greater than 1.'),
];

const combinedEtherAddress = [required, etherAddress()];
const combinedNearAddress = [required, nearAddress()];
const combinedUnknownAddress = [required, minLength(15)];

const combinedAccountName = [
  required,
  minLength(1),
  maxLength(50),
  regexp(/\w+$/i, 'Please use a valid account name (Ex: "Cat, Account-1,..").'),
];

const etherHash = (message?: string) => (value: string) => {
  if (value && value?.length < 15) {
    return 'Invalid Hash';
  }
  if (!/^0x([A-Fa-f0-9]{64})$/.test(value)) {
    return message || 'Please enter valid transaction hash';
  }
  return undefined;
};

const combinedOutchainHash = [required, etherHash()];

const address = () => {
  return 'Invalid address';
};

const notEnoughPRVFee = () => {
  return 'Please top up PRV to cover the transaction fee.';
};

const combineInvalidAddress = [required, address];

const NAME_PATTERN = /^[A-Za-z0-9]*$/;

const validateAlphaNumericText = (message?: string) => (value: any) => {
  return !NAME_PATTERN.test(value) ? message : undefined;
};

const validateEVMAddress = (message?: string) => (value: any) => {
  return !isValidateEVMAddress(value) ? message || 'Address is invalid.' : undefined;
};

const validateBTCAddress = (message?: string) => (value: any) => {
  return !isValidateBTCAddress(value) ? message || 'Address is invalid.' : undefined;
};

export const composeValidators =
  (...validators: any[]) =>
  (value: any) =>
    validators.reduce((error, validator) => error || validator(value), undefined);

const validator = {
  validateAlphaNumericText,
  minLength,
  maxLength,
  required,
  required1,
  maxValue,
  minValue,
  address,
  notEnoughPRVFee,
  largerThan,
  combinedAmount,
  combinedAccountName,
  combinedNanoAmount,
  combineInvalidAddress,
  combinedUnknownAddress,
  combinedEtherAddress,
  combinedNearAddress,
  combinedOutchainHash,
  email,
  validateEVMAddress,
  validateBTCAddress,
  addressRequired,
  amountRequired,
};

export default validator;
