import { TransactionResponse } from '@ethersproject/abstract-provider';
import { ErrorCode } from '@ethersproject/logger';
import { sleep } from '@eth-optimism/core-utils';

interface IRetryCall {
  func: () => Promise<TransactionResponse>;
}

const onRetryFunction = async ({ func }: IRetryCall) => {
  let tx: TransactionResponse | undefined;
  let counter = 0;
  const expiredCounter = 3;
  while (counter <= expiredCounter) {
    try {
      tx = await func();
      counter = expiredCounter + 1;
      // code block to be executed
    } catch (error) {
      const _error = error as any;
      const isRejected = _error.code === ErrorCode.ACTION_REJECTED;
      counter = counter + 1;
      if (counter == expiredCounter || isRejected) {
        throw error;
      }
      await sleep(1000);
    }
  }
  return tx;
};

export { onRetryFunction };
