import { useState } from 'react';
import DepositProcessingModal from './components/DepositProcessingModal';

const enhanceDepositSuccess = (WrappedComponent: any) => (props: any) => {
  const [data, setDepositProcessingModalData] = useState({
    isShow: false,
    amount: '',
    receiverAddress: '',
    txHash: '',
  });
  return (
    <>
      <WrappedComponent {...{ ...props, setDepositProcessingModalData }} />
      <DepositProcessingModal
        isShow={data.isShow}
        amount={data.amount}
        txHash={data.txHash}
        receiverAddress={data.receiverAddress}
        onHide={() => {
          setDepositProcessingModalData({
            isShow: false,
            amount: '',
            receiverAddress: '',
            txHash: '',
          });
        }}
      />
    </>
  );
};
export default enhanceDepositSuccess;
