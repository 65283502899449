import { NETWORK_SUPPORTING } from '@/constants/network';
import { formBridgeActions } from '@/containers/FormBridge/state/reducer';
import { useAppDispatch } from '@/state/hooks';
import { getNetworkNameListSelector } from '@/state/network/selector';
import {
  getTokenBySymbolSelector,
  getTokenByToNetworkSelector,
  getTokenNativeCurrentNetworkSelector,
} from '@/state/tokens/selector';
import { delay } from '@/utils/time';
import { useRouter } from 'next/router';
import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
// import BasicSpinner from '@/components/AppSpinner/BacsicSpinner';

// Pathname shorten
const BITCOIN_PATHNAME_LIST = ['/nakamoto', '/bitcoin', '/btc'];
const ETH_PATHNAME_LIST = ['/eth', '/ethereum', '/vitaliz'];
const ORIDINALS_PATHNAME_LIST = ['/ordinals', '/ordinal', '/ord'];
const TC_PATHNAME_LIST = ['/trustlessLayer1', '/tcLayer1', '/tc'];
const NOS_PATHNAME_LIST = ['/trustlessLayer2', '/tcLayer2', '/nos', '/alpha'];

// Params with alias name
const BTC_NETWORK_ALIAS = ['bitcoin', 'btc', 'nakamoto'];
const ETH_NETWORK_ALIAS = ['ethereum', 'eth', 'vitaliz'];
const ORDINALS_NETWORK_ALIAS = ['ordinals', 'ord'];
const TC_LAYER_1_ALIAS = ['trustlessLayer1', 'tcLayer1', 'tc', 'trustless-computer'];
const TC_LAYER_2_ALIAS = ['trustlessLayer2', 'tcLayer2', 'nos', 'alpha'];
const NAKA_NETWORK_ALIAS = ['NAKA', 'naka'];

const withRouteParams = (WrappedComponent: FC<any>) => (props: any) => {
  const router = useRouter();

  const [routeParamReady, setRouteParamReady] = useState(true);

  const { pathname, query } = router;

  const { from, to, token } = query;

  const dispatch = useAppDispatch();
  const getTokenBySymbolFunc = useSelector(getTokenBySymbolSelector);
  const getTokenNativeCurrentNetworkFunc = useSelector(
    getTokenNativeCurrentNetworkSelector,
  );
  const networkNameList = useSelector(getNetworkNameListSelector) || []; //Network name list from API

  const getTokenByToNetworkFunc = useSelector(getTokenByToNetworkSelector);

  const mapNetworkAliasToNetworkStr = (network: string) => {
    if (BTC_NETWORK_ALIAS.includes(network)) {
      return NETWORK_SUPPORTING.BITCOIN;
    }
    if (ETH_NETWORK_ALIAS.includes(network)) {
      return NETWORK_SUPPORTING.ETHEREUM;
    }
    if (ORDINALS_NETWORK_ALIAS.includes(network)) {
      return NETWORK_SUPPORTING.ORDINALS;
    }
    if (TC_LAYER_1_ALIAS.includes(network)) {
      return NETWORK_SUPPORTING.TRUSTLESS_LAYER1;
    }
    if (TC_LAYER_2_ALIAS.includes(network)) {
      return NETWORK_SUPPORTING.TRUSTLESS_LAYER2;
    }
    if (NAKA_NETWORK_ALIAS.includes(network)) {
      return NETWORK_SUPPORTING.NAKA;
    }
    if (!networkNameList.includes(network)) {
      return NETWORK_SUPPORTING.BITCOIN;
    }

    return network;
  };

  const getTokenSymbol = (fromNetwork: string, toNetwork: string) => {
    if (
      fromNetwork === NETWORK_SUPPORTING.BITCOIN ||
      toNetwork === NETWORK_SUPPORTING.BITCOIN
    ) {
      return 'BTC';
    }
    if (
      fromNetwork === NETWORK_SUPPORTING.ETHEREUM ||
      toNetwork === NETWORK_SUPPORTING.ETHEREUM
    ) {
      return 'ETH';
    }
    if (
      fromNetwork === NETWORK_SUPPORTING.ORDINALS ||
      toNetwork === NETWORK_SUPPORTING.ORDINALS
    ) {
      return 'ORDI';
    }

    return 'BVM';
  };

  const getFromAndByNetwork = () => {
    let fromNetwork;
    let toNetwork;

    //Priority 1
    //[pathname]

    if (pathname && pathname !== '/') {
      if (BITCOIN_PATHNAME_LIST.includes(pathname)) {
        fromNetwork = NETWORK_SUPPORTING.BITCOIN;
        toNetwork = NETWORK_SUPPORTING.TRUSTLESS_LAYER1;
      } else if (ETH_PATHNAME_LIST.includes(pathname)) {
        fromNetwork = NETWORK_SUPPORTING.ETHEREUM;
        toNetwork = NETWORK_SUPPORTING.TRUSTLESS_LAYER1;
      } else if (ORIDINALS_PATHNAME_LIST.includes(pathname)) {
        fromNetwork = NETWORK_SUPPORTING.ORDINALS;
        toNetwork = NETWORK_SUPPORTING.TRUSTLESS_LAYER1;
      } else if (TC_PATHNAME_LIST.includes(pathname)) {
        fromNetwork = NETWORK_SUPPORTING.TRUSTLESS_LAYER1;
        toNetwork = NETWORK_SUPPORTING.TRUSTLESS_LAYER2;
      } else if (NOS_PATHNAME_LIST.includes(pathname)) {
        fromNetwork = NETWORK_SUPPORTING.TRUSTLESS_LAYER2;
        toNetwork = NETWORK_SUPPORTING.TRUSTLESS_LAYER1;
      } else if (NAKA_NETWORK_ALIAS.includes(pathname)) {
        fromNetwork = NETWORK_SUPPORTING.NAKA;
        toNetwork = NETWORK_SUPPORTING.TRUSTLESS_LAYER1;
      } else {
        fromNetwork = NETWORK_SUPPORTING.BITCOIN;
        toNetwork = NETWORK_SUPPORTING.TRUSTLESS_LAYER1;
      }
    }

    //Priority 2
    //[params query]
    else {
      if (!from || !to || typeof from !== 'string' || typeof to !== 'string') {
        fromNetwork = NETWORK_SUPPORTING.BITCOIN;
        toNetwork = NETWORK_SUPPORTING.TRUSTLESS_LAYER1;
      } else {
        fromNetwork = mapNetworkAliasToNetworkStr(from);
        toNetwork = mapNetworkAliasToNetworkStr(to);
      }
    }

    return {
      fromNetwork,
      toNetwork,
    };
  };

  useEffect(() => {
    const detechtFn = async () => {
      let { fromNetwork, toNetwork } = getFromAndByNetwork();

      const tokenSymbol = getTokenSymbol(fromNetwork, toNetwork);
      const tokenNative = getTokenNativeCurrentNetworkFunc(fromNetwork);
      const tokenActive = getTokenBySymbolFunc(tokenSymbol);
      const fromTokenSelect = tokenActive || tokenNative;

      // console.log('useEffect [detechtFn]  --- ', {
      //   fromNetwork,
      //   toNetwork,
      //   tokenSymbol,
      //   tokenNative,
      //   tokenActive,
      //   fromTokenSelect,
      // });

      dispatch(formBridgeActions.setFromNetwork(fromNetwork));
      dispatch(formBridgeActions.setFromTokenSelected(fromTokenSelect));

      await delay(1000).then(() => {
        dispatch(formBridgeActions.setToNetwork(toNetwork));
      });
      setRouteParamReady(false);
    };

    detechtFn();
  }, [
    getTokenBySymbolFunc,
    getTokenNativeCurrentNetworkFunc,
    getTokenByToNetworkFunc,
    from,
    to,
    pathname,
  ]);

  // if (routeParamReady) return <BasicSpinner />;
  return <WrappedComponent {...{ ...props, routeParamReady }} />;
};

export default withRouteParams;
