const isFailedTCL1ByHash = async (txHash: string) => {
  let isFailed = false;
  const tcClient = window.tcClient;
  try {
    const res: any = await tcClient.getTCTxByHash(txHash);
    if (res && res.blockHash) {
      if (res.status === '0x0') {
        isFailed = true;
      }
    }
  } catch (e) {
    isFailed = false;
  }
  return isFailed;
};

export { isFailedTCL1ByHash };
