import {
  BTCSubmitBtnStyled,
  Container,
  SubmitButton,
  WrapperContent,
} from './FormBridge.styles';

import Space from '@components/Space';
import { Form } from 'react-final-form';
import { useSelector } from 'react-redux';
import { FormInitialValues } from './FormBridge.constants';
import enhance from './FormBridge.enhance';
import { FormBridgeType } from './FormBridge.types';
import { getFormBridgeInfo, getGenerateDepositSelector } from './state/selector';

import { ROUTE_PATH } from '@/constants/route-path';
import { isLayer1Network } from '@/containers/FormBridge/FormBridge.utils';
import { getIsAuthenticatedSelector } from '@/state/user/selector';
import router from 'next/router';
// import WarningBridgeBRC20 from './components/WarningBridgeBRC20';

import CentralizeInput from './FormBridge.centralizeInput';
import EVMInput from './FormBridge.evmInput';

import GroupSelect from './FormBridge.groupSelect';

import withSekeleton from './FormBridge.withSekeleton';

const FormBridge = (props: FormBridgeType) => {
  const {
    submitHandler,
    switchChainHandler,
    swapHandler,
    generateDepositDataHandler,
    maxHandler,
  } = props;

  const { isLoading } = useSelector(getGenerateDepositSelector);
  const isAuthen = useSelector(getIsAuthenticatedSelector);

  const {
    submitBtnTitle,
    disableSubmitBtn,
    isSwitchNetwork,
    fromNetworkSelected,
    isDepositForm,
  } = useSelector(getFormBridgeInfo);

  return (
    <Form
      onSubmit={(data) => {
        if (isDepositForm) {
          generateDepositDataHandler(data);
        } else {
          submitHandler(data);
        }
      }}
      initialValues={FormInitialValues}
      render={({ handleSubmit }) => (
        <Container>
          <WrapperContent>
            {/* Render Warning BRC20 when Deposit */}
            {/* <WarningBridgeBRC20 /> */}
            <GroupSelect swapHandler={swapHandler} />
            <Space.Vertical size={20} />
            {isDepositForm ? (
              <>
                <CentralizeInput />
                <BTCSubmitBtnStyled type="submit" onClick={handleSubmit}>
                  {isLoading ? 'Generating...' : 'Transfer'}
                </BTCSubmitBtnStyled>
              </>
            ) : (
              <>
                <EVMInput
                  switchChainHandler={switchChainHandler}
                  maxHandler={maxHandler}
                />
                <SubmitButton
                  type="button"
                  disabled={disableSubmitBtn}
                  onClick={() => {
                    if (isSwitchNetwork) {
                      switchChainHandler();
                    } else {
                      if (isLayer1Network(fromNetworkSelected)) {
                        if (!isAuthen) {
                          router.push(
                            `${ROUTE_PATH.CONNECT_WALLET}?next=${window.location.href}`,
                          );
                        } else {
                          handleSubmit();
                        }
                      } else {
                        handleSubmit();
                      }
                    }
                  }}
                >
                  {submitBtnTitle}
                </SubmitButton>
              </>
            )}
          </WrapperContent>
        </Container>
      )}
    />
  );
};

export default enhance(withSekeleton(FormBridge));
