import {
  AddressType,
  getAddressInfo,
  Network,
  validate,
} from 'bitcoin-address-validation';
import { ethers } from 'ethers';
import { isProduction } from '@/utils/commons';

export const validateWalletAddress = (address: string): boolean => {
  return /^0x[a-fA-F0-9]{40}$/.test(address);
};

export const validateBTCAddressTaproot = (_address: string): boolean => {
  const isBTCAddress = validate(
    _address,
    isProduction() ? Network.mainnet : Network.regtest,
  );
  if (isBTCAddress) {
    const addressInfo = getAddressInfo(_address);
    return addressInfo.type === AddressType.p2tr;
  }
  return false;
};

export const validateBTCAddress = (_address: string): boolean => {
  return validate(_address, isProduction() ? Network.mainnet : Network.testnet);
};

export const validateEVMAddress = (_address: string): boolean => {
  return ethers.utils.isAddress(_address);
};

export const validateTwitterUrl = (url: string): boolean => {
  return url.startsWith('https://twitter.com/');
};
