import Text from '@/components/Text';
import { memo } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { getEstimateTimeProcess } from '../state/selector';

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const WithdrawFeeEstimate = () => {
  const data = useSelector(getEstimateTimeProcess);

  if (!data || !data.estTime) return null;

  return (
    <Container>
      <Text>{'Transfer time'}</Text>
      <Text>{`~ ${data.estTimeStr}`}</Text>
    </Container>
  );
};

export default memo(WithdrawFeeEstimate);
