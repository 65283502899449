import { Image } from '@/components/Image';
import Text from '@/components/Text';
import { getToTokenSelectedSelector } from '@/containers/FormBridge/state/selector';
import { addToken } from '@/utils';
import { getIconUrl } from '@/utils/token';
import { SupportedChainId } from '@constants/chains';
import { NETWORK_SUPPORTING, isLayer2, isOutChain } from '@constants/network';
import { useWeb3React } from '@web3-react/core';
import debounce from 'lodash/debounce';
import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { toast } from 'react-hot-toast';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { getFormBridgeInfo } from '../state/selector';

const DropdownStyled = styled(Dropdown)`
  &&& {
    display: flex;
    align-items: center;
    margin-top: 5px;
  }
`;

const DropdownToggleStyled = styled(Dropdown.Toggle)<{ isempty?: number }>`
  &&& {
    display: flex;
    align-items: center;
    flex-direction: row;

    margin: 0;
    padding: 0;
    flex: 1;
    min-height: 55px;

    background: ${({ theme, isempty }) =>
      isempty ? theme.colors.bg1 : theme.colors.black};
    border: 1px solid ${({ theme }) => theme.colors.tranpsarent};
    :hover {
      cursor: initial;
      background: ${({ theme, isempty }) =>
        isempty ? theme.colors.bg1 : theme.colors.tranpsarent};
    }

    ::after {
      color: ${({ theme }) => theme.colors.tranpsarent};
    }
  }

  .import-token {
    text-decoration: underline;
    font-size: 12px;
    cursor: pointer;
    color: #3772ff;

    :hover {
      opacity: 0.9;
    }
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
`;

const RowSpaceBetween = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const ToTokenSelected = () => {
  const { fromTokenSelected, toNetworkSelected, toNetworkObject } =
    useSelector(getFormBridgeInfo);
  const { connector, chainId } = useWeb3React();

  const requireChainID =
    toNetworkSelected === NETWORK_SUPPORTING.TRUSTLESS_LAYER1
      ? SupportedChainId.TRUSTLESS_COMPUTER
      : toNetworkObject?.chainId;

  const toTokenSelected = useSelector(getToTokenSelectedSelector);

  const isAddToken = React.useMemo(() => {
    if (isOutChain(toNetworkSelected)) {
      return false;
    } else {
      if (fromTokenSelected?.symbol?.toUpperCase() !== 'BVM') {
        return true;
      } else {
        return false;
      }
    }
  }, [toNetworkSelected, toTokenSelected, chainId, fromTokenSelected]);

  const onImportToken = debounce(async () => {
    if (!toTokenSelected) return;
    if (chainId === undefined) {
      toast.error(
        `Please connect to Metamask before importing the ${toTokenSelected?.symbol} token.`,
      );
      return;
    }

    if (!requireChainID || requireChainID < 0) {
      toast.error(`requireChainID invalid`);
      return;
    }

    try {
      await addToken({
        tokenAddress: toTokenSelected.tcTokenID,
        symbol: toTokenSelected.symbol,
        decimals: toTokenSelected.decimals,
        image: getIconUrl({
          token: toTokenSelected,
        }),
        connector,
        currentChainID: chainId,
        requireChainID: requireChainID,
      });
    } catch (error) {
      console.log('ADD TOKEN ERROR: ', error);
    }
  }, 300);

  const isEmptyToken = !fromTokenSelected;

  return (
    <DropdownStyled>
      <DropdownToggleStyled isempty={Number(isEmptyToken)}>
        <RowSpaceBetween>
          {!toTokenSelected ? (
            <Text>Select Token</Text>
          ) : (
            <RowSpaceBetween>
              <Row>
                <Image
                  iconUrl={getIconUrl({
                    token: toTokenSelected,
                  })}
                  size={32}
                  alt={`${toTokenSelected.symbol}`}
                />
                <Text fontSize={18} style={{ marginLeft: 14 }}>
                  {`${toTokenSelected.symbol?.toUpperCase()}`}
                </Text>
              </Row>
              {isAddToken && (
                <Text className="import-token" onClick={onImportToken}>
                  Import Token
                </Text>
              )}
            </RowSpaceBetween>
          )}
        </RowSpaceBetween>
      </DropdownToggleStyled>
    </DropdownStyled>
  );
};

export default ToTokenSelected;
