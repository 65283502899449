import localStorage from '@/utils/localstorage';
import { SetTxBurnMetaMaskPayload, TxMetaMaskType } from './Withdraw.types';
import { HistoryItemType, StatusType } from '../History/History.types';
import { WAITING_INSCRIBED_STATUS } from '../History/History.constants';
import { isFailedTCL1ByHash } from '@utils/sdk';
import { NETWORK_SUPPORTING, SYMBOL_BY_NETWORK_NAME } from '@constants/network';

const Keys = {
  TXS_BURN_METAMASK: 'TXS_BURN_METAMASK',
};

const TxBurnMetaMaskMap: any = localStorage.get(Keys.TXS_BURN_METAMASK) || {}; // Dict
//   let TxBurnMetaMaskList: TxMetaMaskType[] =
//   localStorage.get(Keys.TXS_BURN_METAMASK) || []; // Dict

const removeTxByHash = (txHash: string) => {
  delete TxBurnMetaMaskMap[txHash];
  localStorage.set(Keys.TXS_BURN_METAMASK, TxBurnMetaMaskMap);
};

const setTxBurnMetaMask = (payload: SetTxBurnMetaMaskPayload) => {
  const txHash = payload.txHash;
  const isL1ToL2 = payload.isL1ToL2;
  const txObject: TxMetaMaskType = {
    id: txHash,
    createdAt: payload.createdAt || new Date().getTime(),
    amountBurnedWithdraw: payload.amountBN,
    tcAddress: payload.tcAddress,
    btcWithdrawAddress: payload.btcWithdrawAddress,
    type: payload.type || 'unknown', //Withdraw
    eventName: payload.type, //Withdraw
    txHash: payload.txHash,
    amountBN: payload.amountBN,
    amountHumanRead: payload.amountHumanRead,
    status: payload.status,
    symbol: payload.symbol,
    txBurnedWithdraw: isL1ToL2 ? undefined : txHash,
    txReceivedDeposit: isL1ToL2 ? txHash : undefined,
    statusStr: 'Pending',
    statusType: StatusType.Processing,
    amountTc: payload.amountTc,
    fromNetwork: payload.fromNetwork,
    toNetwork: payload.toNetwork,
    fromNetworkTitle: payload.fromNetworkTitle,
    toNetworkTitle: payload.toNetworkTitle,
    fromNetworkName: payload.fromNetwork,
    toNetworkName: payload.toNetwork,
  };

  TxBurnMetaMaskMap[txHash] = txObject;

  localStorage.set(Keys.TXS_BURN_METAMASK, TxBurnMetaMaskMap);
};

export const filterTxHistoryAPI = async (
  historyList: HistoryItemType[],
  unInscribedTxIDs: string[],
): Promise<any[]> => {
  let result: any[] = [...historyList];

  // Remove All Tx that history.txBurnedWithdraw === TxBurnMetaMaskMap[txBurnedWithdraw]
  // priority TX from API
  historyList.map((history) => {
    const txBurnedWithdraw = history.txBurnedWithdraw;
    if (txBurnedWithdraw && txBurnedWithdraw !== '') {
      if (TxBurnMetaMaskMap[txBurnedWithdraw]) {
        delete TxBurnMetaMaskMap[txBurnedWithdraw];
        // TxBurnMetaMaskMap[txBurnedWithdraw] = history;
      }
    }

    // Case: L1 => L2: Remove tx if history.txReceivedDeposit = TxBurnMetaMaskMap[txReceivedDeposit].txReceivedDeposit
    const txReceivedDeposit = history.txReceivedDeposit;
    if (txReceivedDeposit && txReceivedDeposit !== '') {
      if (TxBurnMetaMaskMap[txReceivedDeposit]) {
        delete TxBurnMetaMaskMap[txReceivedDeposit];
        // TxBurnMetaMaskMap[txBurnedWithdraw] = history;
      }
    }

    // if (txReceivedDeposit && txReceivedDeposit !== '') {
    //   const txData = TxBurnMetaMaskMap[txReceivedDeposit];
    //   if (
    //     txData &&
    //     txData.txReceivedDeposit &&
    //     txData.txReceivedDeposit === txReceivedDeposit
    //   ) {
    //     delete TxBurnMetaMaskMap[txReceivedDeposit];
    //   }
    // }
  });

  // Save Local
  const storageTxs: TxMetaMaskType[] = Object.values(TxBurnMetaMaskMap) || [];

  const tasks = storageTxs.map(async (trans) => {
    const { fromNetwork, txHash, statusType } = trans;
    if (
      fromNetwork === NETWORK_SUPPORTING.TRUSTLESS_LAYER1 &&
      statusType !== StatusType.Failed
    ) {
      const isFailed = await isFailedTCL1ByHash(txHash);
      if (isFailed) {
        const transaction = {
          ...trans,
          status: 113,
          statusType: StatusType.Failed,
          statusStr: 'Failed',
        };

        TxBurnMetaMaskMap[txHash] = {
          ...transaction,
        };
        return transaction;
      }
    }
    return trans;
  });

  const txBurnMetaMaskList = await Promise.all(tasks);

  localStorage.set(Keys.TXS_BURN_METAMASK, TxBurnMetaMaskMap);

  txBurnMetaMaskList.map((tx) => {
    if (
      unInscribedTxIDs.some(
        (unInscribedTxIDs) =>
          unInscribedTxIDs.toLowerCase() === tx.txHash.toLowerCase(),
      )
    ) {
      TxBurnMetaMaskMap[tx.txHash] = {
        ...TxBurnMetaMaskMap[tx.txHash],
        status: WAITING_INSCRIBED_STATUS,
        isNeedInscribe: true,
      };
    } else {
      TxBurnMetaMaskMap[tx.txHash] = {
        ...TxBurnMetaMaskMap[tx.txHash],
        status: tx.status || undefined,
      };
    }
  });

  // Save TxBurnMetaMaskMap final
  localStorage.set(Keys.TXS_BURN_METAMASK, TxBurnMetaMaskMap);

  // @ts-ignore
  const localTxs = Object.values(TxBurnMetaMaskMap as any).map((trans: any) => {
    const { fromNetwork, toNetwork, fromNetworkTitle, toNetworkTitle } =
      trans as SetTxBurnMetaMaskPayload;

    const formatNetwork = (network: string) => {
      // return network
      //   ? network.replace('-Testnet', '').replace('-', '').replace('-', '')
      //   : network;
      return network;
    };
    const fromToNetworkInfo =
      !fromNetwork || !toNetwork
        ? 'Unknow'
        : `${
            SYMBOL_BY_NETWORK_NAME[fromNetwork as NETWORK_SUPPORTING] ||
            formatNetwork(fromNetworkTitle)
          } -> ${
            SYMBOL_BY_NETWORK_NAME[toNetwork as NETWORK_SUPPORTING] ||
            formatNetwork(toNetworkTitle)
          }`;

    return {
      ...trans,
      fromToNetworkInfo: fromToNetworkInfo,
    };
  });

  // append Tx local list to Result
  result = [...result, ...localTxs];

  return result;
};

export const filterTxByTcAddress = (
  historyList: HistoryItemType[],
  tcAddress: string,
) => {
  if (!historyList || !tcAddress) return [];
  return (
    historyList.filter(
      (history) => history.tcAddress?.toLowerCase() === tcAddress.toLowerCase(),
    ) || []
  );
};

export { Keys, setTxBurnMetaMask, removeTxByHash };
