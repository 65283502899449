import { Input2 } from '@/components/Inputs/Input2';
import validator, { composeValidators } from '@/utils/validator';
import Space from '@components/Space';
import { Field } from 'react-final-form';
import { useSelector } from 'react-redux';
import { Fields } from './FormBridge.constants';
import { Box, ErrorText } from './FormBridge.styles';
import { getFormBridgeInfo } from './state/selector';

const CentralizeInput = () => {
  const { toInputPlaceHolder } = useSelector(getFormBridgeInfo);

  return (
    <Box>
      <Field
        name={Fields.ADDRESS}
        validate={composeValidators(
          validator.addressRequired(),
          validator.validateEVMAddress(),
        )}
      >
        {({ input, meta }) => (
          <>
            <Input2
              {...input}
              {...meta}
              onChange={(event: any) => {
                input.onChange(event);
              }}
              id={Fields.ADDRESS}
              name={Fields.ADDRESS}
              placeholder={`${toInputPlaceHolder}`}
              type="text"
              step={'any'}
              autoComplete="off"
              spellCheck={false}
            />

            {meta.error && meta.touched && (
              <>
                <Space.Vertical size={8} />
                <ErrorText>{meta.error}</ErrorText>
              </>
            )}
          </>
        )}
      </Field>
    </Box>
  );
};

export default CentralizeInput;
