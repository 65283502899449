import { Modal } from 'react-bootstrap';
import { Container, ModalStyled, FormStyled } from './styled';
import Text from '@components/Text';
import useConvertTCL2ToL1, {
  IConvertTCL2ToL1Params,
} from '@/hooks/contract-operations/useConvertTCL2ToL1';
import { formatAmount } from '@utils/format';
import useContractOperation from '@/hooks/contract-operations/useContractOperation';
import Stepper from '@components/WithdrawTCLayer2/Stepper';
import { WITHDRAW_STEPS } from '@components/WithdrawTCLayer2/constants';
import { SubmitButtonStyled } from '@/containers/FormBridge/FormBridge.styles';
import { useWeb3React } from '@web3-react/core';
import React from 'react';
import { SupportedChainId } from '@constants/chains';
import { useSelector } from 'react-redux';
import { getUserSelector } from '@state/user/selector';
import { switchChain } from '@/utils';
import { toast } from 'react-hot-toast';
import { parseError } from '@utils/errorHelper';
import Spinner from 'react-bootstrap/Spinner';
import withdrawTCL2Storage from '@components/WithdrawTCLayer2/storage';
import { setTxBurnMetaMask } from '@/containers/Withdraw/Withdraw.localStorage';
import { getEventTypeParseNetwork } from '@/containers/History/History.types';
import { LOCAL_PENDING_STATUS } from '@/containers/History/History.constants';
import { getFormBridgeInfo } from '@/containers/FormBridge/state/selector';

interface IProps {
  isShow: boolean;
  onHide: () => void;
  symbol: string;
  data?: IConvertTCL2ToL1Params;
}

const TCL2WithdrawModal = (props: IProps) => {
  const { isShow, onHide, data, symbol } = props;
  const { chainId, account } = useWeb3React();
  const user = useSelector(getUserSelector);
  const [processing, setProcessing] = React.useState(false);
  const [steps, setStep] = React.useState(WITHDRAW_STEPS);
  const [checked] = React.useState({
    check1: true,
    check2: true,
  });
  const {
    fromTokenSelected,
    fromNetworkSelected,
    toNetworkSelected,
    fromNetworkObject,
    toNetworkObject,
  } = useSelector(getFormBridgeInfo);

  const { run: convertTCL2ToL1 } = useContractOperation<
    IConvertTCL2ToL1Params,
    string
  >({
    operation: useConvertTCL2ToL1,
    inscribeable: false,
  });

  const buttonStatus = React.useMemo(() => {
    const isSwitchNetwork =
      fromNetworkObject && chainId !== fromNetworkObject.chainId;

    let buttonLabel = 'Initiate withdrawal';
    if (isSwitchNetwork) {
      buttonLabel = `Switch to ${fromNetworkObject.networkTitle}`;
    }

    return {
      isSwitchNetwork,
      buttonLabel,
    };
  }, [chainId, user]);

  const isCreateTx = React.useMemo(() => {
    return steps[0].processing;
  }, [steps[0].processing]);

  const onSubmit = async () => {
    setProcessing(true);
    try {
      if (buttonStatus.isSwitchNetwork) {
        await switchChain(fromNetworkObject?.chainId);
      } else if (data) {
        const tx = await convertTCL2ToL1({
          ...data,
        });

        const hash = Object(tx)?.hash;
        if (hash) {
          withdrawTCL2Storage.setInitWithdraw(hash);

          const amountHumanRead = formatAmount(data.amount, true, 18);

          //Save TX Burn Metamask to LocalStorage
          await setTxBurnMetaMask({
            txHash: hash,
            amountHumanRead: amountHumanRead,
            amountBN: data.amount,
            btcWithdrawAddress: '',
            tcAddress: account || '',
            createdAt: new Date().getTime(),
            isL1ToL2: false,
            type: getEventTypeParseNetwork(fromNetworkSelected, toNetworkSelected),
            symbol: fromTokenSelected?.symbol,
            status: LOCAL_PENDING_STATUS,
            amountTc: data.amount,
            fromNetwork: fromNetworkSelected,
            toNetwork: toNetworkSelected,
            toNetworkTitle: toNetworkObject?.networkTitle || '',
            fromNetworkTitle: fromNetworkObject?.networkTitle || '',
          });
        }
        const _steps = steps.map((step, index) => {
          if (Object(tx)?.hash) {
            if (index === 0) {
              return {
                ...step,
                processing: false,
                hash: hash,
              };
            }
            if (index === 1) {
              return {
                ...step,
                processing: true,
              };
            }
          }

          return step;
        });
        setStep(_steps);
        toast.success('Withdrawal successfully.');
      }
    } catch (error) {
      toast.error(parseError(error));
    } finally {
      setProcessing(false);
    }
  };

  const onClose = () => {
    onHide();
    setProcessing(false);
  };

  if (!data) return <></>;

  return (
    <ModalStyled centered show={isShow} keyboard={false} onHide={onClose}>
      <Modal.Header closeButton color="white" closeVariant="white">
        <Modal.Title>Withdrawal</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Text color="text2">Amount to withdraw</Text>
          <Text color="white" fontSize={24} fontWeight={700}>
            {formatAmount(data.amount)} {symbol}
          </Text>
          <Stepper steps={steps} l2Explorer={fromNetworkObject?.explorerUrl || ''} />
          {/*{isCreateTx && (*/}
          {/*  <FormStyled>*/}
          {/*    {['checkbox'].map((type) => (*/}
          {/*      <div key={type} className="mb-3">*/}
          {/*        <Form.Check*/}
          {/*          onClick={() =>*/}
          {/*            setChecked((value) => ({*/}
          {/*              ...value,*/}
          {/*              check1: !value.check1,*/}
          {/*            }))*/}
          {/*          }*/}
          {/*          type={type as any}*/}
          {/*          id={`check1-${type}`}*/}
          {/*          label={*/}
          {/*            <Text color="white">*/}
          {/*              I understand it will take a few minutes until my funds are*/}
          {/*              claimable on Trustless.*/}
          {/*            </Text>*/}
          {/*          }*/}
          {/*        />*/}
          {/*        <Form.Check*/}
          {/*          type={type as any}*/}
          {/*          className="bottom-check"*/}
          {/*          onClick={() =>*/}
          {/*            setChecked((value) => ({*/}
          {/*              ...value,*/}
          {/*              check2: !value.check2,*/}
          {/*            }))*/}
          {/*          }*/}
          {/*          label={*/}
          {/*            <Text color="white">*/}
          {/*              I understand the 1 minute withdrawal timer does not start*/}
          {/*              until I prove my withdrawal.*/}
          {/*            </Text>*/}
          {/*          }*/}
          {/*          id={`check2-${type}`}*/}
          {/*        />*/}
          {/*      </div>*/}
          {/*    ))}*/}
          {/*  </FormStyled>*/}
          {/*)}*/}

          {isCreateTx && (
            <SubmitButtonStyled
              onClick={onSubmit}
              disabled={processing || !checked.check1 || !checked.check2}
            >
              {processing ? (
                <Spinner animation="border" role="status" />
              ) : (
                buttonStatus.buttonLabel
              )}
            </SubmitButtonStyled>
          )}
        </Container>
      </Modal.Body>
    </ModalStyled>
  );
};

export default TCL2WithdrawModal;
