import { useForm } from 'react-final-form';
import { Fields } from '../FormBridge.constants';
import { ErrorText } from '../FormBridge.styles';

const ToInputFieldError = () => {
  const form = useForm();
  const stateField = form.getFieldState(Fields.ADDRESS);
  if (!stateField) return null;

  return (
    stateField.error &&
    stateField.touched && <ErrorText>{stateField.error}</ErrorText>
  );
};

export default ToInputFieldError;
