import { TC_LAYER2_NETWORK_RPC, TC_NETWORK_RPC } from '@/configs';
import { isProduction } from '@/utils/commons';

enum SupportedChainId {
  UNKNOW = -1,
  GOERLI = 5,
  POLYGON_MUMBAI = 80001,
  ETHEREUM = isProduction() ? 1 : 11155111, //  SEPOLIA = 11155111,
  TRUSTLESS_COMPUTER = isProduction() ? 22213 : 22215,

  TRUSTLESS_COMPUTER_LAYER_2 = isProduction() ? 42213 : 42070, // TODO: UPDATE LAYER2
}

const TRUSTLESS_COMPUTER_CHAIN_INFO = {
  name: isProduction() ? 'Trustless Computer' : 'Trustless Computer (Testnet)',
  title: '',
  chain: 'BVM',
  icon: '',
  rpc: [TC_NETWORK_RPC],
  faucets: [],
  nativeCurrency: {
    name: 'BVM',
    symbol: 'BVM',
    decimals: 18,
  },
  infoURL: 'https://trustless.computer',
  shortName: 'BVM',
  chainId: SupportedChainId.TRUSTLESS_COMPUTER,
  networkId: SupportedChainId.TRUSTLESS_COMPUTER,
  slip44: 0,
  explorers: [
    {
      name: isProduction()
        ? 'Trustless computer explorer'
        : 'Trustless computer explorer (Testnet)',
      url: isProduction()
        ? 'https://explorer.trustless.computer'
        : 'https://explorer.regtest.trustless.computer/',
      standard: 'EIP3091',
    },
  ],
  ens: {
    registry: '',
  },
};

const TRUSTLESS_COMPUTER_LAYER2_CHAIN_INFO = {
  name: isProduction() ? 'Alpha' : 'Alpha (Test)',
  title: '',
  chain: 'TC',
  icon: '',
  rpc: [TC_LAYER2_NETWORK_RPC],
  faucets: [],
  nativeCurrency: {
    name: 'TC',
    symbol: 'TC',
    decimals: 18,
  },
  infoURL: 'https://trustless.computer',
  shortName: 'TC',
  chainId: SupportedChainId.TRUSTLESS_COMPUTER_LAYER_2,
  networkId: SupportedChainId.TRUSTLESS_COMPUTER_LAYER_2,
  slip44: 0,
  explorers: [
    {
      name: isProduction()
        ? 'Trustless computer explorer'
        : 'Trustless computer explorer (Test)',
      url: isProduction()
        ? 'https://explorer.l2.trustless.computer/'
        : 'https://nos-explorer.regtest.trustless.computer/',
      standard: 'EIP3091',
    },
  ],
  ens: {
    registry: '',
  },
};

const CHAINS_INFO = [
  TRUSTLESS_COMPUTER_CHAIN_INFO,
  TRUSTLESS_COMPUTER_LAYER2_CHAIN_INFO,
];

export {
  CHAINS_INFO,
  TRUSTLESS_COMPUTER_CHAIN_INFO,
  TRUSTLESS_COMPUTER_LAYER2_CHAIN_INFO,
  SupportedChainId,
};
