import { Image } from '@/components/Image';
import Text from '@/components/Text';
import { getIconUrl } from '@/utils/token';
import { Dropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Token } from '@/state/tokens/types';
import { getFormBridgeInfo, getTokensSelectable } from '../state/selector';
import { formBridgeActions } from '../state/reducer';
import DropDownIcon from '@/components/IconSVG/DropDownIcon';

const DropdownStyled = styled(Dropdown)`
  &&& {
    display: flex;
    align-items: center;
    margin-top: 5px;
    /* background-color: red; */
  }
`;

const DropdownToggleStyled = styled(Dropdown.Toggle)<{ isempty?: number }>`
  &&& {
    display: flex;
    align-items: center;
    flex-direction: row;

    margin: 0;
    padding: 0;
    flex: 1;
    min-height: 55px;

    background: ${({ theme, isempty }) =>
      isempty ? theme.colors.bg1 : theme.colors.black};
    border: 1px solid ${({ theme }) => theme.colors.tranpsarent};

    :hover {
      cursor: pointer;
      background: ${({ theme }) => theme.colors.bg1};
    }

    ::after {
      color: ${({ theme }) => theme.colors.tranpsarent};
    }
  }
`;

const DropdownMenuStyled = styled(Dropdown.Menu)`
  &&& {
    flex: 1;
    padding: 0;
    margin: 0;
    width: 100%;
    background: ${({ theme }) => theme.colors.bg1};
    border: 1px solid ${({ theme }) => theme.colors.border1};
    border-radius: 8px;
  }
`;

const DropdownItemStyled = styled(Dropdown.Item)`
  &&& {
    all: unset;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
`;

const RowSpaceBetween = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const SelectTokenItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  padding: 5px 12px;
  min-height: 55px;
  min-width: 150px;

  :hover {
    cursor: pointer;
    color: white;
    background: ${({ theme }) => theme.colors.bg5};
  }
`;

const FromTokenSelect = () => {
  const dispatch = useDispatch();
  const { fromTokenSelected } = useSelector(getFormBridgeInfo);

  const tokenList = useSelector(getTokensSelectable)({
    isFrom: true,
  });

  const tokenOnClick = (token: Token) => {
    if (fromTokenSelected?.id !== token.id) {
      dispatch(formBridgeActions.setFromTokenSelected(token));
    }
  };

  const renderItem = (token: Token) => {
    return (
      <SelectTokenItem
        key={token.id + 'FROM_TOKENS'}
        onClick={() => tokenOnClick(token)}
      >
        <Image iconUrl={getIconUrl({ token })} size={28} alt={`${token.symbol}`} />
        <Text style={{ marginLeft: 10 }}>{`${(
          token.symbol || 'Unknow'
        ).toUpperCase()}`}</Text>
      </SelectTokenItem>
    );
  };

  const isEmptyToken = !fromTokenSelected;

  return (
    <DropdownStyled>
      <DropdownToggleStyled isempty={Number(isEmptyToken)}>
        <RowSpaceBetween>
          {!fromTokenSelected ? (
            <Text fontSize={18}>Select Token</Text>
          ) : (
            <Row>
              <Image
                iconUrl={getIconUrl({
                  token: fromTokenSelected,
                })}
                size={32}
                alt={`${fromTokenSelected.symbol}`}
              />
              <Text fontSize={18} style={{ marginLeft: 14 }}>
                {`${fromTokenSelected.symbol?.toUpperCase()}`}
              </Text>
            </Row>
          )}
          {tokenList && tokenList.length > 1 && <DropDownIcon />}
        </RowSpaceBetween>
      </DropdownToggleStyled>
      {tokenList && tokenList.length > 1 && (
        <DropdownMenuStyled>
          {tokenList.map((token: Token) => {
            return (
              <DropdownItemStyled key={token.id}>
                {renderItem(token)}
              </DropdownItemStyled>
            );
          })}
        </DropdownMenuStyled>
      )}
    </DropdownStyled>
  );
};

export default FromTokenSelect;
