import styled from 'styled-components';

export const Container = styled.div`
  /* margin-top: 10px; */
  /* margin-bottom: 16px; */
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  padding: 12px 16px;

  background: #000000;

  .time-item {
  }

  .fee-item {
  }

  .status-item {
  }

  @media screen and (max-width: 1024px) {
    .time-item {
      display: none;
    }
  }

  @media screen and (max-width: 768px) {
    .fee-item {
      display: none;
    }
  }

  @media screen and (max-width: 640px) {
    .status-item {
      display: none;
    }
  }
`;

export const WrapTitle = styled.div`
  display: flex;
  flex: 1;
  max-width: 120px;

  /* background-color: red; */
`;

export const Title = styled.p`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: left;
  align-self: left;

  text-transform: uppercase;

  @media screen and (max-width: 1920px) {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
  }
  @media screen and (max-width: 1024px) {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
  }
  @media screen and (max-width: 768) {
    font-weight: 600;
    font-size: 12px;
  }

  /* color: #3772ff; */

  opacity: 1;
`;
