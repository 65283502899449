import styled, { DefaultTheme } from 'styled-components';

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  gap: 10px;

  .title {
    font-weight: 500;
    font-size: 56px;
    line-height: 66px;
    align-items: center;
    align-self: center;
    letter-spacing: -0.02em;
    color: ${({ theme }: { theme: DefaultTheme }) => theme.colors.white};
  }

  .decs {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    align-items: center;
    align-self: center;
  }
`;

export { Container };
