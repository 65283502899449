import { LocalStorageUtil } from '@utils/localstorage';
import { isProduction } from '@utils/commons';
import { isFailedTCL1ByHash } from '@utils/sdk';

const Keys = {
  ConvertTCL2Storage: 'ConvertTCL2Storage',
};

export interface ITransactionItem {
  l2Hash: string;
  l1ProveHash?: string;
  l1ClaimHash?: string;
  updatedAt: number;
}

interface ITransaction {
  [key: string]: ITransactionItem;
}

class ConvertTCL2Storage extends LocalStorageUtil {
  getKey = () => {
    return Keys.ConvertTCL2Storage + isProduction();
  };
  private getStorageTransactionByL2Hash = (
    l2Hash: string,
  ): ITransactionItem | undefined => {
    const transactions = this.getTransactions();
    const transaction = transactions[l2Hash.toLowerCase()];
    return (
      transaction || {
        l1ProveHash: undefined,
        l1ClaimHash: undefined,
        l2Hash,
      }
    );
  };
  private rpcCheckIsTransactionFailed = async (txHash: string, time: number) => {
    const isExpired = new Date().getTime() - time > 30 * 60 * 1000;
    if (!isExpired) {
      return false;
    }
    return isFailedTCL1ByHash(txHash);
  };
  getTransactions = (): ITransaction => {
    const key = this.getKey();
    const transactions = this.get(key) as ITransaction;
    return transactions || {};
  };
  setInitWithdraw = (l2Hash: string) => {
    if (!l2Hash) return;
    const transactions = this.getTransactions();
    const key = this.getKey();
    const _newTransactions: ITransaction = {
      ...transactions,
      [l2Hash.toLowerCase()]: {
        l2Hash,
        updatedAt: new Date().getTime(),
      },
    };
    this.set(key, _newTransactions);
  };
  addTransactionHash = (
    l2Hash: string,
    params: {
      l1ProveHash?: string;
      l1ClaimHash?: string;
    },
  ) => {
    if (!l2Hash) return;
    const { l1ProveHash, l1ClaimHash } = params;
    const key = this.getKey();
    const transactions = this.getTransactions();
    const currentTransaction = this.getStorageTransactionByL2Hash(l2Hash);
    if (currentTransaction) {
      const newTransaction: ITransactionItem = {
        ...currentTransaction,
        l1ProveHash: currentTransaction?.l1ProveHash || l1ProveHash,
        l1ClaimHash: currentTransaction?.l1ClaimHash || l1ClaimHash,
        updatedAt: new Date().getTime(),
      };
      this.set(key, {
        ...transactions,
        [l2Hash.toLowerCase()]: { ...newTransaction },
      });
    }
  };
  removeTransactionHash = (
    l2Hash: string,
    params: {
      isRemoveL1ProveHash: boolean;
      isRemoveL1ClaimHash: boolean;
    },
  ) => {
    if (!l2Hash) return;
    const { isRemoveL1ProveHash, isRemoveL1ClaimHash } = params;
    const key = this.getKey();
    const transactions = this.getTransactions();
    const currentTransaction = this.getStorageTransactionByL2Hash(l2Hash);
    if (currentTransaction) {
      const newTransaction: ITransactionItem = {
        ...currentTransaction,
        l1ProveHash: isRemoveL1ProveHash
          ? undefined
          : currentTransaction?.l1ProveHash,
        l1ClaimHash: isRemoveL1ClaimHash
          ? undefined
          : currentTransaction.l1ClaimHash,
      };
      this.set(key, {
        ...transactions,
        [l2Hash.toLowerCase()]: { ...newTransaction },
        updatedAt: new Date().getTime(),
      });
    }
  };
  getCurrentStatusByStorage = async (
    l2Hash: string,
  ): Promise<ITransactionItem | undefined> => {
    const currentTransaction = this.getStorageTransactionByL2Hash(l2Hash);
    if (currentTransaction) {
      const txHash =
        currentTransaction.l1ClaimHash || currentTransaction.l1ProveHash;
      if (txHash) {
        const isFailed = await this.rpcCheckIsTransactionFailed(
          txHash,
          currentTransaction.updatedAt,
        );
        // transaction failed
        if (isFailed) {
          const isRemoveProve = !currentTransaction.l1ClaimHash;
          this.removeTransactionHash(l2Hash, {
            isRemoveL1ProveHash: isRemoveProve,
            isRemoveL1ClaimHash: !isRemoveProve,
          });
        }
        return this.getStorageTransactionByL2Hash(l2Hash);
      }
    }
    return undefined;
  };
}

const instance = new ConvertTCL2Storage();

export default instance;
