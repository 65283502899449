import Text from '@/components/Text';
import { AlertTriangle } from 'react-feather';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { getChainInfor } from '../state/selector';
import { getWeb3InforSelector } from '@/state/web3React/selector';

const Container = styled.div`
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 40px;
  width: 100%;

  align-self: center;

  margin-top: 24px;
  padding: 8px 16px;
  gap: 8px;

  border-radius: 8px;

  background-color: ${({ theme }) => theme.colors.white};

  .chain-name {
    color: ${({ theme }) => theme.colors.warning};
    font-family: 'TitilliumWeb-SemiBold';
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;
type Props = {
  switchChainOnClick?: () => void;
};

const SwitchChainWarning = (props: Props) => {
  const { switchChainOnClick = () => {} } = props;
  const { supportChainName, isCorrectChain, supportChainID } =
    useSelector(getChainInfor);
  const { isNeededConnectWeb3 } = useSelector(getWeb3InforSelector);
  if (isNeededConnectWeb3) return null;

  if (isCorrectChain || !supportChainName || !supportChainID) return null;

  return (
    <Container>
      <AlertTriangle color="orange" />
      <Text color="black" fontSize={16}>
        {`You must switch to `}
        <span className="chain-name hover" onClick={switchChainOnClick}>
          {supportChainName || ''}
        </span>
        <span>{` to begin the transfer.`}</span>
      </Text>
    </Container>
  );
};

export default SwitchChainWarning;
