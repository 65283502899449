import { Image } from '@/components/Image';
import Text from '@/components/Text';
import { Dropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { formBridgeActions } from '../state/reducer';
import {
  getFromNetworkSelected,
  getNetworksSelectable,
  getToNetworkSelected,
} from '../state/selector';
import DropDownIcon from '@/components/IconSVG/DropDownIcon';
import { getNetworkByNameSelector } from '@/state/network/selector';
import { NetworkName } from '@/state/network/types';

const DropdownStyled = styled(Dropdown)`
  &&& {
    display: flex;
    align-items: center;
    margin-top: 5px;
    /* background-color: red; */
  }
`;

const DropdownToggleStyled = styled(Dropdown.Toggle)<{ isempty?: number }>`
  &&& {
    display: flex;
    align-items: center;
    flex-direction: row;

    margin: 0;
    padding: 0;
    flex: 1;
    min-height: 45px;

    background: ${({ theme, isempty }) =>
      isempty ? theme.colors.bg1 : theme.colors.black};
    border: 1px solid ${({ theme }) => theme.colors.tranpsarent};

    :hover {
      cursor: pointer;
      background: ${({ theme }) => theme.colors.bg1};
    }

    ::after {
      color: ${({ theme }) => theme.colors.tranpsarent};
    }
  }
`;

const DropdownMenuStyled = styled(Dropdown.Menu)`
  &&& {
    flex: 1;
    padding: 0;
    margin: 0;
    width: 100%;
    background: ${({ theme }) => theme.colors.bg1};
    border: 1px solid ${({ theme }) => theme.colors.border1};
    border-radius: 8px;
  }
`;

const DropdownItemStyled = styled(Dropdown.Item)`
  &&& {
    all: unset;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 14px;
  flex: 1;
`;

const RowSpaceBetween = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const SelectNetworkItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;

  padding: 5px 12px;
  min-height: 55px;
  min-width: 150px;

  :hover {
    cursor: pointer;
    color: white;
  }

  .enhanceText {
    text-wrap: balance;
    text-align: left;
  }
`;

const FromNetworkSelect = () => {
  const dispatch = useDispatch();
  const networkFromSelected = useSelector(getFromNetworkSelected);
  const networkToSelected = useSelector(getToNetworkSelected);
  const { fromNetworkList } = useSelector(getNetworksSelectable);

  const getNetworkObjectByName = useSelector(getNetworkByNameSelector);

  const itemOnClick = (network: string) => {
    if (network === networkToSelected) {
      dispatch(formBridgeActions.swapAction());
    } else if (network !== networkFromSelected) {
      dispatch(formBridgeActions.setFromNetwork(network));
    }
  };
  const renderItem = (network: string) => {
    const networkObj = getNetworkObjectByName(network);
    return (
      <SelectNetworkItem key={network} onClick={() => itemOnClick(network)}>
        <Row>
          <Image
            iconUrl={networkObj?.networkLogo || ''}
            size={30}
            alt={`${networkFromSelected}`}
          />
          <Text
            fontSize={18}
            className="enhanceText"
            style={{
              textTransform: 'capitalize',
            }}
          >
            {networkObj?.networkTitle || 'Undefined'}
          </Text>
        </Row>
      </SelectNetworkItem>
    );
  };

  const isEmptyNetwork = !networkFromSelected;

  return (
    <DropdownStyled>
      <DropdownToggleStyled isempty={Number(isEmptyNetwork)}>
        <RowSpaceBetween>
          {isEmptyNetwork ? (
            <Text>Select Network</Text>
          ) : (
            <Row>{renderItem(networkFromSelected)}</Row>
          )}
          <DropDownIcon />
        </RowSpaceBetween>
      </DropdownToggleStyled>
      <DropdownMenuStyled>
        {fromNetworkList &&
          fromNetworkList.length > 0 &&
          fromNetworkList.map((network: NetworkName) => {
            return (
              <DropdownItemStyled key={network + 'NETWORK_FROM'}>
                {renderItem(network)}
              </DropdownItemStyled>
            );
          })}
      </DropdownMenuStyled>
    </DropdownStyled>
  );
};

export default FromNetworkSelect;
