import { GenerateDepositData } from '@/interfaces/api/deposit';
import { EstimateWithdrawFeeData } from '@/interfaces/api/withdraw';
import { formatAmountFactory } from '@/utils/format';
import BigNumber from 'bignumber.js';

const depositDataFormmater = (data: GenerateDepositData | undefined) => {
  if (!data) return undefined;
  const { tcAddress, depositAddress, minDepositAmount, depositFee, depositBtc } =
    data;

  const {
    priceBN: depositFeeBN,
    priceFormatedBN: depositFeeFormatedBN,
    priceFormatedStr: depositFeeHRFormatStr,
  } = formatAmountFactory(depositFee);

  const {
    priceBN: minDepositAmountBN,
    priceFormatedBN: minDepositAmountFormatedBN,
    priceFormatedStr: minDepositAmountFormatedStr,
  } = formatAmountFactory(minDepositAmount);

  const {
    priceBN: currentBTCBalanceBN,
    priceFormatedBN: currentBTCBalanceFormatedBN,
    priceFormatedStr: currentBTCBalanceFormatedStr,
  } = formatAmountFactory(depositBtc);

  const needBTCBalanceMore = currentBTCBalanceFormatedBN?.minus(
    depositFeeFormatedBN!,
  );
  const isNeedBTCBalanceMore = needBTCBalanceMore?.lte(0);

  const needBTCBalanceMoreStr = new BigNumber(needBTCBalanceMore || '0.0')
    .abs()
    .toFixed();

  const minDepositShouldBeHide = minDepositAmountBN?.lte(0);

  return {
    ...data,

    tcAddress,
    depositAddress,

    depositFee,
    depositFeeBN,
    depositFeeFormatedBN,
    depositFeeHRFormatStr,

    minDepositAmount,
    minDepositAmountBN,
    minDepositAmountFormatedBN,
    minDepositAmountFormatedStr,

    currentBTCBalanceBN,
    currentBTCBalanceFormatedBN,
    currentBTCBalanceFormatedStr,

    isNeedBTCBalanceMore,
    needBTCBalanceMoreStr,

    minDepositShouldBeHide,
  };
};

const estimateDataFormmater = (data: EstimateWithdrawFeeData | undefined) => {
  if (!data) return undefined;

  const {
    minWithdrawAmount: minWithdrawAmountOriginal,
    withdrawFee: withdrawFeeOriginal,
    withdrawBtc,

    withdrawBtcL2,
    withdrawFeeL2: withdrawFeeL2Original,

    crossLayerDepositAddress,
    isBlockToken,
  } = data;

  const {
    priceBN: withdrawFeeBN,
    priceFormatedBN: withdrawFeeFormatedBN,
    priceFormatedStr: withdrawFeeHRFormatStr,
  } = formatAmountFactory(withdrawFeeOriginal);

  const {
    priceBN: minWithdrawAmountBN,
    priceFormatedBN: minWithdrawAmountFormatedBN,
    priceFormatedStr: minWithdrawAmountFormatedStr,
  } = formatAmountFactory(minWithdrawAmountOriginal);

  const {
    priceBN: currentBTCBalanceBN,
    priceFormatedBN: currentBTCBalanceFormatedBN,
    priceFormatedStr: currentBTCBalanceFormatedStr,
  } = formatAmountFactory(withdrawBtc || '0.0');

  //Layer 2

  const {
    priceBN: withdrawFeeL2BN,
    priceFormatedBN: withdrawFeeL2FormatedBN,
    priceFormatedStr: withdrawFeeL2HRFormatStr,
  } = formatAmountFactory(withdrawFeeL2Original || '0.0');

  const {
    priceBN: currentBTCBalanceL2BN,
    priceFormatedBN: currentBTCBalanceL2FormatedBN,
    priceFormatedStr: currentBTCBalanceL2FormatedStr,
  } = formatAmountFactory(withdrawBtcL2 || '0.0');

  // L1: Compare Fee & Balance BTC
  const needBTCBalanceMore = currentBTCBalanceFormatedBN?.minus(
    withdrawFeeFormatedBN!,
  );
  const isNeedBTCBalanceMore = needBTCBalanceMore?.lte(0);
  const needBTCBalanceMoreStr = new BigNumber(needBTCBalanceMore || '0.0')
    .abs()
    .toFixed();

  // L2: Compare Fee & Balance BTC (TO DO)

  const minWithdrawAmountShouldBeHide = minWithdrawAmountBN?.lte(0);

  return {
    ...data,

    withdrawFeeOriginal,
    withdrawFeeBN,
    withdrawFeeFormatedBN,
    withdrawFeeHRFormatStr,

    minWithdrawAmountOriginal,
    minWithdrawAmountBN,
    minWithdrawAmountFormatedBN,
    minWithdrawAmountFormatedStr,

    currentBTCBalanceBN,
    currentBTCBalanceFormatedBN,
    currentBTCBalanceFormatedStr,

    // Layer 2
    withdrawFeeL2Original,
    withdrawFeeL2BN,
    withdrawFeeL2FormatedBN,
    withdrawFeeL2HRFormatStr,

    currentBTCBalanceL2BN,
    currentBTCBalanceL2FormatedBN,
    currentBTCBalanceL2FormatedStr,

    isNeedBTCBalanceMore,
    needBTCBalanceMoreStr,

    crossLayerDepositAddress,
    isBlockToken,

    minWithdrawAmountShouldBeHide,
  };
};

export { depositDataFormmater, estimateDataFormmater };
