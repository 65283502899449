import { Modal } from 'react-bootstrap';
import { Container, ModalStyled } from './styled';
import Text from '@components/Text';
import Stepper from '@components/WithdrawTCLayer2/Stepper';
import { IWithdrawStep } from '@components/WithdrawTCLayer2/constants';
import { SubmitButtonStyled } from '@/containers/FormBridge/FormBridge.styles';
import { useWeb3React } from '@web3-react/core';
import React from 'react';
import { SupportedChainId } from '@constants/chains';
import { useSelector } from 'react-redux';
import { getIsAuthenticatedSelector } from '@state/user/selector';
import Spinner from 'react-bootstrap/Spinner';
import { ITransactionItem } from '@components/WithdrawTCLayer2/storage';
import router from 'next/router';
import { ROUTE_PATH } from '@constants/route-path';
import { switchChain } from '@/utils';
import { toast } from 'react-hot-toast';
import { parseError } from '@utils/errorHelper';
import useContractOperation from '@/hooks/contract-operations/useContractOperation';
import useProveTCL2, {
  IProveTCL2Params,
} from '@/hooks/contract-operations/useProveTCL2';
import useClaimTCL2, {
  IClaimTCL2Params,
} from '@/hooks/contract-operations/useClaimTCL2';
import withdrawTCL2Storage from '@components/WithdrawTCLayer2/storage';
import * as TC_SDK from 'trustless-computer-sdk';
import { TransactionEventType } from '@/enums/transaction';
import { isProduction } from '@utils/commons';
import { formatAmount } from '@utils/format';
import useBitcoin from '@/hooks/useBitcoin';
import useAsyncEffect from 'use-async-effect';
import { TC_WEB_URL } from '@/configs';
import throttle from 'lodash/throttle';
import { getCrossChainMessengerPayloadFromNetwork } from '@utils/optimism';
import { getNetworkByNameSelector } from '@state/network/selector';
export interface IConvertData {
  storage?: ITransactionItem;
  steps: IWithdrawStep[];
  l2Hash: string;
  amount: string;
  isClaim: boolean;
  isProve: boolean;
  isSuccess: boolean;
  network: string;
}

interface IProps {
  isShow: boolean;
  onHide: () => void;
  data: IConvertData;
  fetchData: () => void;
  address: string;
  symbol: string;
}

const TCL2TransactorModal = (props: IProps) => {
  const { isShow, onHide, data, address, symbol } = props;
  const { chainId } = useWeb3React();
  const [submitted, setSubmitted] = React.useState(false);
  const isAuthenticated = useSelector(getIsAuthenticatedSelector);
  const [processing, setProcessing] = React.useState(false);
  const { getUnInscribedTransactionByAddress } = useBitcoin();
  const [unInscribedTxIDs, setUnInscribedTxIDs] = React.useState<string[]>([]);
  const fromNetworkObject = useSelector(getNetworkByNameSelector)(data.network);

  const { run: proveTCL2 } = useContractOperation<IProveTCL2Params, string>({
    operation: useProveTCL2,
    inscribeable: true,
  });

  const { run: claimTCL2 } = useContractOperation<IClaimTCL2Params, string>({
    operation: useClaimTCL2,
    inscribeable: true,
  });

  const buttonStatus = React.useMemo(() => {
    const isSwitchNetwork = chainId !== SupportedChainId.TRUSTLESS_COMPUTER;

    const isNeedInscribe =
      unInscribedTxIDs.some(
        (txID) =>
          txID.toLowerCase() === (data.storage?.l1ClaimHash || '').toLowerCase() ||
          txID.toLowerCase() === (data.storage?.l1ProveHash || '').toLowerCase(),
      ) ||
      ((data.isProve || data.isClaim) && !!unInscribedTxIDs.length);

    let buttonLabel = '';
    if (isSwitchNetwork) {
      buttonLabel = 'Switch to Trustless Computer';
    } else if (!isAuthenticated) {
      buttonLabel = 'Connect to Trustless Wallet';
    } else if (isNeedInscribe) {
      buttonLabel = 'Process pending transaction';
    } else if (data.isProve) {
      buttonLabel = 'Prove withdrawal';
    } else if (data.isClaim) {
      buttonLabel = 'Claim withdrawal';
    }

    return {
      isSwitchNetwork,
      isNeedConnect: !isAuthenticated,
      isNeedInscribe,
      buttonLabel,
    };
  }, [
    chainId,
    isAuthenticated,
    data,
    unInscribedTxIDs,
    data.storage?.l1ProveHash,
    data.storage?.l1ClaimHash,
  ]);

  const onClose = () => {
    onHide();
    setProcessing(false);
  };

  const onSubmit = async () => {
    setProcessing(true);
    try {
      if (!fromNetworkObject) {
        throw new Error(`Invalid network ${fromNetworkObject}`);
      }
      let hash = '';
      const crossMessengerParams =
        getCrossChainMessengerPayloadFromNetwork(fromNetworkObject);
      if (buttonStatus.isSwitchNetwork) {
        await switchChain(SupportedChainId.TRUSTLESS_COMPUTER);
      } else if (buttonStatus.isNeedConnect) {
        await router.push(ROUTE_PATH.CONNECT_WALLET);
      } else if (buttonStatus.isNeedInscribe) {
        await window.open(TC_WEB_URL, '_blank');
      } else if (data.isProve) {
        hash = await proveTCL2({
          hash: data.l2Hash,
          ...crossMessengerParams,
        });
        if (hash) {
          withdrawTCL2Storage.addTransactionHash(data.l2Hash, {
            l1ProveHash: hash,
          });
        }
      } else if (data.isClaim) {
        hash = await claimTCL2({
          hash: data.l2Hash,
          ...crossMessengerParams,
        });
        if (hash) {
          withdrawTCL2Storage.addTransactionHash(data.l2Hash, {
            l1ClaimHash: hash,
          });
        }
      }

      if (hash) {
        TC_SDK.signTransaction({
          method: `${
            data?.isProve ? TransactionEventType.Prove : TransactionEventType.Claim
          }`,
          hash: hash,
          dappURL: window.location.origin,
          isRedirect: true,
          target: '_blank',
          isMainnet: isProduction(),
        });
        setSubmitted(true);
      }
    } catch (error) {
      toast.error(parseError(error));
    } finally {
      setProcessing(false);
    }
  };

  const getUninscribed = async () => {
    try {
      if (!address) return;
      const unInscribedTxIDs = await getUnInscribedTransactionByAddress(address);
      setUnInscribedTxIDs(unInscribedTxIDs);
    } catch (e) {
      setUnInscribedTxIDs([]);
    }
  };

  useAsyncEffect(() => {
    getUninscribed();
    const interval = setInterval(getUninscribed, 3000);
    return () => {
      clearInterval(interval);
    };
  }, [address]);

  return (
    <ModalStyled centered show={isShow} keyboard={false} onHide={onClose}>
      <Modal.Header closeButton color="white" closeVariant="white">
        <Modal.Title>Withdrawal</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Text color="text2">Amount to withdraw</Text>
          <Text color="white" fontSize={24} fontWeight={700}>
            {formatAmount(data.amount)} {symbol}
          </Text>
          <Stepper
            steps={data.steps}
            storageData={data.storage}
            l2Explorer={fromNetworkObject?.explorerUrl || ''}
          />
          {((buttonStatus.buttonLabel && (data.isProve || data.isClaim)) ||
            buttonStatus.isNeedInscribe) && (
            <SubmitButtonStyled
              onClick={throttle(onSubmit, 1000)}
              disabled={processing || submitted}
            >
              {processing ? (
                <Spinner animation="border" role="status" />
              ) : (
                buttonStatus.buttonLabel
              )}
            </SubmitButtonStyled>
          )}
        </Container>
      </Modal.Body>
    </ModalStyled>
  );
};

export default TCL2TransactorModal;
