import { isNOSNetwork, isOutChain, isTCNetwork } from '@/constants/network';
import { NetworkName } from '@/state/network/types';
import { IWithdrawStep } from '@components/WithdrawTCLayer2/constants';

declare const Types: [
  'deposit',
  'withdraw',
  'l1 => l2',
  'l2 => l1',
  'deposit l2',
  'withdraw l2',
  'cross layer', // L2s <-> other layers (L1, NOS, other L2)
  'unknown',
];

export declare type HistoryTypes = typeof Types[number];

export type EventTypeMap = {
  [key in HistoryTypes]: HistoryTypes;
};

export const EventMap: EventTypeMap = {
  deposit: 'deposit',
  withdraw: 'withdraw',
  'l1 => l2': 'l1 => l2',
  'l2 => l1': 'l2 => l1',
  'deposit l2': 'deposit l2',
  'withdraw l2': 'withdraw l2',
  'cross layer': 'cross layer', // L2s <-> other layers (L1, NOS, other L2)
  unknown: 'unknown',
};

export const getEventTypeParseNetwork = (
  fromNetwork: NetworkName,
  toNetwork: NetworkName,
) => {
  // Deposit:
  // fromNetwork (BTC, ETH, Ordinal)
  // toNetwork (TC || NOS || L2)
  if (isOutChain(fromNetwork) && isTCNetwork(toNetwork)) {
    // BTC, ETH, Ordinal -> TC
    if (isTCNetwork(toNetwork)) {
      return EventMap.deposit;
    }
    // BTC, ETH, Ordinal -> NOS
    if (isNOSNetwork(toNetwork)) {
      return EventMap['deposit l2'];
    }
  }

  // Withdraw:
  // fromNetwork (TC, NOS)
  // toNetwork (BTC, ETH, Ordinal)

  if (isOutChain(toNetwork)) {
    // TC -> BTC, ETH, Ordinal
    if (isTCNetwork(fromNetwork)) {
      return EventMap.withdraw;
    }
    // NOS -> BTC, ETH, Ordinal
    if (isNOSNetwork(fromNetwork)) {
      return EventMap['withdraw l2'];
    }
  }

  // fromNetwork (TC)
  // toNetwork (NOS)
  if (isTCNetwork(fromNetwork) && isNOSNetwork(toNetwork)) {
    return EventMap['l1 => l2'];
  }

  // fromNetwork (NOS)
  // toNetwork (TC)
  if (isNOSNetwork(fromNetwork) && isTCNetwork(toNetwork)) {
    return EventMap['l2 => l1'];
  }

  // Cross Layer: // L2s <-> other layers (L1, NOS, other L2)
  return EventMap['cross layer'];
};

export enum StatusType {
  Success = 0,
  Processing = 1,
  Failed = 2,
  Info = 3,
}

export type HistoryItemType = {
  id: string;
  uuid?: string;
  deletedAt?: null;
  createdAt?: Date | number;
  updatedAt?: null;
  tcAddress?: string;
  status?: number;
  statusStr?: string;
  fromToNetworkInfo?: string;
  statusType: StatusType;
  type: HistoryTypes; // 'Withdraw' | 'Deposit' | 'L1 => L2' | 'L2 => L1' | 'Deposit L2' | 'Withdraw L2';
  tcToken?: string;
  tcTokenInfo?: TcTokenInfo;
  amountTc?: string; // amount on TC => decimal 18
  fee?: string;
  depositWallet?: string;
  txReceivedDeposit?: string;
  txOutIndexReceived?: number;
  amountReceivedDeposit?: string; // amount receive from external => follow decimal from TcTokenInfo
  txTcProcessDeposit?: string; // TX TC
  txBtcProcessDeposit?: string; // TX BTC
  withdrawWallet?: string;
  txBurnedWithdraw?: string;
  amountBurnedWithdraw?: string; // amount burn on TC => decimal 18
  txProcessWithdraw?: string; //  TX BTC
  txProcessDeposit?: string; //
  changeUTXOIndex?: number;
  note?: string;
  txHash?: string;
  symbol?: string;
  convertTcl2Stepper?: IWithdrawStep[];
  isNeedInscribe?: boolean;
  fromNetwork?: string;
  toNetwork?: string;
  fromNetworkName?: string;
  toNetworkName?: string;
};

export type TcTokenInfo = {
  id: string;
  uuid: string;
  deletedAt: null;
  createdAt: Date;
  updatedAt: null;
  outChainTokenID: string;
  network: string;
  decimals: number;
  name: string;
  symbol: string;
  tcTokenID: string;
  status: number;
  priceUsd: number;
  type: string;
  networkId: string;
  logo?: string;
};
