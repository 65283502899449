import { IStepKey, IWithdrawStep } from '@components/WithdrawTCLayer2/constants';
import Text from '@components/Text';
import {
  HashText,
  Image,
  LinkIcon,
  Row,
  RowContainer,
  StepperContainer,
  VerticalLine,
} from './styled';
import { CDN_ICON_URL } from '@/configs';
import SVG from 'react-inlinesvg';
import React from 'react';
import {
  TRUSTLESS_COMPUTER_CHAIN_INFO,
  TRUSTLESS_COMPUTER_LAYER2_CHAIN_INFO,
} from '@constants/chains';
import { ITransactionItem } from '@components/WithdrawTCLayer2/storage';
import { ellipsisCenter } from '@utils/address';
import CopyIconSVG from '@components/IconSVG/CopyIconSVG';
import copy from 'copy-to-clipboard';
import { toast } from 'react-hot-toast';

interface IProps {
  steps: Array<IWithdrawStep>;
  storageData?: ITransactionItem;
  l2Explorer: string;
}

const Stepper = (props: IProps) => {
  const { steps, storageData } = props;

  const processingIndex = React.useMemo(() => {
    return steps.findIndex((step) => step.processing);
  }, [steps]);

  const onClickCopy = (content: string) => {
    copy(content);
    toast.success('Copied');
  };

  const renderItem = (step: IWithdrawStep, index: number) => {
    let hash = step.hash;
    const isSuccess = steps.find(
      (step) => step.processing && step.key === IStepKey.claim && step.hash,
    );
    const isPassed =
      (!step.processing && processingIndex !== 0 && processingIndex > index) ||
      isSuccess;

    const l2Explorer = `${props.l2Explorer}/tx/`;
    const l1Explorer = `${TRUSTLESS_COMPUTER_CHAIN_INFO.explorers[0].url}tx/`;

    let link = '';
    switch (step.key) {
      case IStepKey.withdraw: {
        hash = hash || storageData?.l2Hash || '';
        if (hash) {
          link = l2Explorer + hash;
        }
        break;
      }
      case IStepKey.prove:
      case IStepKey.claim: {
        hash =
          hash ||
          (step.key === IStepKey.prove
            ? storageData?.l1ProveHash
            : storageData?.l1ClaimHash) ||
          '';
        if (hash) {
          link = l1Explorer + hash;
        }
        break;
      }
    }

    return (
      <>
        <RowContainer key={step.name + step.processing} processing={step.processing}>
          <Row
            hasLink={link}
            onClick={() => {
              if (!link) return;
              window.open(link, '_blank');
            }}
          >
            <Image color={isPassed ? '#18CF8E' : 'white'}>
              <SVG
                src={`${CDN_ICON_URL}/icons/${
                  isPassed ? 'checked.svg' : step.image
                }`}
              />
            </Image>
            <div>
              <Text fontSize={18} color={isPassed ? 'success' : 'white'}>
                {step.name}
              </Text>
            </div>
            {link && (
              <CopyIconSVG
                width={22}
                className="hover"
                scale={0}
                onClick={(e) => {
                  e.stopPropagation();
                  onClickCopy(hash);
                }}
              />
            )}
            {link && <LinkIcon width={22} color="white" className="explorer" />}
          </Row>
          {!!hash && (
            <HashText fontSize={14} color="text2">
              {ellipsisCenter({ str: hash, limit: 5 })}
            </HashText>
          )}
        </RowContainer>
        {index !== steps.length - 1 && (
          <VerticalLine dashed={!isPassed} key={`line-${step.key}`} />
        )}
      </>
    );
  };

  return <StepperContainer>{steps.map(renderItem)}</StepperContainer>;
};

export default Stepper;
