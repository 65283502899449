export enum TransactionStatus {
  PENDING = 'processing',
  CONFIRMED = 'confirmed',
  RESUME = 'pending',
}

export enum TransactionEventType {
  CREATE = 'Create',
  TRANSFER = 'Transfer',
  MINT = 'Mint',
  NONE = 'None',
  DEPLOY = 'Deploy',
  APPROVE = 'Approve',
  WITHDRAW = 'Withdraw',
  DEPOSIT = 'Deposit',
  Prove = 'Prove withdrawal',
  Claim = 'Claim withdrawal',
}
