import { Container, Title, WrapTitle } from './HistoryHeader.styled';

const HistoryHeader = () => {
  return (
    <Container>
      <WrapTitle style={{ flex: 0.5, maxWidth: 130 }}>
        <Title>Direction</Title>
      </WrapTitle>

      <WrapTitle style={{ flex: 2, maxWidth: 260 }}>
        <Title>Transaction ID</Title>
      </WrapTitle>

      <WrapTitle style={{ flex: 0.8, maxWidth: 100 }}>
        <Title>Amount</Title>
      </WrapTitle>

      <WrapTitle className="fee-item" style={{ flex: 0.5, maxWidth: 120 }}>
        <Title>Fee</Title>
      </WrapTitle>

      <WrapTitle style={{ flex: 0.5, maxWidth: 80 }}>
        <Title>Symbol</Title>
      </WrapTitle>

      <WrapTitle className="status-item" style={{ flex: 0.8, maxWidth: 100 }}>
        <Title style={{ paddingLeft: 24 }}>Status</Title>
      </WrapTitle>

      <WrapTitle className="time-item" style={{ flex: 0.8, maxWidth: 100 }}>
        <Title>Time</Title>
      </WrapTitle>
    </Container>
  );
};

export default HistoryHeader;
