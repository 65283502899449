import Text from '@/components/Text';
import { Tab, Tabs } from 'react-bootstrap';
import { HOME_TABS } from './Home.constants';
import { BodyContainer, Container, TabContainer } from './Home.styled';
import queryString from 'query-string';

import { updateApproveTxByHash } from '@/containers/FormBridge/FormBridge.localStorage';
import { withLayout } from '@/layouts';
import React, { useState } from 'react';
import FormBridge from '../FormBridge';
import FormUpdater from '../FormBridge/state/updater';
import HistoryPage from '../History';
import enhance from './Home.enhance';
import { useRouter } from 'next/router';

const Home = (props: any) => {
  const { routeParamReady } = props;
  const router = useRouter();
  const { pathname, query } = router;

  const [tabActive, setTabActive] = useState<string>(
    typeof query['tab'] === 'string' ? query['tab'] : HOME_TABS.Transfer,
  );

  const updateStorageBurnTransaction = () => updateApproveTxByHash();

  React.useEffect(() => {
    updateStorageBurnTransaction();
    const interval = setInterval(updateStorageBurnTransaction, 10000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  React.useEffect(() => {
    const tab = tabActive || HOME_TABS.Transfer;
    delete query['tab'];
    const queryParams = queryString.stringify(query);
    let newPath = `${pathname}?tab=${tab}${queryParams ? '&' + queryParams : ''}`;
    router.push(newPath);
  }, [tabActive]);
  return (
    <Container>
      <BodyContainer>
        <FormUpdater />
        <TabContainer>
          <Tabs
            id="uncontrolled-tab"
            mountOnEnter
            defaultActiveKey={tabActive}
            activeKey={tabActive}
            onSelect={(k) => {
              setTabActive(k || HOME_TABS.Transfer);
            }}
          >
            <Tab
              eventKey={HOME_TABS.Transfer}
              title={<Text className="nav-title">{HOME_TABS.Transfer}</Text>}
            >
              <FormBridge routeParamReady={routeParamReady} />
            </Tab>

            <Tab
              eventKey={HOME_TABS.History}
              title={<Text className="nav-title">{HOME_TABS.History}</Text>}
            >
              <HistoryPage />
            </Tab>
          </Tabs>
        </TabContainer>
      </BodyContainer>
    </Container>
  );
};

export default enhance(withLayout(Home));
