import * as React from 'react';
import { SVGProps, memo } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    {...props}
  >
    <path
      fill="#FF7E21"
      fillRule="evenodd"
      d="M10 12.667a1.333 1.333 0 0 1-1.233 1.33l-.1.003H7.333a1.333 1.333 0 0 1-1.33-1.233l-.003-.1h4ZM8 1.333a4.666 4.666 0 0 1 4.664 4.507l.003.16v2.51l1.214 2.429a.733.733 0 0 1-.579 1.057l-.077.004H2.775a.734.734 0 0 1-.687-.991l.03-.07 1.215-2.43V6A4.667 4.667 0 0 1 8 1.333Zm0 1.334a3.333 3.333 0 0 0-3.33 3.188L4.667 6v2.51c0 .165-.031.329-.091.483l-.05.113-.78 1.56h8.509l-.78-1.56a1.333 1.333 0 0 1-.136-.473l-.006-.124V6A3.333 3.333 0 0 0 8 2.667Z"
      clipRule="evenodd"
    />
  </svg>
);
const Memo = memo(SvgComponent);
export default Memo;
