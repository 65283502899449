import styled from 'styled-components';
import { Modal } from 'react-bootstrap';

const Container = styled(Modal)`
  &.modal {
    --bs-modal-color: rgb(00, 00, 00);
  }

  .modal-content {
    padding: 15px;
    background: #1b1e26;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 16px;
  }

  .modal-header {
    border-bottom: 1px solid #353945;
    div {
      font-weight: 700 !important;
    }
  }
  .modal-title {
    color: white;
  }
`;

const Body = styled.div`
  margin-top: 12px;
`;

const Image = styled.img`
  width: 100%;
  margin-top: 24px;
  border-radius: 9px;
`;

export { Container, Body, Image };
