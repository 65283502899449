import { NETWORK_SUPPORTING } from '@/constants/network';
import { isProduction } from '@/utils/commons';
import { useWeb3React } from '@web3-react/core';
import { useMemo } from 'react';

export const GAS_LITMIT_TRUSTLESS_LAYER_1 = isProduction() ? 40000 : 40000;
export const GAS_LITMIT_TRUSTLESS_LAYER_2 = 0; // TO DO
export const GAS_LITMIT_ETHEREUM = 40000; // TO DO
export const GAS_LITMIT_NAKA = 40000; // TO DO

const useGasInfor = () => {
  const { account, provider } = useWeb3React();

  const getGasPrice = async (): Promise<number> => {
    const gasPrice = await provider?.getGasPrice();
    return gasPrice?.toNumber() || 0;
  };

  const gasPrice = useMemo(async () => {
    return await getGasPrice();
  }, [account, provider]);

  const getGasLimit = async (
    networkName: string = NETWORK_SUPPORTING.TRUSTLESS_LAYER1,
  ): Promise<number> => {
    switch (networkName) {
      case NETWORK_SUPPORTING.TRUSTLESS_LAYER1:
        return GAS_LITMIT_TRUSTLESS_LAYER_1;
      case NETWORK_SUPPORTING.TRUSTLESS_LAYER2:
        return GAS_LITMIT_TRUSTLESS_LAYER_2;
      case NETWORK_SUPPORTING.ETHEREUM:
        return GAS_LITMIT_ETHEREUM;
      case NETWORK_SUPPORTING.NAKA:
        return GAS_LITMIT_NAKA;
      default:
        return 40000;
    }
  };

  const gasLitmit = useMemo(async () => {
    return await getGasLimit();
  }, [account, provider]);

  return {
    getGasPrice,
    getGasLimit,

    gasPrice,
    gasLitmit,
  };
};

export default useGasInfor;
