// import { useAppDispatch } from '@/state/hooks';
import { formatAmountFactory } from '@/utils/format';
import { useWeb3React } from '@web3-react/core';
import BigNumber from 'bignumber.js';
import { throttle } from 'lodash';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Fields } from './FormBridge.constants';
import { FormBridgeType } from './FormBridge.types';
import {
  getFeeBurnNativeTokenSelector,
  getFormBridgeInfo,
  getFromInstanceSelector,
  getMaxBalanceSelector,
} from './state/selector';

import { useAppDispatch } from '@/state/hooks';
import { formBridgeActions } from './state/reducer';
import toast from 'react-hot-toast';
import { parseError } from '@/utils/errorHelper';
import useGasInfor from '@/hooks/useGasInfor';

const enhanceMax = (WrappedComponent: any) => (props: FormBridgeType) => {
  const dispatch = useAppDispatch();
  const formInstance = useSelector(getFromInstanceSelector);
  const maxBalance = useSelector(getMaxBalanceSelector);
  const { feeBurnNativeTokenFormated_Str } = useSelector(
    getFeeBurnNativeTokenSelector,
  );

  const { account: tcAddress } = useWeb3React();
  const { isNativeToken, isL1ToL2, isL2ToL1, fromTokenSelected, isBurnNativeToken } =
    useSelector(getFormBridgeInfo);

  const { getGasPrice, getGasLimit } = useGasInfor();

  const maxHandler = useCallback(
    throttle(
      async () => {
        try {
          if (formInstance) {
            if (!isNativeToken) {
              formInstance.change(Fields.AMOUNT, maxBalance);
            } else {
              if (
                (isL1ToL2 || isL2ToL1) &&
                fromTokenSelected?.symbol?.toUpperCase() === 'BVM'
              ) {
                const oldMaxBalance = maxBalance || '0';
                const gasPrice = await getGasPrice();
                const gasLitmit = await getGasLimit();

                const { priceFormatedStr: fee = '0.0' } = formatAmountFactory(
                  new BigNumber(gasPrice * gasLitmit).toFixed(),
                  18,
                );

                let balance;
                let errorMessage;

                let newMaxBalance = new BigNumber(maxBalance || '0').minus(fee);

                if (newMaxBalance.lte(new BigNumber(0))) {
                  balance = oldMaxBalance;
                  errorMessage =
                    'Your balance is insufficient to cover network fees';
                } else {
                  balance = newMaxBalance.toFixed() || oldMaxBalance;
                }

                // console.log('---  INFO MAX -- balance ', {
                //   balance,
                //   errorMessage,
                // });

                if (errorMessage) {
                  dispatch(formBridgeActions.setMaxErrorMessage(errorMessage));
                }
                formInstance.change(Fields.AMOUNT, balance);
              } else if (isBurnNativeToken) {
                const oldMaxBalance = maxBalance || '0';

                let balance;
                let errorMessage;
                let newMaxBalance = new BigNumber(maxBalance || '0').minus(
                  feeBurnNativeTokenFormated_Str,
                );

                if (newMaxBalance.lte(new BigNumber(0))) {
                  balance = oldMaxBalance;
                  errorMessage =
                    'Your balance is insufficient to cover network fees';
                } else {
                  balance = newMaxBalance.toFixed() || oldMaxBalance;
                }
                if (errorMessage) {
                  dispatch(formBridgeActions.setMaxErrorMessage(errorMessage));
                }
                formInstance.change(Fields.AMOUNT, balance);
              } else {
                formInstance.change(Fields.AMOUNT, maxBalance);
              }
            }
          }
        } catch (error) {
          console.log('[maxHandler] ERROR: ', error);
          toast.error(parseError(error));
        }
      },
      1000,
      {
        trailing: false,
        leading: true,
      },
    ),
    [
      formInstance,
      maxBalance,
      getGasPrice,
      getGasLimit,
      isNativeToken,
      tcAddress,
      isL1ToL2,
      isL2ToL1,
      fromTokenSelected,
      feeBurnNativeTokenFormated_Str,
    ],
  );

  return <WrappedComponent {...{ ...props, maxHandler }} />;
};

export default enhanceMax;
