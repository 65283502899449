import styled from 'styled-components';

export const Input2 = styled.input`
  width: 100%;

  outline: none;
  overflow: hidden;
  text-overflow: ellipsis;

  font-weight: 600;
  font-size: 18px;
  line-height: 16px;

  padding: 16px 12px;

  color: white;

  border-radius: 8px;

  caret-color: white;
  background: black;
  background: ${({ theme }) => theme.colors.black};
  border: 1px solid ${({ theme }) => theme.colors.border4};
  border-radius: 8px;

  /* background-color: red; */

  :hover {
  }

  :disabled {
    opacity: 1;
    color: white;
    ::placeholder {
      opacity: 1;
      color: white;
    }
  }

  :focus {
  }

  ::placeholder {
    color: grey;
  }

  ::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
`;
