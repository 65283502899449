import BellIcon from '@/components/IconSVG/BellIcon';
import styled from 'styled-components';

const Container = styled.div`
  margin-top: 5px;
  flex: 1;
  display: flex;
  flex-direction: row;
  padding: 4px 12px 4px 4px;
  box-sizing: border-box;
  border: 1px solid rgba(255, 126, 33, 0.3);
  border-radius: 1000px;

  .warning-text {
    margin-left: 8px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #ff7e21;
    font-family: 'TitilliumWeb-SemiBold';
  }
`;

const WarningMessage = () => {
  return (
    <Container>
      <BellIcon />
      <p className="warning-text">
        {'Any deposits that are less than the minimum deposit will be lost.'}
      </p>
    </Container>
  );
};

export default WarningMessage;
