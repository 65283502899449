import { Image } from '@/components/Image';
import Text from '@/components/Text';
import { Dropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { formBridgeActions } from '../state/reducer';
import { getFormBridgeInfo, getNetworksSelectable } from '../state/selector';
import DropDownIcon from '@/components/IconSVG/DropDownIcon';
import { useEffect, useMemo } from 'react';
import { getNetworkByNameSelector } from '@/state/network/selector';
import { NetworkName } from '@/state/network/types';
import { NETWORK_SUPPORTING } from '@/constants/network';

const Container = styled.div<{ disabled: boolean }>`
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  opacity: ${({ disabled }) => (disabled ? 1 : 1)};
  :disabled {
    color: white;
    opacity: 1;
  }
`;

const DropdownStyled = styled(Dropdown)`
  &&& {
    display: flex;
    align-items: center;
    margin-top: 5px;
  }
`;

const DropdownToggleStyled = styled(Dropdown.Toggle)<{ isempty: number }>`
  &&& {
    display: flex;
    align-items: center;
    flex-direction: row;

    margin: 0;
    padding: 0;
    flex: 1;
    min-height: 45px;

    background: ${({ theme, isempty }) =>
      isempty ? theme.colors.bg1 : theme.colors.black};
    border: 1px solid ${({ theme }) => theme.colors.tranpsarent};

    :hover {
      cursor: pointer;
      background: ${({ theme }) => theme.colors.bg1};
    }

    ::after {
      color: ${({ theme }) => theme.colors.tranpsarent};
    }
  }
`;

const DropdownMenuStyled = styled(Dropdown.Menu)`
  &&& {
    flex: 1;
    padding: 0;
    margin: 0;
    width: 100%;
    background: ${({ theme }) => theme.colors.bg1};
    border: 1px solid ${({ theme }) => theme.colors.border1};
    border-radius: 8px;
  }
`;

const DropdownItemStyled = styled(Dropdown.Item)`
  &&& {
    all: unset;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 12px;
`;

const RowSpaceBetween = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const SelectNetworkItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  padding: 5px 12px;
  min-height: 55px;
  min-width: 150px;

  :hover {
    cursor: pointer;
    color: white;
  }

  .enhanceText {
    text-wrap: balance;
    text-align: left;
  }
`;

const ToNetworkSelect = () => {
  const dispatch = useDispatch();
  const {
    disableToNetworkSelect,
    toNetworkSelected,
    fromNetworkSelected,
    fromTokenSelected,
  } = useSelector(getFormBridgeInfo);
  const getNetworkObjectByName = useSelector(getNetworkByNameSelector);
  let { toNetworkList } = useSelector(getNetworksSelectable);

  toNetworkList = useMemo(() => {
    if (
      fromNetworkSelected === NETWORK_SUPPORTING.TRUSTLESS_LAYER1 &&
      fromTokenSelected?.symbol?.toUpperCase() === 'BVM'
    ) {
      return (
        toNetworkList.filter((network) => network?.toUpperCase() !== 'ORDINALS') ||
        []
      );
    } else {
      return toNetworkList;
    }
  }, [toNetworkList, fromNetworkSelected, fromTokenSelected]);

  useEffect(() => {
    if (toNetworkList.includes(toNetworkSelected)) {
      dispatch(formBridgeActions.setToNetwork(toNetworkSelected));
    } else {
      dispatch(formBridgeActions.setToNetwork(toNetworkList[0]));
    }
  }, [toNetworkList, toNetworkSelected]);

  const itemOnClick = (network: NetworkName) => {
    if (network !== toNetworkSelected) {
      dispatch(formBridgeActions.setToNetwork(network));
    }
  };
  const renderItem = (network: NetworkName) => {
    const networkObj = getNetworkObjectByName(network);
    return (
      <SelectNetworkItem key={network} onClick={() => itemOnClick(network)}>
        <Row>
          <Image
            iconUrl={networkObj?.networkLogo || ''}
            size={30}
            alt={`${toNetworkSelected}`}
            style={{ marginRight: 7, width: 30, height: 30 }}
          />
          <Text
            fontSize={18}
            className="enhanceText"
            style={{
              textTransform: 'capitalize',
            }}
          >
            {networkObj?.networkTitle || ''}
          </Text>
        </Row>
      </SelectNetworkItem>
    );
  };

  const isEmptyNetwork = !toNetworkSelected;
  return (
    <Container disabled={disableToNetworkSelect}>
      <DropdownStyled>
        <DropdownToggleStyled isempty={Number(isEmptyNetwork)}>
          <RowSpaceBetween>
            {!toNetworkSelected ? (
              <Text fontSize={18}>{'Select Network'}</Text>
            ) : (
              <Row>{renderItem(toNetworkSelected)}</Row>
            )}
            <DropDownIcon />
          </RowSpaceBetween>
        </DropdownToggleStyled>
        {toNetworkList && toNetworkList.length > 0 && (
          <DropdownMenuStyled>
            {toNetworkList.map((network: NetworkName) => {
              return (
                <DropdownItemStyled key={network + 'TO_NETWORK'}>
                  {renderItem(network)}
                </DropdownItemStyled>
              );
            })}
          </DropdownMenuStyled>
        )}
      </DropdownStyled>
    </Container>
  );
};

export default ToNetworkSelect;
