import { ethers } from 'ethers';
import * as optimismSDK from '@eth-optimism/sdk';
import { SupportedChainId } from '@constants/chains';
import React from 'react';
import { useWeb3React } from '@web3-react/core';
import { AddressZero } from '@ethersproject/constants';
import { INetwork } from '@state/network/types';

export interface ICrossChainMessenger {
  addressManager: string;
  l1CrossDomainMessenger: string;
  l1StandardBridge: string;
  optimismPortal: string;
  l2OutputOracle: string;
  l2ChainId: number;
  l2Rpc: string;
}

export const getCrossChainMessengerPayloadFromNetwork = (payload: INetwork) => {
  return {
    addressManager: payload.addressManagerContractAddress,
    l1CrossDomainMessenger: payload.crossDomainMessengerContractAddress,
    l1StandardBridge: payload.bridgeContractAddress,
    optimismPortal: payload.portalContractAddress,
    l2OutputOracle: payload.oracleContractAddress,
    l2ChainId: Number(payload.chainId),
    l2Rpc: payload.rpcEndpoint,
  };
};

const useCrossChainMessenger = () => {
  const { account, provider, chainId } = useWeb3React();

  return React.useCallback(
    (payload: ICrossChainMessenger) => {
      const {
        addressManager,
        l1CrossDomainMessenger,
        l1StandardBridge,
        optimismPortal,
        l2OutputOracle,
        l2ChainId,
        l2Rpc,
      } = payload;
      if (!provider || !account || !l2Rpc) {
        throw new Error('Invalid data crossChainMessenger.');
      }
      const l1Contracts = {
        StateCommitmentChain: AddressZero,
        CanonicalTransactionChain: AddressZero,
        BondManager: AddressZero,
        AddressManager: addressManager, // Lib_AddressManager.json
        L1CrossDomainMessenger: l1CrossDomainMessenger, // Proxy__OVM_L1CrossDomainMessenger.json
        L1StandardBridge: l1StandardBridge, // Proxy__OVM_L1StandardBridge.json bridgeContractAddress
        OptimismPortal: optimismPortal, // OptimismPortalProxy.json  portalContractAddress
        L2OutputOracle: l2OutputOracle, // L2OutputOracleProxy.json oracleContractAddress
      };

      const l2Provider = new ethers.providers.JsonRpcProvider(l2Rpc);

      const crossChainMessenger = new optimismSDK.CrossChainMessenger({
        l1ChainId: SupportedChainId.TRUSTLESS_COMPUTER,
        l2ChainId: l2ChainId,
        l1SignerOrProvider: provider.getSigner(),
        l2SignerOrProvider: l2Provider.getSigner(account),
        bedrock: true,
        contracts: {
          l1: l1Contracts,
        },
      });
      return crossChainMessenger;
    },
    [account, provider, chainId],
  );
};

export default useCrossChainMessenger;
