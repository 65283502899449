import { setLoading } from '@/state/loading/reducer';
import { useDispatch } from 'react-redux';

const useLoading = () => {
  const dispatch = useDispatch();
  const showLoading = (flag: boolean, message?: string) => {
    dispatch(
      setLoading({
        flag,
        message,
      }),
    );
  };

  return {
    showLoading,
  };
};

export default useLoading;
