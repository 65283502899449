import withRotate from '@/hocs/withRotate';
import withWeb3Connected from '@/hocs/withWeb3Connected';
import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 28px;
  display: flex;
  flex-direction: column;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const RowTextInput = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  background: ${({ theme }) => theme.colors.black};
  border: 1px solid ${({ theme }) => theme.colors.border4};
  border-radius: 8px;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const WrapperContent = styled.div`
  //width: 50%;
  min-width: 700px;
  display: flex;
  flex-direction: column;
  padding: 20px 40px 40px 40px;

  background: ${({ theme }) => theme.colors.bg1};
  border: 1px solid ${({ theme }) => theme.colors.border4};
  border-radius: 16px;
  align-self: center;

  @media screen and (max-width: 1920px) {
    width: 40%;
  }
  @media screen and (max-width: 1024px) {
    width: 70%;
    padding: 14px 24px 24px 24px;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
    min-width: 90%;
  }
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;

  padding: 5px 0px;

  /* background: ${({ theme }) => theme.colors.black};
  border: 1px solid ${({ theme }) => theme.colors.border4};
  border-radius: 8px; */
`;

export const Box2 = styled.div`
  display: flex;
  flex-direction: row;

  background: ${({ theme }) => theme.colors.black};
  border: 1px solid ${({ theme }) => theme.colors.border4};
  border-radius: 8px;
`;

export const NetworkBox = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  flex: 1;
  min-height: 65px;
  /* background-color: red; */
`;

export const DividerBox = styled.div`
  display: flex;
  width: 1px;
  background-color: ${({ theme }) => theme.colors.border4};
`;

export const TokenBox = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  flex: 1;
  min-height: 65px;
  /* background-color: blue; */
`;

export const SubmitButtonStyled = styled.button`
  margin-top: 30px;
  width: 100%;
  height: 50px;
  align-self: center;

  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 16px;
  text-align: center;
  color: #fcfcfd;

  background: #3772ff;
  border-radius: 100px !important;
  display: flex;
  justify-content: center;
  align-items: center;

  :disabled {
    opacity: 0.5;
    :hover {
      opacity: 0.5;
    }
  }

  :hover {
    opacity: 0.8;
  }

  :active {
    transform: scale(1.01);
  }
`;

export const SubmitButton = withWeb3Connected(SubmitButtonStyled);

export const BTCSubmitBtnStyled = styled.button`
  margin-top: 30px;
  width: 100%;
  height: 50px;
  align-self: center;

  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 16px;
  text-align: center;
  color: #fcfcfd;

  background: #3772ff;
  border-radius: 100px !important;

  :disabled {
    opacity: 0.5;
    :hover {
      opacity: 0.5;
    }
  }

  :hover {
    opacity: 0.8;
  }

  :active {
    transform: scale(1.01);
  }
`;

export const ErrorText = styled.p`
  margin-top: 5px;
  font-family: 'TitilliumWeb-SemiBold';
  color: #ff5747;
`;

export const WrapperArrowDownCircleStyled = styled.div`
  align-items: center;
  justify-content: center;
  align-self: center;
`;

export const WrapperArrowDownCircle = withRotate(WrapperArrowDownCircleStyled);

export const Title1 = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  text-align: left;
  color: #ffffff;
`;

export const Title2 = styled.p`
  font-weight: 500;
  font-size: 15px;
  line-height: 150%;
  text-align: left;
  color: #cecece;
`;
