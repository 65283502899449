import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { getFormBridgeInfo, getFromBalanceSelector } from '../state/selector';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;

  .content {
    display: flex;
    flex-direction: row;
    align-items: left;
    border-radius: 100px;
  }
`;

const Text = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  text-transform: capitalize;
  color: #ffffff;
`;

const FromBalanceBox = () => {
  let balance;
  const {
    fromBalanceFormatedStr: depositBalance,
    fromTokenSelected,
    isSwitchNetwork,
    formType,
  } = useSelector(getFormBridgeInfo);

  const { fromBalanceFormatedStr: withdrawBalance } = useSelector(
    getFromBalanceSelector,
  );

  if (!fromTokenSelected || isSwitchNetwork) return null;

  if (formType === 'Deposit') {
    balance = depositBalance;
  }
  if (formType === 'Withdraw') {
    balance = withdrawBalance;
  }

  return (
    <Container>
      <div className="content">
        <Text>{`Balance: ${balance} ${fromTokenSelected.symbol?.toUpperCase()}`}</Text>
      </div>
    </Container>
  );
};

export default FromBalanceBox;
