import { Fields } from './FormBridge.constants';
import { FormBridgeType } from './FormBridge.types';
import { getFromInstanceSelector } from './state/selector';
import { useSelector } from 'react-redux';

const enhanceResetForm = (WrappedComponent: any) => (props: FormBridgeType) => {
  const formInstance = useSelector(getFromInstanceSelector);
  const resetForm = () => {
    if (formInstance && formInstance.reset) {
      formInstance.reset();
      formInstance.resetFieldState(Fields.AMOUNT);
      formInstance.resetFieldState(Fields.ADDRESS);
    }
  };
  return <WrappedComponent {...{ ...props, resetForm }} />;
};
export default enhanceResetForm;
