import {
  getReceiverTokenAddress,
  isDeposit,
} from '@/containers/FormBridge/FormBridge.utils';
import { parseError } from '@/utils/errorHelper';
import { throttle } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { fetchGenerateDepositData } from './state/actions';
import { getFormBridgeInfo } from './state/selector';

import { useAppDispatch } from '@/state/hooks';
import { logger } from '@/utils/logger';
import DepositInfoModal from '../BTCForm/DepositInfoModal';
import { formBridgeActions } from './state/reducer';
import useLoading from '@/hooks/useLoading';
import useThrottle from '@/hooks/useThrottle';

const enhanceGenerateDeposit = (WrappedComponent: any) => (props: any) => {
  const dispatch = useAppDispatch();
  const { showLoading } = useLoading();

  // const formInstance = useSelector(getFromInstanceSelector);

  const [depositInfoModalShow, setDepositInfoModalShow] = useState(false);

  const { formType, fromNetworkSelected, fromTokenSelected, toNetworkSelected } =
    useSelector(getFormBridgeInfo);

  useEffect(() => {
    if (isDeposit(fromNetworkSelected)) {
      dispatch(formBridgeActions.clearGenerateDepositData());
    }
  }, [fromTokenSelected]);

  const tcTokenID = React.useMemo(() => {
    return getReceiverTokenAddress({
      network: toNetworkSelected,
      token: fromTokenSelected,
      formType,
    });
  }, [fromTokenSelected, toNetworkSelected, formType]);

  const generateDepositDataHandler = useCallback(
    useThrottle(async (data) => {
      const tcAddress = data.address;
      if (
        !fromNetworkSelected ||
        !tcTokenID ||
        !tcAddress ||
        !isDeposit(fromNetworkSelected)
      ) {
        logger('[generateDepositData] INVALID DATA: ', {
          fromNetworkSelected,
          tcTokenID,
          tcAddress,
          isDepositCentralized: isDeposit(fromNetworkSelected),
        });
        return;
      }

      // Call Generate Data Deposit from API
      try {
        showLoading(true);
        const result: any = await dispatch(
          fetchGenerateDepositData({
            tcAddress: tcAddress,
            tcTokenID,
            network: toNetworkSelected,
          }),
        );
        if (result) {
          if (!result.error) {
            setDepositInfoModalShow(true);
          } else {
            throw result.error;
          }
        }
      } catch (error) {
        toast.error(parseError(error));
        setDepositInfoModalShow(false);
      } finally {
        showLoading(false);
      }
    }, 1500),
    [tcTokenID, fromNetworkSelected, formType, toNetworkSelected],
  );

  return (
    <>
      <WrappedComponent {...{ ...props, generateDepositDataHandler }} />
      <DepositInfoModal
        isShow={depositInfoModalShow}
        onHide={() => {
          setDepositInfoModalShow(false);
        }}
      />
    </>
  );
};
export default enhanceGenerateDeposit;
