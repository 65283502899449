import * as React from 'react';
import { SVGProps, memo } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={40}
    height={40}
    fill="none"
    {...props}
  >
    <rect width={40} height={40} fill="#23262F" rx={8} />
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeWidth={1.5}
      d="M15 22h-2a1 1 0 0 1-1-1v-7a2 2 0 0 1 2-2h7a1 1 0 0 1 1 1v2m-2 13h6a2 2 0 0 0 2-2v-6a2 2 0 0 0-2-2h-6a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2Z"
    />
  </svg>
);
const Memo = memo(SvgComponent);
export default Memo;
