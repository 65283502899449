import Text from '@/components/Text';
import Skeleton from 'react-loading-skeleton';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { getFeeBurnNativeTokenSelector, getFormBridgeInfo } from '../state/selector';

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const GasPriceEst = () => {
  const { isBurnNativeToken, isLoading, tokenSymbol, isCorrectChain } =
    useSelector(getFormBridgeInfo);
  const { feeBurnNativeTokenFormated_Str } = useSelector(
    getFeeBurnNativeTokenSelector,
  );
  if (isLoading) return <Skeleton count={1} />;
  if (!isCorrectChain) return null;
  if (isBurnNativeToken)
    return (
      <Container>
        <Text>{'Gas (est.)'}</Text>
        <Text>{`${feeBurnNativeTokenFormated_Str} ${tokenSymbol}`}</Text>
      </Container>
    );
  return null;
};

export default GasPriceEst;
