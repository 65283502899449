// import BTCIcon from '@/components/IconSVG/BTCIcon';
// import Text from '@/components/Text';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import {
  getEstimateWithdrawSelector,
  getFormBridgeInfo,
  getGenerateDepositSelector,
} from '../FormBridge/state/selector';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 10px;

  .warning-text {
    color: orange;
  }

  .white-text {
    color: white;
  }

  .warning-text-bold {
    color: orange;
    font-weight: 900;
    font-family: 'TitilliumWeb-Semibold';
  }
`;

const Text = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
`;
type Props = {
  flowWithdraw?: boolean;
};

const DescriptionQrCode = (props: Props) => {
  const { flowWithdraw = false } = props;
  const { fromTokenSelected, isNativeToken } = useSelector(getFormBridgeInfo);
  const { isLoading, data: depositData } = useSelector(getGenerateDepositSelector);
  const { data: estimateData } = useSelector(getEstimateWithdrawSelector);
  const needBTCBalanceMoreWithdrawStr = estimateData?.needBTCBalanceMoreStr || '';

  if (!depositData) return null;

  const { isNeedBTCBalanceMore, needBTCBalanceMoreStr } = depositData;

  const tokenSymbol = `${fromTokenSelected?.symbol?.toUpperCase()}`;

  const renderFlowWithdraw = () => {
    return (
      <>
        <Text className="warning-text">
          {`Please top up at least`}{' '}
          <Text className="warning-text-bold">{` ${needBTCBalanceMoreWithdrawStr} BTC`}</Text>
        </Text>
      </>
    );
  };

  const renderFlowDeposit = () => {
    // if (isNativeToken)
    //   return (
    //     <Text className="white-text">{`Please send ${tokenSymbol} to the following address.`}</Text>
    //   );

    // return (
    //   <>
    //     {isNeedBTCBalanceMore && (
    //       <>
    //         <Text className="warning-text">
    //           {`Please send ${
    //             fromTokenSelected?.symbol?.toUpperCase() || ''
    //           } and top up at least`}{' '}
    //           <Text className="warning-text-bold">{` ${needBTCBalanceMoreStr} BTC`}</Text>
    //           <Text className="warning-text">{` or more to the following address to cover network fees.`}</Text>
    //         </Text>
    //       </>
    //     )}
    //     {!isNeedBTCBalanceMore && (
    //       <Text className="white-text">{`Please send ${tokenSymbol} to the following address.`}</Text>
    //     )}
    //   </>
    // );

    return (
      <Text className="white-text">{`Please send ${tokenSymbol} to the following address.`}</Text>
    );
  };

  return (
    <Container>
      {flowWithdraw ? renderFlowWithdraw() : renderFlowDeposit()}
    </Container>
  );
};

export default DescriptionQrCode;
