import { ContractOperationHook, DAppType } from '@/interfaces/contract-operation';
import { useWeb3React } from '@web3-react/core';
import { useCallback } from 'react';
import { TransactionEventType } from '@/enums/transaction';
import { getContract } from '@/utils';
import ERC20ABIJson from '@/abis/erc20.json';

export interface ITokenBalanceParams {
  tokenAddress: string;
}

const useGetTokenBalance: ContractOperationHook<
  ITokenBalanceParams,
  string
> = () => {
  const { account, provider } = useWeb3React();
  const call = useCallback(
    async (params: ITokenBalanceParams): Promise<string> => {
      if (account && provider) {
        const { tokenAddress } = params;
        const contract = getContract(
          tokenAddress,
          ERC20ABIJson.abi,
          provider,
          account,
        );
        const balance = await contract.connect(provider).balanceOf(account);
        return balance.toString();
      }
      return '0';
    },
    [account, provider],
  );

  return {
    call: call,
    dAppType: DAppType.Contract,
    transactionType: TransactionEventType.DEPLOY,
  };
};

export default useGetTokenBalance;
