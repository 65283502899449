import { ContractOperationHook, DAppType } from '@/interfaces/contract-operation';
import { useWeb3React } from '@web3-react/core';
import { useCallback } from 'react';
import { TransactionEventType } from '@/enums/transaction';
import { getContract } from '@/utils';
import ERC20ABIJson from '@/abis/erc20.json';

export interface IApproveTokenParams {
  bridgeAddress: string;
  tokenAddress: string;
  approveAmount?: string;
}

const APPROVE_MAX =
  '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff';

const useApproveToken: ContractOperationHook<
  IApproveTokenParams,
  any | undefined
> = () => {
  const { account, provider } = useWeb3React();
  const call = useCallback(
    async (params: IApproveTokenParams): Promise<any | undefined> => {
      if (account && provider) {
        const { tokenAddress, approveAmount, bridgeAddress } = params;
        const contract = getContract(
          tokenAddress,
          ERC20ABIJson.abi,
          provider,
          account,
        );
        const tx = await contract
          .connect(provider.getSigner())
          .approve(bridgeAddress, approveAmount ? approveAmount : APPROVE_MAX);
        if (tx) {
          return {
            hash: Object(tx).hash,
          };
        }
      }
      return undefined;
    },
    [account, provider],
  );

  return {
    call: call,
    dAppType: DAppType.Contract,
    transactionType: TransactionEventType.DEPLOY,
  };
};

export default useApproveToken;
