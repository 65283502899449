import { SupportedChainId } from '@/constants/chains';
import useApproveToken, {
  IApproveTokenParams,
} from '@/hooks/contract-operations/useApproveToken';
import useBurnETHNativeToLayer2, {
  IBurnETHNativeToLayer2Params,
} from '@/hooks/contract-operations/useBurnETHNativeToLayer2';
import useBurnTokenLayer2, {
  IBurnTokenLayer2Params,
} from '@/hooks/contract-operations/useBurnLayer2';
import useContractOperation from '@/hooks/contract-operations/useContractOperation';
import useDepositERC20, {
  IDepositERC20Params,
} from '@/hooks/contract-operations/useDepositERC20';
import useDepositETH, {
  IDepositETHParams,
} from '@/hooks/contract-operations/useDepositETH';
import useLoading from '@/hooks/useLoading';
import convertUtils from '@/utils/convert';
import { parseError } from '@/utils/errorHelper';
import { logger } from '@/utils/logger';
import { debounce } from 'lodash';
import React from 'react';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { Fields } from './FormBridge.constants';
import { checkAllowanceAmount, getBridgeAddress } from './FormBridge.utils';
import { getFormBridgeInfo } from './state/selector';
import { isLayer2 } from '@/constants/network';
import { isProduction } from '@/utils/commons';

const enhanceDeposit = (WrappedComponent: any) => (props: any) => {
  const { resetForm, setDepositProcessingModalData } = props;
  const {
    fromTokenSelected,
    isNativeToken,
    allowanceAmount,
    toNetworkSelected,
    toNetworkObject,
  } = useSelector(getFormBridgeInfo);
  const { decimals: tokenDecimals } = fromTokenSelected ?? { decimals: 18 };

  const chainID = isProduction() ? 1 : 11155111;

  const bridgeAddress = React.useMemo(() => {
    return getBridgeAddress({
      network: toNetworkSelected,
      token: fromTokenSelected,
    });
  }, [toNetworkSelected, fromTokenSelected]);

  const tokenAddress = React.useMemo(() => {
    return fromTokenSelected?.tcTokenID;
  }, [fromTokenSelected?.tcTokenID]);

  const { showLoading } = useLoading();

  const { run: approveToken } = useContractOperation<IApproveTokenParams, string>({
    operation: useApproveToken,
    inscribeable: false,
    chainId: chainID,
    isCheckChainID: true,
  });

  const { run: depositETH } = useContractOperation<IDepositETHParams, string>({
    operation: useDepositETH,
    inscribeable: false,
    chainId: chainID,
    isCheckChainID: true,
  });

  const { run: depositNativeETHLayer2 } = useContractOperation<
    IBurnETHNativeToLayer2Params,
    string
  >({
    operation: useBurnETHNativeToLayer2,
    inscribeable: false,
    chainId: chainID,
    isCheckChainID: true,
  });

  const { run: depositERC20 } = useContractOperation<IDepositERC20Params, string>({
    operation: useDepositERC20,
    inscribeable: false,
    chainId: chainID,
    isCheckChainID: true,
  });

  const { run: depositLayer2 } = useContractOperation<
    IBurnTokenLayer2Params,
    string
  >({
    operation: useBurnTokenLayer2,
    inscribeable: false,
    chainId: chainID,
    isCheckChainID: true,
  });

  const onApproveTokenHandler = debounce(async () => {
    logger('[onApproveTokenHandler] payload ', {
      bridgeAddress: bridgeAddress,
      tokenAddress: tokenAddress,
    });
    if (!fromTokenSelected || !bridgeAddress || !tokenAddress) {
      return;
    }
    try {
      const result = await approveToken({
        bridgeAddress: bridgeAddress,
        tokenAddress: tokenAddress,
      });
      logger('[onApproveTokenHandler] result ', result);
    } catch (error: any) {
      logger('[onApproveTokenHandler] error ', error);
      toast.error(parseError(error));
    } finally {
      showLoading(false);
    }
  }, 100);

  const onDepositERC20 = async ({
    amount,
    receiverAddress,
  }: {
    amount: string;
    receiverAddress: string;
  }) => {
    try {
      if (
        !fromTokenSelected ||
        !tokenAddress ||
        !amount ||
        !receiverAddress ||
        !bridgeAddress
      ) {
        return;
      }

      const isNeedApproveMoreAmount = await checkAllowanceAmount({
        amount,
        allowanceAmount,
        decimals: tokenDecimals,
      });
      if (isNeedApproveMoreAmount) {
        await onApproveTokenHandler();
        return;
      }

      const amountStr = convertUtils.toBigNumberNonRound(amount, tokenDecimals);
      const chainID = toNetworkObject?.chainId;
      if (!chainID || chainID == -1) {
        throw 'ChainID Invalid';
      }

      const payload = {
        bridgeAddress: bridgeAddress,
        amount: amountStr,
        receiver: receiverAddress,
        tokenAddress: tokenAddress,
        chainID: chainID,
      };

      let depositResult;

      if (isLayer2(toNetworkSelected)) {
        const layer2Payload = {
          tokenAddress: payload.tokenAddress,
          bridgeAddress: payload.bridgeAddress,
          burnAmount: payload.amount,
          receiver: payload.receiver,
          chainID: payload.chainID,
        };
        logger('[onDepositERC20] Layer 2 payload ', layer2Payload);
        depositResult = await depositLayer2(layer2Payload);
      } else {
        logger('[onDepositERC20] Layer 1 payload ', payload);
        depositResult = await depositERC20(payload);
      }

      return depositResult;
    } catch (error: any) {
      toast.error(parseError(error));
    } finally {
      showLoading(false);
    }
  };

  const onDepositNativeETH = async ({
    amount,
    receiverAddress,
  }: {
    amount: string;
    receiverAddress: string;
  }) => {
    if (!fromTokenSelected || !bridgeAddress) return;

    const { decimals } = fromTokenSelected;

    try {
      const amountStr = convertUtils.toBigNumberNonRound(amount, decimals);
      const chainID = toNetworkObject?.chainId;

      if (!chainID || chainID == -1) {
        throw 'ChainID Invalid';
      }

      const payload = {
        bridgeAddress: bridgeAddress,
        amount: amountStr,
        receiver: receiverAddress,
        chainID: chainID,
      };

      let result;

      if (isLayer2(toNetworkSelected)) {
        const layer2Payload = {
          bridgeAddress: payload.bridgeAddress,
          amount: payload.amount,
          receiver: payload.receiver,
          chainID: payload.chainID,
        };
        logger('[onDepositNativeETH] Layer 2 payload ', layer2Payload);
        result = await depositNativeETHLayer2(layer2Payload);
      } else {
        logger('[onDepositNativeETH] Layer 1 payload ', payload);
        result = await depositETH(payload);
      }
      return result;
    } catch (error: any) {
      logger('[onDepositNativeETH] error ', error);
      toast.error(parseError(error));
    } finally {
      showLoading(false);
    }
  };

  const onDepositHandler = async (data: any) => {
    console.log('[enhanceDeposit][onDepositHandler] data ', data);
    try {
      if (!data) return;

      showLoading(true);
      const amount = data[Fields.AMOUNT];
      const receiverAddress = data[Fields.ADDRESS];

      if (!fromTokenSelected || !amount || !receiverAddress) return;

      let depositResult;
      const payload = {
        amount,
        receiverAddress,
      };

      if (isNativeToken) {
        depositResult = await onDepositNativeETH(payload);
      } else {
        depositResult = await onDepositERC20(payload);
      }
      if (depositResult) {
        // const txHash = depositResult.hash as any;
        // toast.success('Deposit Processing');

        const txHash = (depositResult as any).hash! as any;

        setDepositProcessingModalData({
          isShow: true,
          amount: amount,
          receiverAddress: receiverAddress,
          txHash: txHash,
        });

        resetForm && resetForm();
      }
    } catch (error: any) {
      console.log('[enhanceDeposit][onDepositHandler] ERROR: ', error);
      toast.error(parseError(error));
    }
  };
  return <WrappedComponent {...{ ...props, onDepositHandler }} />;
};
export default enhanceDeposit;
