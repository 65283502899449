import Text from '@/components/Text';
import { memo } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { getFormBridgeInfo, getEstimateWithdrawSelector } from '../state/selector';
import { NETWORK_SUPPORTING } from '@constants/network';

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const WithdrawFeeEstimate = () => {
  const { isLoading, data } = useSelector(getEstimateWithdrawSelector);
  const { fromTokenSelected } = useSelector(getFormBridgeInfo);
  const withdrawFeeHRFormatStr = data?.withdrawFeeHRFormatStr;
  const isBlockToken = data?.isBlockToken;

  if (isLoading) return <Skeleton count={1} />;
  if (!withdrawFeeHRFormatStr || !fromTokenSelected) return null;
  if (isBlockToken) return null;

  const tokenSymbol = fromTokenSelected?.symbol?.toUpperCase();

  // const symbol =
  //   toNetworkSelected === NETWORK_SUPPORTING.BITCOIN ||
  //   toNetworkSelected === NETWORK_SUPPORTING.ORDINALS
  //     ? tokenSymbol === 'BVM'
  //       ? 'BVM'
  //       : 'BTC'
  //     : `${(fromTokenSelected?.symbol || 'Unknow').toUpperCase()}`;

  return (
    <Container>
      <Text>{'Transfer fee (est.)'}</Text>
      <Text>{`${withdrawFeeHRFormatStr} ${tokenSymbol}`}</Text>
    </Container>
  );
};

export default memo(WithdrawFeeEstimate);
