import styled from 'styled-components';

export const Container = styled.div`
  padding: 24px 16px !important;

  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;

  justify-content: space-between;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);

  .time-item {
  }

  .fee-item {
  }

  .status-item {
  }

  .button-withdraw {
    background-color: #4f42e2;
    width: 100%;
    color: white;
    margin-top: 6px;
    padding: 3px;
    border-radius: 4px;
    font-weight: 700 !important;
  }

  .button-inscribe {
    background-color: #4f42e2;
    width: 80px;
    color: white;
    margin-top: 6px;
    padding: 3px;
    border-radius: 4px;
    font-weight: 700 !important;
  }

  @media screen and (max-width: 1024px) {
    .time-item {
      display: none;
    }
  }

  @media screen and (max-width: 768px) {
    .fee-item {
      display: none;
    }
  }

  @media screen and (max-width: 640px) {
    .status-item {
      display: none;
    }
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const WrapTitle = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  height: fit-content;
`;

export const Title = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  overflow: hidden;

  word-wrap: break-word;
  text-overflow: ellipsis;

  opacity: 1;
`;

export const WrapCopyIcon = styled.div`
  margin-left: 1px;
  scale: 0.8;
  :hover {
    cursor: pointer;
    opacity: 0.8;
  }
`;

export const Link = styled.a`
  margin-left: 5px;
  color: rgb(177, 227, 255);

  :hover {
    cursor: pointer;
    color: rgb(177, 227, 255);
    opacity: 1;
  }
`;
