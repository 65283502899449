import MaintenancePage from '@/containers/Maintenance';
import { getEnableFlagAPI } from '@/services/config';
import React, { FC, useEffect, useState } from 'react';

const TIME_INTERVAL_FEATURE_FLAG = 5000; //5s

const withMaintenance = (WrappedComponent: FC) => (props: any) => {
  const [isEnable, setEnable] = useState(false);
  const timerRef = React.useRef<any>();
  const firstTime = React.useRef<boolean>(true);

  const getEnableFlag = async () => {
    try {
      const result = await getEnableFlagAPI();
      setEnable(result);
    } catch (error) {
    } finally {
      firstTime.current = false;
    }
  };

  useEffect(() => {
    getEnableFlag();
    if (!timerRef.current) {
      timerRef.current = setInterval(() => {
        getEnableFlag();
      }, TIME_INTERVAL_FEATURE_FLAG);
    }
    return () => {
      clearInterval(timerRef.current);
      timerRef.current = undefined;
    };
  }, []);

  if (firstTime.current) return null;
  if (!isEnable) return <MaintenancePage />;

  return <WrappedComponent {...props} />;
};

export default withMaintenance;
