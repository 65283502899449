import { ContractOperationHook, DAppType } from '@/interfaces/contract-operation';
import { useWeb3React } from '@web3-react/core';
import { useCallback } from 'react';
import { TransactionEventType } from '@/enums/transaction';
import { getContract } from '@/utils';
import DepositJson from '@/abis/deposit.json';
import { ethers } from 'ethers';

export interface IDepositERC20Params {
  bridgeAddress: string;
  tokenAddress: string;
  amount: string; // original amount // 1000000000000000
  receiver: string;
}

const useDepositERC20: ContractOperationHook<
  IDepositERC20Params,
  any | undefined
> = () => {
  const { account, provider } = useWeb3React();
  const call = useCallback(
    async (params: IDepositERC20Params): Promise<any | undefined> => {
      if (account && provider) {
        const { bridgeAddress, amount, tokenAddress, receiver } = params;
        const contract = getContract(
          bridgeAddress,
          DepositJson,
          provider,
          account,
        ).connect(provider.getSigner());
        const tx = await contract['deposit(address,uint256,address)'](
          tokenAddress,
          ethers.BigNumber.from(amount),
          receiver,
        );
        if (tx) {
          return {
            hash: Object(tx).hash,
          };
        }
      }
      return undefined;
    },
    [account, provider],
  );

  return {
    call: call,
    dAppType: DAppType.ERC20,
    transactionType: TransactionEventType.DEPOSIT,
  };
};

export default useDepositERC20;
