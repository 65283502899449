import { compose } from '@reduxjs/toolkit';
import { FC } from 'react';

import withFetcher from '@/hocs/withFetcher';
import withMaintenance from '@/hocs/withMaintenance';
import withRouteParams from '@/hocs/withRouteParams';

const enhance = (WrappedComponent: FC) => (props: any) => {
  return <WrappedComponent {...props} />;
};

export default compose<FC>(withMaintenance, withFetcher, withRouteParams, enhance);
