export enum IStepKey {
  withdraw = 'withdraw',
  waiting = 'waiting',
  prove = 'prove',
  challenge = 'challenge',
  claim = 'claim',
}

export interface IWithdrawStep {
  name: string;
  processing: boolean;
  image: string;
  hash: string;
  infoLink: string;
  key: IStepKey;
}

const WITHDRAW_STEPS: Array<IWithdrawStep> = [
  {
    name: 'Initiate withdrawal',
    processing: true,
    image: 'fly.svg',
    hash: '',
    infoLink: '',
    key: IStepKey.withdraw,
  },
  {
    name: 'Wait up to 3 hours',
    processing: false,
    image: 'time.svg',
    hash: '',
    infoLink: '',
    key: IStepKey.waiting,
  },
  {
    name: 'Prove withdrawal',
    processing: false,
    image: 'shield.svg',
    hash: '',
    infoLink: '',
    key: IStepKey.prove,
  },
  {
    name: 'Wait 7 days',
    processing: false,
    image: 'fly.svg',
    hash: '',
    infoLink: '',
    key: IStepKey.challenge,
  },
  {
    name: 'Claim withdrawal',
    processing: false,
    image: 'download.svg',
    hash: '',
    infoLink: '',
    key: IStepKey.claim,
  },
];

export { WITHDRAW_STEPS };
