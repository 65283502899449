/* eslint-disable */
import { TC_BRIDGES_API_URL } from '@/configs';
import { camelCaseKeys } from '@trustless-computer/dapp-core';
import createAxiosInstance from './http-feat-flag';

const apiClient = createAxiosInstance({ baseURL: TC_BRIDGES_API_URL });

const API_PATH = '/api/bridge-enabled';

export const getEnableFlagAPI = async (): Promise<boolean> => {
  try {
    let response = await apiClient.get(`${API_PATH}`);
    if (response) {
      return true;
    } else {
      return false;
    }
  } catch (error: any) {
    // console.log('[getEnableFlagAPI] error: ', error);
    throw error;
  }
};
