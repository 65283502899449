import { ContractOperationHook, DAppType } from '@/interfaces/contract-operation';
import { useWeb3React } from '@web3-react/core';
import { useCallback } from 'react';
import { TransactionEventType } from '@/enums/transaction';
import { getContract } from '@/utils';
import ConvertTCL1ToL2Json from '@/abis/tcBridgeFromL1.json';
import { ethers } from 'ethers';

export interface IConvertTCL1ToL2Params {
  bridgeAddress: string;
  receiver: string;
  amount: string;
}

const useConvertTCL1ToL2: ContractOperationHook<
  IConvertTCL1ToL2Params,
  any | undefined
> = () => {
  const { account, provider } = useWeb3React();
  const call = useCallback(
    async (params: IConvertTCL1ToL2Params): Promise<any | undefined> => {
      if (account && provider) {
        const { amount, receiver, bridgeAddress } = params;
        const contract = getContract(
          bridgeAddress,
          ConvertTCL1ToL2Json,
          provider,
          account,
        ).connect(provider.getSigner());
        const tx = await contract.depositETHTo(receiver, '200000', [], {
          value: ethers.BigNumber.from(amount),
        });
        if (tx) {
          return {
            hash: Object(tx).hash,
          };
        }
      }
      return undefined;
    },
    [account, provider],
  );

  return {
    call: call,
    dAppType: DAppType.Contract,
    transactionType: TransactionEventType.DEPLOY,
  };
};

export default useConvertTCL1ToL2;
