import { TransactionEventType } from '@/enums/transaction';
import useBurnTokenLayer2, {
  IBurnTokenLayer2Params,
} from '@/hooks/contract-operations/useBurnLayer2';
import useConvertTCL1ToL2, {
  IConvertTCL1ToL2Params,
} from '@/hooks/contract-operations/useConvertTCL1ToL2';
import { IConvertTCL2ToL1Params } from '@/hooks/contract-operations/useConvertTCL2ToL1';
import useBurnToken, {
  IBurnTokenParams,
} from '@/hooks/contract-operations/useBurnToken';
import useContractOperation from '@/hooks/contract-operations/useContractOperation';
import useWithdrawApproveToken, {
  IApproveTokenParams,
} from '@/hooks/contract-operations/useWithdrawApproveToken';
import useLoading from '@/hooks/useLoading';
import { isProduction } from '@/utils/commons';
import convertUtils from '@/utils/convert';
import { parseError } from '@/utils/errorHelper';
import { logger } from '@/utils/logger';
import { delay } from '@/utils/time';
import { ROUTE_PATH } from '@constants/route-path';
import { useAppSelector } from '@state/hooks';
import { getUserSelector } from '@state/user/selector';
import { useWeb3React } from '@web3-react/core';
import { useRouter } from 'next/router';
import React from 'react';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import * as TC_SDK from 'trustless-computer-sdk';
import { setTxBurnMetaMask } from '../Withdraw/Withdraw.localStorage';
import {
  Fields,
  WAITING_APPROVAL_CONFIRMATION_TIMEOUT,
} from './FormBridge.constants';
import { getKeyDefineByData } from './FormBridge.localStorage';
import {
  checkNeedApproveOnL1,
  checkNeedApproveOnL2,
  getBridgeAddress,
  isLayer1Network,
} from './FormBridge.utils';
import {
  getFormBridgeInfo,
  getMaxErrorSelector,
  getEstimateWithdrawSelector,
} from './state/selector';
import { isLayer2, NETWORK_SUPPORTING } from '@constants/network';
import { getEventTypeParseNetwork } from '../History/History.types';
import TCL2WithdrawModal from '@components/WithdrawTCLayer2/TCL2Modal.withdraw';
import { LOCAL_PENDING_STATUS } from '../History/History.constants';
import { HOME_TABS } from '../Home/Home.constants';
import useConvertERC20L1ToL2, {
  IConvertERC20L1ToL2Params,
} from '@/hooks/contract-operations/useConvertERC20L1ToL2';
import { IApprovePopup } from '@/containers/FormBridge/FormBridge.types';
import ApproveModal from '@components/ApproveModal';
import useBurnNativeToken from '@/hooks/contract-operations/useBurnNativeToken';

const enhanceWithdraw = (WrappedComponent: any) => (props: any) => {
  const { resetForm } = props;
  const {
    fromTokenSelected,
    allowanceAmount,
    toNetworkSelected,
    fromNetworkSelected,
    fromNetworkObject,
    toNetworkObject,
    isCrossLayer,
    isBurnNativeToken,
  } = useSelector(getFormBridgeInfo);

  const maxErrorMessage = useSelector(getMaxErrorSelector);
  const [convertTCL2Data, setConvertTCL2Data] = React.useState<
    IConvertTCL2ToL1Params | undefined
  >(undefined);

  const [popupApprove, setPopupApprove] = React.useState<IApprovePopup | undefined>(
    undefined,
  );

  const amountInputRef = React.useRef('');
  const allowanceAmountRef = React.useRef<string>();

  const user = useAppSelector(getUserSelector);

  const { data: estimateData } = useAppSelector(getEstimateWithdrawSelector);

  const { account: tcAddress } = useWeb3React();
  const { showLoading } = useLoading();
  const router = useRouter();

  const { run: burnTokenLayer1 } = useContractOperation<IBurnTokenParams, string>({
    operation: useBurnToken,
    inscribeable: true,
  });

  const { run: useBurnNativeTokenRunner } = useContractOperation<
    IBurnTokenParams,
    string
  >({
    operation: useBurnNativeToken,
    inscribeable: false,
  });

  const { run: burnTokenLayer2 } = useContractOperation<
    IBurnTokenLayer2Params,
    string
  >({
    operation: useBurnTokenLayer2,
    inscribeable: false,
  });

  const { run: convertTCL1ToL2 } = useContractOperation<
    IConvertTCL1ToL2Params,
    string
  >({
    operation: useConvertTCL1ToL2,
    inscribeable: true,
  });

  const { run: convertERC20L1ToL2 } = useContractOperation<
    IConvertERC20L1ToL2Params,
    string
  >({
    operation: useConvertERC20L1ToL2,
    inscribeable: true,
  });

  const { run: approveToken } = useContractOperation<IApproveTokenParams, string>({
    operation: useWithdrawApproveToken,
    inscribeable: isLayer1Network(fromNetworkSelected),
  });

  const checkApproveOnL2 = (amount: string, allowanceAmount?: string) => {
    if (!fromTokenSelected || !allowanceAmount || !amount) return true;
    return checkNeedApproveOnL2({
      amountInput: amount,
      allowanceAmount,
      decimals: 18,
    });
  };

  React.useEffect(() => {
    allowanceAmountRef.current = allowanceAmount;
  }, [allowanceAmount]);

  const bridgeAddress = React.useMemo(() => {
    return getBridgeAddress({
      network: toNetworkSelected,
      token: fromTokenSelected,
    });
  }, [toNetworkSelected, fromTokenSelected]);

  const tokenAddress = React.useMemo(() => {
    return fromTokenSelected?.tcTokenID;
  }, [fromTokenSelected?.tcTokenID]);

  const onApproveTokenHandler = async (amount: string) => {
    if (!fromTokenSelected || !tokenAddress || !bridgeAddress) return;
    try {
      const result = await approveToken({
        bridgeAddress: bridgeAddress,
        tokenAddress: tokenAddress,
        approveAmount: amount,
        tokenInfo: fromTokenSelected,
      });
      console.log('[onApproveTokenHandler] result ', result);
      return result;
    } catch (error: any) {
      console.log('[onApproveTokenHandler] error ', error);
      toast.error(parseError(error));
    } finally {
      showLoading(false);
    }
  };

  const convertTCStatus = React.useMemo(() => {
    const isTransferTCL1ToL2 =
      fromNetworkSelected === NETWORK_SUPPORTING.TRUSTLESS_LAYER1 &&
      isLayer2(toNetworkSelected) &&
      fromTokenSelected?.type === 'NATIVE';

    const isTransferTCL2ToL1 =
      isLayer2(fromNetworkSelected) &&
      toNetworkSelected === NETWORK_SUPPORTING.TRUSTLESS_LAYER1 &&
      fromTokenSelected?.type === 'NATIVE';

    const isNativeBridgeL1ToL2 =
      fromNetworkSelected === NETWORK_SUPPORTING.TRUSTLESS_LAYER1 &&
      isLayer2(toNetworkSelected) &&
      !!fromTokenSelected?.isNativeBridge;

    const isNativeBridgeL2ToL1 =
      isLayer2(fromNetworkSelected) &&
      toNetworkSelected === NETWORK_SUPPORTING.TRUSTLESS_LAYER1 &&
      !!fromTokenSelected?.isNativeBridge;

    const remoteTokenAddress = fromTokenSelected?.tokenAddress[toNetworkSelected];

    return {
      isTransferTCL1ToL2,
      isTransferTCL2ToL1,
      isNativeBridgeL1ToL2,
      isNativeBridgeL2ToL1,
      remoteTokenAddress,
    };
  }, [
    fromNetworkSelected,
    toNetworkSelected,
    fromTokenSelected?.type,
    fromTokenSelected?.isNativeBridge,
    fromTokenSelected?.tokenAddress,
  ]);

  const onBurn = async (burnAmount: string, receiverAddress: string) => {
    if (
      !fromTokenSelected ||
      !bridgeAddress ||
      !tokenAddress ||
      !burnAmount ||
      !receiverAddress
    ) {
      return;
    }
    try {
      const payload = {
        bridgeAddress: bridgeAddress,
        tokenAddress: tokenAddress,
        burnAmount: burnAmount,
        receiver: receiverAddress,
      };

      const crossLayerDepositAddress = estimateData?.crossLayerDepositAddress;
      const isBlockToken = estimateData?.isBlockToken;

      logger('OLD Payload  = ', payload);
      logger('crossLayerDepositAddress = ', crossLayerDepositAddress);
      logger('convertTCStatus = ', convertTCStatus);

      let result: any;

      if (convertTCStatus.isTransferTCL1ToL2) {
        result = convertTCL1ToL2({
          bridgeAddress: payload.bridgeAddress,
          receiver: payload.receiver,
          amount: payload.burnAmount,
        });
      } else if (
        convertTCStatus.isTransferTCL2ToL1 ||
        convertTCStatus.isNativeBridgeL2ToL1
      ) {
        setConvertTCL2Data({
          bridgeAddress: payload.bridgeAddress,
          tokenAddress: payload.tokenAddress,
          amount: payload.burnAmount,
          isNative: fromTokenSelected.type === 'NATIVE',
        });
        return;
      } else if (convertTCStatus.isNativeBridgeL1ToL2) {
        result = convertERC20L1ToL2({
          bridgeAddress: payload.bridgeAddress,
          receiver: payload.receiver,
          amount: payload.burnAmount,
          tokenAddress: payload.tokenAddress,
          remoteTokenAddress: convertTCStatus.remoteTokenAddress || '',
        });
      } else {
        if (isBurnNativeToken) {
          // ------------------------------
          // burn BVM, Naka
          // ------------------------------
          logger('onBurn - NATIVE TOKEN: -- ');
          result = await useBurnNativeTokenRunner(payload);
        } else {
          const isNewBurn = isLayer2(fromNetworkSelected) || isBlockToken === true;
          if (isCrossLayer) {
            if (!crossLayerDepositAddress || crossLayerDepositAddress.length < 1) {
              throw 'CrossLayerDepositAddress INVALID!';
            }
            payload.receiver = crossLayerDepositAddress;
          } else {
            payload.receiver = receiverAddress;
          }

          if (isNewBurn) {
            const chainID = toNetworkObject?.chainId;
            if (!chainID || chainID < 0) {
              throw 'ChainID UNKNOW';
            }
            const layer2Payload = {
              tokenAddress: payload.tokenAddress,
              bridgeAddress: payload.bridgeAddress,
              burnAmount: payload.burnAmount,
              receiver: payload.receiver,
              chainID: chainID,
            };
            logger('onBurn - LAYER 2: ', layer2Payload);
            result = await burnTokenLayer2(layer2Payload);
          } else {
            logger('onBurn - LAYER 1: -- ANOTHER');
            result = await burnTokenLayer1(payload);
          }
        }
      }

      return result;
    } catch (error) {
      logger('onBurn - error: ', error);
      throw error;
    }
  };

  const checkApproveOnL1 = (amount: string, keyDefined: string) => {
    if (!fromTokenSelected) return false;
    return checkNeedApproveOnL1({
      amountInput: amount,
      allowanceAmount,
      decimals: 18,
      keyDefined,
      type: fromTokenSelected.type,
    });
  };

  const waitingApproveHandler = async () => {
    let count = 0;
    while (count < WAITING_APPROVAL_CONFIRMATION_TIMEOUT) {
      if (!checkApproveOnL2(amountInputRef.current, allowanceAmountRef.current)) {
        break;
      }
      await delay(1000);
      count++;
    }
    return count < WAITING_APPROVAL_CONFIRMATION_TIMEOUT;
  };

  const onWithdrawHandler = async (data: any, isShowApproveModal = true) => {
    console.log('[onWithdrawHandler] data ', data);
    try {
      if (!data || maxErrorMessage) return;

      if (isLayer1Network(fromNetworkSelected)) {
        if (!user || !user.walletAddress || !user.walletAddressBtcTaproot) {
          router.push(ROUTE_PATH.CONNECT_WALLET);
          return;
        }
      }

      showLoading(true);
      // if (fromTokenSelected && fromTokenSelected.type !== 'NATIVE') {
      //   await addToken({
      //     tokenAddress: fromTokenSelected.tcTokenID,
      //     decimals: fromTokenSelected.decimals,
      //     image: getIconUrl({ token: fromTokenSelected }),
      //     symbol: fromTokenSelected.symbol,
      //     connector,
      //   });
      // }

      const amount = data[Fields.AMOUNT];
      const receiverAddress = data[Fields.ADDRESS];

      if (!fromTokenSelected || !amount || !receiverAddress || !tcAddress) return;
      const tokenAddress = fromTokenSelected.tcTokenID!;
      amountInputRef.current = amount;

      const keyDefined = getKeyDefineByData({
        tcAddress,
        tokenAddress,
        bridgeAddress: bridgeAddress || toNetworkSelected || '',
      });

      console.log('[onWithdrawHandler] BEFORE check approve', {
        amount,
        allowanceAmount,
        decimals: 18,
        keyDefined,
        tokenAddress,
        tcAddress,
        isBurnNativeToken,
      });

      const isConvertTC =
        convertTCStatus.isTransferTCL2ToL1 ||
        convertTCStatus.isTransferTCL1ToL2 ||
        convertTCStatus.isNativeBridgeL2ToL1;

      const isNeedApprove =
        isConvertTC || isBurnNativeToken
          ? false
          : isLayer1Network(fromNetworkSelected)
          ? checkApproveOnL1(amount, keyDefined)
          : checkApproveOnL2(amount, allowanceAmount);

      logger('[onWithdrawHandler] isNeedApprove ', isNeedApprove);

      let txApproveResult;
      if (isNeedApprove) {
        if (isShowApproveModal) {
          return setPopupApprove({
            data: data,
          });
        }
        txApproveResult = await onApproveTokenHandler(amount);
        if (!txApproveResult) return;
        if (isLayer2(fromNetworkSelected)) {
          showLoading(
            true,
            'Please wait for the confirmation of the approval transaction.',
          );
          const result = await waitingApproveHandler();
          showLoading(false);
          if (!result) return;
        } else {
          await delay(500);
        }
      }

      const amountStr = convertUtils.toBigNumberNonRound(amount, 18);
      const burnResult: any = await onBurn(amountStr, receiverAddress);

      console.log('[DEBUG] Burn Result ', burnResult);

      if (burnResult?.hash) {
        //Save TX Burn Metamask to LocalStorage
        await setTxBurnMetaMask({
          txHash: burnResult.hash,
          amountHumanRead: amount,
          amountBN: amountStr,
          btcWithdrawAddress: receiverAddress,
          tcAddress: tcAddress,
          createdAt: new Date().getTime(),
          isL1ToL2:
            fromNetworkSelected === NETWORK_SUPPORTING.TRUSTLESS_LAYER1 &&
            isLayer2(toNetworkSelected),
          type: getEventTypeParseNetwork(fromNetworkSelected, toNetworkSelected),
          symbol: fromTokenSelected?.symbol,
          status: LOCAL_PENDING_STATUS,
          amountTc: amountStr,
          fromNetwork: fromNetworkSelected,
          toNetwork: toNetworkSelected,
          toNetworkTitle: toNetworkObject?.networkTitle || '',
          fromNetworkTitle: fromNetworkObject?.networkTitle || '',
        });

        if (isLayer1Network(fromNetworkSelected)) {
          //send TxHash to Wallet TC page to sign!
          TC_SDK.signTransaction({
            method: `${TransactionEventType.WITHDRAW}`,
            hash: burnResult.hash,
            dappURL: window.location.origin,
            isRedirect: true,
            target: '_blank',
            isMainnet: isProduction(),
          });
        }
        toast.success('Withdraw Processing');
        router.push(`/?tab=${HOME_TABS.History}`);
        resetForm && resetForm();
      }
    } catch (error: any) {
      console.log('[enhanceDeposit][onDepositHandler] ERROR: ', error);
      toast.error(parseError(error));
    } finally {
      showLoading(false);
    }
  };

  return (
    <>
      <WrappedComponent {...{ ...props, onWithdrawHandler }} />
      {!!convertTCL2Data && (
        <TCL2WithdrawModal
          symbol={fromTokenSelected?.symbol || ''}
          isShow={Boolean(convertTCL2Data)}
          onHide={() => setConvertTCL2Data(undefined)}
          data={convertTCL2Data}
        />
      )}
      {!!popupApprove?.data && (
        <ApproveModal
          symbol={fromTokenSelected?.symbol || ''}
          show={true}
          onSubmit={async () => {
            await onWithdrawHandler(popupApprove.data, false);
            setPopupApprove(undefined);
          }}
          onClose={() => {
            setPopupApprove(undefined);
          }}
        />
      )}
    </>
  );
};
export default enhanceWithdraw;
