import { compose } from '@reduxjs/toolkit';
import enhanceAllowanceAmount from './FormBridge.enhanceAllowanceAmount';

import enhanceBalance from './FormBridge.enhanceBalance';
import enhanceSubmit from './FormBridge.enhanceSubmit';
import enhanceSwap from './FormBridge.enhanceSwap';
import enhanceDeposit from './FormBridge.enhanceDeposit';
import enhanceWithdraw from './FormBridge.enhanceWithdraw';
import enhanceSwitchChain from './FormBridge.enhanceSwitchChain';
import enhanceResetForm from './FormBridge.enhanceResetForm';
import enhanceWithdrawProcessing from './FormBridge.enhanceWithdrawProcessing';
import enhanceDepositSuccess from './FormBridge.enhanceDepositSuccess';
import enhanceGenerateDeposit from './FormBridge.enhanceGenerateDeposit';
import enhanceGasEstimate from './FormBridge.enhanceGasEstimate';

import enhanceMax from './FormBridge.enhanceMax';

const enhance = (WrappedComponent: any) => (props: any) => {
  return <WrappedComponent {...{ ...props }} />;
};

export default compose(
  enhanceResetForm,
  enhanceSwitchChain,
  enhanceAllowanceAmount,
  enhanceBalance,
  enhanceGenerateDeposit,
  enhanceDepositSuccess,
  enhanceDeposit,
  enhanceWithdrawProcessing,
  enhanceWithdraw,
  enhanceSwap,
  enhanceMax,
  enhanceGasEstimate,
  enhanceSubmit,
  enhance,
);
