import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { getFormBridgeInfo } from '../state/selector';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  margin-right: 8px;
  max-width: 48px;
  max-height: 35px;
  padding: 8px;

  border-radius: 4px;
  /* background-color: ${({ theme }) => theme.colors.white}; */
  color: '#1C1C1C';

  :hover {
    cursor: pointer;
    opacity: 0.85;
    transform: scale(1.05);
    transition-duration: 0.3s;
  }
`;

const MaxButtonStyled = styled.button`
  font-family: 'TitilliumWeb';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  text-align: center;
  /* color: #1c1c1c; */
  color: ${({ theme }) => theme.colors.btn1};
`;

type Props = {
  onClick?: any;
};

const MaxButton = (props: Props) => {
  const { onClick } = props;
  const { isL1ToL2, isL2ToL1, fromTokenSelected } = useSelector(getFormBridgeInfo);

  // if ((isL1ToL2 || isL2ToL1) && fromTokenSelected?.symbol?.toUpperCase() === 'TC')
  //   return null;

  return (
    <Container onClick={onClick}>
      <MaxButtonStyled>{'Max'}</MaxButtonStyled>
    </Container>
  );
};

export default MaxButton;
