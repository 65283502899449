import { ContractOperationHook, DAppType } from '@/interfaces/contract-operation';
import { useWeb3React } from '@web3-react/core';
import { useCallback } from 'react';
import { TransactionEventType } from '@/enums/transaction';
import useCrossChainMessenger, { ICrossChainMessenger } from '@utils/optimism';
import { onRetryFunction } from '@utils/retryCall';

export interface IClaimTCL2Params extends ICrossChainMessenger {
  hash: string;
}

const useClaimTCL2: ContractOperationHook<
  IClaimTCL2Params,
  any | undefined
> = () => {
  const { account, provider } = useWeb3React();
  const crossChainMessenger = useCrossChainMessenger();
  const call = useCallback(
    async (params: IClaimTCL2Params): Promise<any | undefined> => {
      const { hash } = params;
      if (account && provider && crossChainMessenger) {
        console.log('CLAIM HASH: ', hash);
        const tx = await onRetryFunction({
          func: async () => {
            return await crossChainMessenger({
              ...params,
            }).finalizeMessage(hash);
          },
        });
        if (Object(tx)?.hash) {
          return Object(tx).hash;
        } else {
          throw new Error('Can not create transaction.');
        }
      }
      return undefined;
    },
    [account, provider, crossChainMessenger],
  );

  return {
    call: call,
    dAppType: DAppType.Contract,
    transactionType: TransactionEventType.DEPLOY,
  };
};

export default useClaimTCL2;
