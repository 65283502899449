const HISTORY_KEYS = {
  WITHDRAW_HISTORY: 'WITHDRAW_HISTORY',
  DEPOSIT_HISTORY: 'DEPOSIT_HISTORY',
};

export const LOCAL_PENDING_STATUS = 2000; // Only Local, no belong to API
const WAITING_INSCRIBED_STATUS = 1000; // Only Local, no belong to API

const StatusL1L2_Pending = 20; // 20: waiting for tx burn has enough confirmations
const StatusL1L2_Processing = 21; // 21: creating tx mint, and waiting for tx mint has enough confirmations
const StatusL1L2_Success = 22; // 22: tx mint  has enough confirmations
const StatusL1L2_TxDepositFailed = 23; // 23: tx submit tc failed
const StatusL1L2_TxListenFailed = 24; // 24: tx listen failed

type TransactionStatusType = {
  SUCCESS: number[];
  INVALID: number[];
  PROCESSING: number[];
  PENDING: number[];
  WAITING_INSCRIBED: number[];
  TOPUP_FEE: number[];
  FAILED: number[];
};

const TransactionStatus: TransactionStatusType = {
  SUCCESS: [4, 7, StatusL1L2_Success],
  INVALID: [8, StatusL1L2_TxDepositFailed, StatusL1L2_TxListenFailed],
  PROCESSING: [StatusL1L2_Processing],
  WAITING_INSCRIBED: [WAITING_INSCRIBED_STATUS],
  PENDING: [0, StatusL1L2_Pending, LOCAL_PENDING_STATUS], //Local
  TOPUP_FEE: [24],
  FAILED: [113],
};

export { HISTORY_KEYS, TransactionStatus, WAITING_INSCRIBED_STATUS };
