import SwapIcon from '@/components/IconSVG/SwapIcon';
import {
  Box2,
  DividerBox,
  NetworkBox,
  Row,
  Title1,
  Title2,
  TokenBox,
  WrapperArrowDownCircle,
} from './FormBridge.styles';

import FromNetworkSelect from './components/FromNetworkSelect';
import FromTokenSelect from './components/FromTokenSelect';

import ToNetworkSelect from './components/ToNetworkSelect';
import ToTokenSelected from './components/ToTokenSelected';

import Space from '@components/Space';
import { FormBridgeType } from './FormBridge.types';

const GroupSelect = (props: FormBridgeType) => {
  const { swapHandler } = props;
  return (
    <>
      <Row>
        <Title1>{'From'}</Title1>
      </Row>
      <Space.Vertical size={10} />
      <Box2>
        <NetworkBox>
          <Title2>{'Network:'}</Title2>
          <FromNetworkSelect />
        </NetworkBox>
        <DividerBox />
        <TokenBox>
          <Title2>{'Token:'}</Title2>
          <FromTokenSelect />
        </TokenBox>
      </Box2>

      <Space.Vertical size={20} />
      <WrapperArrowDownCircle>
        <SwapIcon className="hover" onClick={swapHandler} />
      </WrapperArrowDownCircle>

      <Title1>{'To'}</Title1>
      <Space.Vertical size={10} />
      <Box2>
        <NetworkBox>
          <Title2>{'Network:'}</Title2>
          <ToNetworkSelect />
        </NetworkBox>
        <DividerBox />
        <TokenBox>
          <Title2>{'Token:'}</Title2>
          <ToTokenSelected />
        </TokenBox>
      </Box2>
    </>
  );
};

export default GroupSelect;
