import ERC20ABIJson from '@/abis/erc20.json';
import {
  getKeyDefineByData,
  saveApproveTxHash,
} from '@/containers/FormBridge/FormBridge.localStorage';
import { TransactionEventType } from '@/enums/transaction';
import { ContractOperationHook, DAppType } from '@/interfaces/contract-operation';
import { Token } from '@/state/tokens/types';
import { getContract } from '@/utils';
import { useWeb3React } from '@web3-react/core';
import BigNumber from 'bignumber.js';
import { useCallback } from 'react';
import Web3 from 'web3';

export interface IApproveTokenParams {
  bridgeAddress: string;
  tokenAddress: string;
  approveAmount?: string;
  tokenInfo?: Token;
}

const APPROVE_MAX =
  '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff';

const useWithdrawApproveToken: ContractOperationHook<
  IApproveTokenParams,
  any | undefined
> = () => {
  const { account, provider } = useWeb3React();
  const call = useCallback(
    async (params: IApproveTokenParams): Promise<any | undefined> => {
      try {
        if (account && provider) {
          const { tokenAddress, approveAmount, bridgeAddress, tokenInfo } = params;
          const contract = getContract(
            tokenAddress,
            ERC20ABIJson.abi,
            provider,
            account,
          );

          console.log('[useWithdrawApproveToken] approveAmount: ', approveAmount);
          const tx = await contract
            .connect(provider.getSigner())
            .approve(bridgeAddress, APPROVE_MAX);

          console.log('[useWithdrawApproveToken] tx: ', tx);

          if (tx) {
            const txObj = Object(tx);

            console.log('[useWithdrawApproveToken] Approve TX txObj: ', txObj);

            const data: string = txObj.data;

            console.log('[useWithdrawApproveToken] Approve TX data: ', data);

            const length = data.length;
            const allowanceAmountFromMetaMask = Web3.utils
              .hexToNumber(`0x${data.slice(length - 64, length)}`, true)
              .toString();

            console.log(
              '[useWithdrawApproveToken] allowanceAmountFromMetaMask: ',
              allowanceAmountFromMetaMask,
            );

            // Save Approve Tx data to localstorage
            saveApproveTxHash({
              approveTxHash: txObj.hash,
              amountInput: approveAmount || '0.0',
              keyDefined: getKeyDefineByData({
                tcAddress: account,
                tokenAddress,
                bridgeAddress,
              }),
              allowanceAmount: new BigNumber(allowanceAmountFromMetaMask).toFixed(),
              tokenInfo: tokenInfo,
            });

            return {
              hash: txObj.hash,
            };
          }
        }
        return undefined;
      } catch (error) {
        console.log('ERROR: ', error);
        // return undefined;
        throw error;
      }
    },
    [account, provider],
  );

  return {
    call: call,
    dAppType: DAppType.Contract,
    transactionType: TransactionEventType.DEPLOY,
  };
};

export default useWithdrawApproveToken;
