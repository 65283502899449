import { ContractOperationHook, DAppType } from '@/interfaces/contract-operation';
import { useWeb3React } from '@web3-react/core';
import { useCallback } from 'react';
import { TransactionEventType } from '@/enums/transaction';
import { getContract } from '@/utils';
import WBridgeJson from '@/abis/wbridge.layer1.json';

export interface IBurnTokenParams {
  tokenAddress: string;
  bridgeAddress: string;
  burnAmount: string;
  receiver: string;
}

const useBurnToken: ContractOperationHook<
  IBurnTokenParams,
  any | undefined
> = () => {
  const { account, provider } = useWeb3React();
  const call = useCallback(
    async (params: IBurnTokenParams): Promise<any | undefined> => {
      if (account && provider) {
        const { tokenAddress, burnAmount, receiver, bridgeAddress } = params;
        const contract = getContract(bridgeAddress, WBridgeJson, provider, account);
        const tx = await contract
          .connect(provider.getSigner())
          .burn(tokenAddress, burnAmount, receiver, {
            gasLimit: '500000',
          });
        if (tx) {
          return {
            hash: Object(tx).hash,
          };
        }
      }
      return undefined;
    },
    [account, provider],
  );

  return {
    call: call,
    dAppType: DAppType.Contract,
    transactionType: TransactionEventType.DEPLOY,
  };
};

export default useBurnToken;
