import Text from '@/components/Text';
import { memo } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { getFormBridgeInfo, getEstimateWithdrawSelector } from '../state/selector';
import { NETWORK_SUPPORTING, isLayer2 } from '@constants/network';

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const MinimumWithdrawAmount = () => {
  const { data: estimateData, isLoading } = useSelector(getEstimateWithdrawSelector);
  const { fromTokenSelected, toNetworkSelected, fromNetworkSelected } =
    useSelector(getFormBridgeInfo);

  const minWithdrawAmountFormatedStr = estimateData?.minWithdrawAmountFormatedStr;
  const isBlockToken = estimateData?.isBlockToken;
  const minWithdrawAmountShouldBeHide = estimateData?.minWithdrawAmountShouldBeHide;

  if (isLoading) return <Skeleton count={1} />;
  if (
    !minWithdrawAmountFormatedStr ||
    !fromTokenSelected ||
    minWithdrawAmountShouldBeHide
  )
    return null;
  if (toNetworkSelected === NETWORK_SUPPORTING.ORDINALS) return null;
  if (isBlockToken) return null;
  if (fromTokenSelected?.isNativeBridge === true) {
    return null;
  }

  return (
    <Container>
      <Text>{'Minimum withdraw amount'}</Text>
      <Text>{`${minWithdrawAmountFormatedStr} ${(
        fromTokenSelected?.symbol || 'Unknow'
      ).toUpperCase()}`}</Text>
    </Container>
  );
};

export default memo(MinimumWithdrawAmount);
