// import { Input2 } from '@/components/Inputs/Input2';
import { Input3 } from '@/components/Inputs/Input3';
import validator, { composeValidators } from '@/utils/validator';
import { useEffect, useMemo } from 'react';
import { Field, useForm } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { Fields } from '../FormBridge.constants';
import { formBridgeActions } from '../state/reducer';
import {
  getEstimateWithdrawSelector,
  getFormBridgeInfo,
  getGenerateDepositSelector,
} from '../state/selector';

const FromInputField = () => {
  const {
    fromTokenSelected,
    fromNetworkSelected,
    formType,
    fromBalanceFormatedStr,
    generateDepositData,
    toNetworkSelected,
    toTokenSelected,
    isSwitchNetwork,
    disableSubmitBtn,
    isBurnNativeToken,
  } = useSelector(getFormBridgeInfo);

  const { data: depositData } = useSelector(getGenerateDepositSelector);

  const { data: estimdateData } = useSelector(getEstimateWithdrawSelector);

  const depositFeeHRFormatStr = depositData?.depositFeeHRFormatStr;

  const withdrawFeeHRFormatStr = estimdateData?.withdrawFeeHRFormatStr;
  const minWithdrawAmountFormatedStr = estimdateData?.minWithdrawAmountFormatedStr;

  const form = useForm();
  const dispatch = useDispatch();
  // const stateField = form.getFieldState(Fields.AMOUNT);

  useEffect(() => {
    form.reset();
    form.resetFieldState(Fields.AMOUNT);
  }, [form, fromTokenSelected, fromNetworkSelected]);

  useEffect(() => {
    dispatch(formBridgeActions.setFormInstance(form));
  }, []);

  const validators = useMemo(() => {
    const validatorArray: any[] = [];

    if (isSwitchNetwork || disableSubmitBtn) return undefined;

    if (formType === 'Deposit') {
      validatorArray.push(validator.amountRequired());
      validatorArray.push(validator.largerThan(0));
      if (depositFeeHRFormatStr) {
        validatorArray.push(validator.largerThan(depositFeeHRFormatStr));
      }
    } else {
      validatorArray.push(validator.amountRequired());
      validatorArray.push(validator.largerThan(0));
      if (minWithdrawAmountFormatedStr) {
        validatorArray.push(validator.largerThan(minWithdrawAmountFormatedStr));
      }
      if (withdrawFeeHRFormatStr) {
        validatorArray.push(validator.largerThan(withdrawFeeHRFormatStr));
      }
    }

    if (fromBalanceFormatedStr) {
      validatorArray.push(validator.maxValue(fromBalanceFormatedStr));
    }

    return composeValidators(...validatorArray);
  }, [
    fromTokenSelected,
    fromNetworkSelected,
    formType,
    fromBalanceFormatedStr,
    generateDepositData,
    toNetworkSelected,
    toTokenSelected,
    isSwitchNetwork,
    withdrawFeeHRFormatStr,
    minWithdrawAmountFormatedStr,
    disableSubmitBtn,
    isBurnNativeToken,
    form,
  ]);

  return (
    <Field name={Fields.AMOUNT} validate={validators}>
      {({ input, meta }) => (
        <Input3
          {...input}
          {...meta}
          onChange={(event: any) => {
            dispatch(formBridgeActions.setFromInputAmount(event.target.value));
            dispatch(formBridgeActions.setMaxErrorMessage(undefined));
            input.onChange(event);
          }}
          id={Fields.AMOUNT}
          name={Fields.AMOUNT}
          placeholder="0.0"
          type="number"
          step={'any'}
          autoComplete="off"
          spellCheck={false}
          onWheel={(e: any) => e?.target?.blur()}
        />
      )}
    </Field>
  );
};

export default FromInputField;
