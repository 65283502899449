import Text from '@/components/Text';
import BigNumber from 'bignumber.js';
import { isEmpty } from 'lodash';
import { memo } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import {
  getFormBridgeInfo,
  getGenerateDepositSelector,
} from '../FormBridge/state/selector';

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const DepositFeeEstimate = () => {
  const { isLoading, data } = useSelector(getGenerateDepositSelector);
  const { fromTokenSelected } = useSelector(getFormBridgeInfo);

  if (isLoading) return <Skeleton count={1} />;
  if (!data) return null;

  const { depositFeeHRFormatStr } = data;

  if (
    isEmpty(depositFeeHRFormatStr) ||
    new BigNumber(depositFeeHRFormatStr || '0').isZero()
  )
    return null;

  const symbol = fromTokenSelected?.symbol?.toUpperCase();

  return (
    <Container>
      <Text>{'Transfer fee (est.)'}</Text>
      <Text>{`${depositFeeHRFormatStr} ${symbol}`}</Text>
    </Container>
  );
};

export default memo(DepositFeeEstimate);
